/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import moment from 'moment';

export const LoggedInUser = Parse.Object.extend("User", {
  initialize: function () {
  },

  firstName: function () { return Parse.User.current()?.get("firstName"); },
  lastName: function () { return Parse.User.current()?.get("lastName"); },
  fullName: function () { return Parse.User.current()?.get("firstName") + " " + Parse.User.current()?.get("lastName"); },
  email: function () { return Parse.User.current()?.get("email"); },
  roles: function () {
    const userRoles = Parse.User.current()?.get("roles");
    if (userRoles && Array.isArray(userRoles) && userRoles.length) {
      return userRoles.join(", ");
    }
    return "";
  },
  isAdmin: function () { return _.includes(Parse.User.current()?.get("roles"), "admin"); },
  isSectionLeader: function () {
    const sectionLeaderRoles = getSectionLeaderRoles();
    return sectionLeaderRoles.length > 0;
  },
  imageLarge: function () {
    if (Parse.User.current()?.get("profileImageThumbnailLarge")) {
      return Parse.User.current()?.get("profileImageThumbnailLarge").url();
    } else {
      return paidutils.getNoImageURL();
    }
  },
  getSectionLeaderSectionIds: function () {
    const sectionLeaderRoles = getSectionLeaderRoles();
    let results = [];
    results = _.map(sectionLeaderRoles, function (r) {
      console.log(r);
    });
    return results;
  },
  isCashier: function () { return _.includes(Parse.User.current()?.get("roles"), "cashier"); },
  isMarketing: function () { return _.includes(Parse.User.current()?.get("roles"), "marketing"); },
  isOperations: function () { return _.includes(Parse.User.current()?.get("roles"), "operations"); },
  isFinance: function () { return _.includes(Parse.User.current()?.get("roles"), "finance"); },
  isRedemptionOnly: function () {
    const isRedemption = _.includes(Parse.User.current()?.get("roles"), "redemption")
    const isCashier = _.includes(Parse.User.current()?.get("roles"), "cashier")

    return isRedemption && !isCashier
  },
  isCashierOrRedemption: function () {
    const isRedemption = _.includes(Parse.User.current()?.get("roles"), "redemption")
    const isCashier = _.includes(Parse.User.current()?.get("roles"), "cashier")

    return isRedemption || isCashier
  },

  fromNowDate: function () { return moment(Parse.User.current()?.createdAt).fromNow(); },
  image: function () {
    const user: any = Parse.User.current();
    if (user.get("profileImageThumbnail")) {
      const profileImage = user.get("profileImageThumbnail");
      if (profileImage.url) {
        return profileImage.url();
      }
      else {
        return profileImage;
      }
    } else {
      return paidutils.getNoImageURL();
    }
  },

  defaults: {
    firstName: "",
    lastName: "",
    fullName: "",
    image: null,
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});


function getSectionLeaderRoles() {
  const SECTION_LEADER_PREFIX = "SectionLeader__";
  const roles = Parse.User.current()?.get("roles");
  const sectionLeaderRoles = _.filter(roles, function (roleName) {
    return roleName.indexOf(SECTION_LEADER_PREFIX) === 0;
  });
  return sectionLeaderRoles;
}
