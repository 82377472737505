/* eslint-disable @typescript-eslint/no-unused-vars */
import EventState from "../type-defs/interfaces/eventState";
import { ActionContext } from "vuex";
import DataService from "../../services/DataService";
import Event from "../models/event";
import EventSection from "../models/eventSection";
import { getNoImageUrl } from "../../lib/image";
import { updateEventPackage } from "../../lib/common";
const ds = DataService.getInstance();
declare let paidutils: any;
const emptyAddress = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  zip: "",
  state: "",
  country: "",
  coords: {
    latitude: null,
    longitude: null,
  },
};

// initial state
const state: EventState = {
  event: new Event(),
};

function resetEventState(state: EventState) {
  state.event = new Event();
}

// getters
const getters = {
  id: () => state.event.id,
  name: () => state.event.name,
  type: () => state.event.type,
  slug: () => state.event.slug,
  desc: () => state.event.desc,
  terms: () => state.event.terms,
  startDateTime: () => state.event.startDateTime,
  endDateTime: () => state.event.endDateTime,
  isSelling: () => state.event.isSelling,
  useMeals: () => state.event.useMeals,
  mealsArray: () => state.event.mealsArray,
  image: () => state.event.image,
  presentationId: () => state.event.presentationIds[0],
  sections: () => state.event.sections,
  link: () => {
    const webUrl = Parse.Config.current().get("webURL") as string;
    const slug = state.event.slug;
    if (webUrl && slug) {
      return `${webUrl}/events/${slug}`;
    }

    return null;
  },
  prefixLink: () => {
    const webUrl = Parse.Config.current().get("webURL") as string;
    if (webUrl) {
      return `${webUrl}/events/`;
    }

    return null;
  },
  eventImageURL: () => {
    if (state.event) {
      if (state.event.thumbnail) {
        return state.event.thumbnail.url();
      }
      if (state.event.image) {
        return state.event.image.url();
      }
    }

    return getNoImageUrl();
  },
  address: () => state.event.address,
  defaultCommitteeMember: () => state.event.defaultCommitteeMember,
  usesApplications: () => {
    const applicationEvent = state.event.sections.filter((section) => {
      const applicationSections = section.packages.filter((pack) => pack.useApplication);
      return applicationSections.length > 0;
    });

    return applicationEvent.length > 0;
  },
  salesChannels: () => state.event.salesChannels,
  deleted: () => state.event.deleted,
  timezone: () => state.event.timezone,
  inPreviewMode: () => state.event.inPreviewMode,
  resaleEnabled: () => state.event.resaleEnabled,
};

// actons
const actions = {
  getEvent: async (
    { commit, state }: ActionContext<EventState, any>,
    eventId: string
  ) => {
    resetEventState(state);
    const presentations = await ds.getPresentationForEvent(eventId);
    if (!presentations || presentations.length === 0) {
      throw new Error("Presentation not found");
    }

    const parseEvent = await ds.getEvent(eventId);
    const presentationIds = presentations.map((presentation) => presentation.id);
    const event = new Event(parseEvent, presentationIds);
    const sections = await ds.getSectionsForEventForAdmin(eventId);
    let packages = await ds.getPackageListForEventForAdmin(eventId);
    packages = packages.sort((a: { sortOrder: number }, b: { sortOrder: number }) =>
      a.sortOrder > b.sortOrder ? 1 : -1
    );
    event.sections = sections
      .map((section: { objectId: any }) => ({
        ...section,
        id: section.objectId,
        packages: [],
      }))
      .sort((a: { sortOrder: number }, b: { sortOrder: number }) =>
        a.sortOrder > b.sortOrder ? 1 : -1
      );
    event.sections.map((section) => {
      packages.map((packageItem: any) => {
        if (packageItem.section.id == section.id) {
          section.packages.push({ ...packageItem, id: packageItem.objectId });
        }
      });
    });
    event.address = event.address || emptyAddress;
    event.isSelling = event.isSelling || false;
    event.useMeals = event.useMeals || false;
    event.salesChannels = event.salesChannels || [];
    event.deleted = event.deleted || false;
    event.timezone = event.timezone || "";
    event.inPreviewMode = event.inPreviewMode || false;
    event.resaleEnabled = event.resaleEnabled || false;
    commit("event", event);
  },
  getEventForCommitteeMember: async (
    { commit, state }: ActionContext<EventState, any>,
    eventId: string
  ) => {
    resetEventState(state);
    const parseEvent = await ds.getEvent(eventId);
    const event = new Event(parseEvent);
    commit("event", event);
  },
  saveEvent: async () => {
    if (!state.event.id) {
      throw new Error("Error saving Event. Event ID not found to save.");
    }
    const eventObject = state.event;
    console.log("eventObject", eventObject);
    const propertiesToBeSent = [
      "name",
      "desc",
      "terms",
      "image",
      "startDateTime",
      "endDateTime",
      "salesStartDate",
      "salesEndDate",
      "isSelling",
      "useMeals",
      "mealsArray",
      "address",
      "salesChannels",
      "timezone",
      "slug",
      "inPreviewMode",
      "resaleEnabled",
    ];

    await ds.updateEvent(
      state.event.id,
      paidutils.withSelectedProperties(eventObject, propertiesToBeSent)
    );
  },
  addNewSection: async (
    { commit }: ActionContext<EventState, any>,
    sectionDetails: any
  ) => {
    if (state.event.presentationIds.length === 0) {
      throw new Error("Error adding Section. No presentation found.");
    }

    const presentationId = state.event.presentationIds[0];
    await ds.createSection(sectionDetails, presentationId);
  },
  addNewPackage: async (
    { commit }: ActionContext<EventState, any>,
    packageDetails: any
  ) => {
    await ds.createPackage(packageDetails);
  },
  updateSection: async ({ commit }: ActionContext<EventState, any>, section: any) => {
    if (state.event) {
      const sectionsIds = state.event.sections.map((section) => section.id);
      const index = sectionsIds.indexOf(section.id);
      const eventSection = state.event.sections[index];

      if (eventSection) {
        eventSection.name = section.name;
        eventSection.desc = section.desc;
        eventSection.image = section.image;
        eventSection.isSelling = section.isSelling;
        eventSection.authType = section.authType;
        eventSection.mealsDisabled = section.mealsDisabled;
        eventSection.inPreviewMode = section.inPreviewMode;
        eventSection.sortOrder = index;
        const sectionId = section.id;
        delete section.authType;
        delete section.id;
        const updatedSection = await ds.updateSection(sectionId, section);

        // server generates thumbs
        eventSection.thumbnail = updatedSection.thumbnail;
        eventSection.thumbnail_small = updatedSection.thumbnail_small;
        eventSection.thumbnail_large = updatedSection.thumbnail_large;
      }
    }
  },
  updatePackage: async (
    { commit }: ActionContext<EventState, any>,
    packageDetails: any
  ) => {
    if (state.event && state.event.sections.length) {
      const section = state.event.sections.find(
        (section) => section.id === packageDetails.sectionId
      );
      if (section) {
        let eventPackage = section.packages.find(
          (pckage) => pckage.id === packageDetails.id
        );
        if (eventPackage) {
          eventPackage = await updateEventPackage(eventPackage, packageDetails);
        }
      }
    }
  },
  saveSections: async () => {
    if (state.event && state.event.sections.length) {
      const sections = state.event.sections;
      // save order of sections and packages
      let sectionOrder = 0;
      for (const sectionKey in sections) {
        const section = sections[sectionKey];
        const packages = section.packages;
        section.sortOrder = sectionOrder++;
        let packageOrder = 0;
        for (const packageKey in packages) {
          const pckage = packages[packageKey];
          pckage.sortOrder = packageOrder++;
          const packageDetailsToBeSent = [
            "allowNewApplication",
            "cost",
            "desc",
            "minPaymentOverride",
            "name",
            "online",
            "qtyAvailable",
            "soldOutOverride",
            "sortOrder",
            "useApplication",
            "sectionId",
            "maxPackagesPerPerson",
            "maxApplicationPerCustomer",
          ];
          const payload = {
            id: pckage.id,
            package: paidutils.withSelectedProperties(pckage, packageDetailsToBeSent),
          };
          await ds.updatePackage({ ...payload });
        }
        const sectionDetailsToBeSent = [
          "desc",
          "image",
          "isSelling",
          "mealsDisabled",
          "name",
          "sortOrder",
          'inPreviewMode'
        ];
        const sectionDetails = paidutils.withSelectedProperties(
          section,
          sectionDetailsToBeSent
        );
        if (sectionDetails.image == null) {
          delete sectionDetails.image;
        }
        await ds.updateSection(section.id, sectionDetails);
      }
    }
  },
  archiveSection: async (
    { commit }: ActionContext<EventState, any>,
    sectionId: string
  ) => {
    if (state.event && state.event.sections.length) {
      const section = state.event.sections.find((section) => section.id === sectionId);
      if (section) {
        await ds.updateSection(section.id, { deleted: true });
        commit("archiveSection", sectionId);
      } else {
        throw new Error("Error deleting the section");
      }
    }
  },
  archivePackage: async (
    { commit }: ActionContext<EventState, any>,
    { sectionId, packageId }: { sectionId: string; packageId: string }
  ) => {
    await ds.updatePackage({
      id: packageId,
      package: {
        deleted: true,
      },
    });
    commit("archivePackage", { sectionId, packageId });
  },
};

// mutations
const mutations = {
  event(state: EventState, event: Event) {
    state.event = event;
  },
  addSection(state: EventState, section: EventSection) {
    // add to front on sections
    state.event.sections.unshift(section);
  },
  addPackage(state: EventState, pckage: any) {
    const sectionId = pckage.section.id;
    const section = state.event.sections.find((section) => section.id === sectionId);
    if (section) {
      // add to front on package
      section.packages.unshift(pckage);
    }
  },
  archiveSection(state: EventState, sectionId: string) {
    state.event.sections = state.event.sections.filter(
      (section) => section.id !== sectionId
    );
  },
  archivePackage(
    state: EventState,
    { sectionId, packageId }: { sectionId: string; packageId: string }
  ) {
    const section = state.event.sections.find((section) => section.id === sectionId);
    if (!section) {
      return;
    }

    section.packages = section.packages.filter((pckage) => pckage.id !== packageId);
  },
  name(state: EventState, name: string) {
    state.event.name = name;
  },
  desc(state: EventState, desc: string) {
    state.event.desc = desc;
  },
  terms(state: EventState, terms: string) {
    state.event.terms = terms;
  },
  image(state: EventState, image: Parse.File) {
    state.event.image = image;
  },
  isSelling(state: EventState, isSelling: boolean) {
    state.event.isSelling = isSelling;
  },
  useMeals(state: EventState, useMeals: boolean) {
    state.event.useMeals = useMeals;
  },
  mealsArray(state: EventState, mealsArray: string[]) {
    state.event.mealsArray = mealsArray;
  },
  type(state: EventState, type: "livestreamed" | "costumed" | "ticketed") {
    state.event.type = type;
  },
  startDateTime(state: EventState, startDateTime: Date) {
    state.event.startDateTime = startDateTime;
  },
  endDateTime(state: EventState, endDateTime: Date) {
    state.event.endDateTime = endDateTime;
  },
  address(state: EventState, address: object) {
    state.event.address = address;
  },
  defaultCommitteeMember(state: EventState, defaultCommitteeMember: object) {
    state.event.defaultCommitteeMember = defaultCommitteeMember;
  },
  salesChannels(state: EventState, salesChannels: string[]) {
    state.event.salesChannels = salesChannels;
  },
  deleted(state: EventState, deleted: boolean) {
    state.event.deleted = deleted;
  },
  timezone(state: EventState, timezone: string) {
    state.event.timezone = timezone;
  },
  slug(state: EventState, slug: string) {
    state.event.slug = slug;
  },
  inPreviewMode(state: EventState, inPreviewMode: boolean) {
    state.event.inPreviewMode = inPreviewMode;
  },
  resaleEnabled(state: EventState, resaleEnabled: boolean) {
    state.event.resaleEnabled = resaleEnabled;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
