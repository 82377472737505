<template>
  <div
    id="main"
    class="main"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>
