/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';

export const TransactionReport = Parse.Object.extend("TransactionReport", {
  initialize: function () {
    this.isHighlighted = false;
  },

  url: function () {
    const result = this.get("pdf");
    return result ? result._url : null;
  },
  name: function () {
    const result = this.get("pdf");
    return result ? result._name : null;
  },
  approvalCode: function () { return this.get("approvalCode"); },
  startDate: function () { return moment(this.get("StartDate", "YYYY-MM-DD")).format("MMM DD") },
  endDate: function () { return moment(this.get("EndDate", "YYYY-MM-DD")).format("MMM DD") },
  dateRangeString: function () {
    const startMom = moment(this.get("StartDate", "YYYY-MM-DD"));
    const endMom = moment(this.get("EndDate", "YYYY-MM-DD"));
    let retStr = startMom.format("MMM Do") + " -> ";
    if (startMom.month() != endMom.month()) {
      retStr += endMom.format("MMM") + " ";
    }
    retStr += endMom.format("Do");
    return retStr;
  },
  draft: function () { return this.get("draft") },
  createTime: function () { return moment(this.createdAt).format("YYYY-MM-DD"); },
  amount: function () { return paidutils.formatMoney(this.get("TransferAmount")); },
  transferAmount: function () { return paidutils.formatMoney(this.get("TransferAmount")); },
  amountToPay: function () { return paidutils.formatMoney(this.get("AmountToPay")); },
  amountToPayAbs: function () { return paidutils.formatMoney(Math.abs(this.get("AmountToPay"))); },
  originalWithholding: function () { return this.withheld() },
  withheld: function () { return paidutils.formatMoney(this.get("Withheld")); },
  subTotal: function () { return paidutils.formatMoney(this.get("SubTotal")); },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});
