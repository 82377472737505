/* eslint-disable @typescript-eslint/no-unused-vars */
export const EntitlementInstance = Parse.Object.extend("EntitlementInstance", {

  initialize: function () {
  },

  used: function () { return this.get("used"); },
  approved: function () { return this.get("approved"); },
  requested: function () { return this.get("requested"); },
  template: function () { return this.get("template"); },
  member: function () { return this.get("member"); },
  applicant: function () { return this.get("applicant"); },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});