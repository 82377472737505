<template>
  <div id="app">
    <LoggedIn v-if="isLoggedIn">
      <div
        v-if="isLoggedIn"
        class="wrapper"
      >
        <div id="modal-wrapper" />
        <Navbar
          :user="user"
          :web-u-r-l="webURL"
        />
        <Sidebar />
        <router-view
          v-slot="{ Component }"
          :key="$route.path"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </LoggedIn>
    <Login v-else />
  </div>
</template>
<script>
import Login from "./vue/components/Login.vue";
import LoggedIn from "./vue/components/LoggedIn.vue";
import Navbar from './vue/components/Navbar.vue';
import Sidebar from './vue/components/Sidebar.vue';

export default {
  name: "App",
  components: {
    Login,
    LoggedIn,
    Navbar,
    Sidebar,
  },
  data() {
    return {
      user: {},
      webURL: "",
    };
  },
  computed: {
    isLoggedIn() {
      return !!Parse.User.current();
    },
  },
  created() {
    this.$store.dispatch("settings/getAppSettings");
    this.user = new Portal.Models.LoggedInUser();
    this.webURL = Parse.Config.current().get("webURL");
  },
  mounted() {
    const user = Parse.User.current();
    if (user && Beamer) {
      Beamer.update({
        user_firstname: user.get("firstName"),
        user_lastname: user.get("lastName"),
        user_email: user.get("email"),
      });
    }
    Beamer.init();
  },
};
</script>
<style lang="scss">
/* Your global styles here */

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0.3;
}
</style>
