/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import moment from 'moment';

export const OrderItem = Parse.Object.extend("OrderItem", {
  order: function () { return this.get("order"); },
  package: function () { return this.get("package"); },
  instances: function () { return this.instances; }, //item instances

  stitch: function (entry: { _instances: any; instances: any; }) { //expects entry in form {_instances:[parseObjects], instances:[stripped down json objects for children]};
    this.instances = entry._instances;
    _.each(this.instances, function (inst) {
      const match = _.find(entry.instances, function (_item, key) { return inst.id == key; });
      inst.stitch(match);
    });
  },

  note: function () { return this.get("note"); },
  mealPreference: function () { return this.get("meal_value"); },
  referralCode: function () { return this.get("referralCode"); },
  prettyTime: function () { return moment(this.createdAt).format("h:mmA"); },
  isCollected: function () {
    return (this.get("collection") !== undefined && this.get("collection") !== "") || this.get("collectionStatus") == 'COLLECTED' ? true : false;
  },
  isPartiallyCollected: function () {
    return this.get("collectionStatus") == 'PARTIALLY_COLLECTED' ? true : false;
  },
  collectionDetails: function () {
    return this.get("collection");
  },
  isCostumedEvent: function () {
    return this.get('package').get('section').get('presentation').get('season').get('type') === 'costumed';
  },
  eventName: function () {
    return this.get('package').get('section').get('presentation').get('season').get('name');
  },
  packageName: function () {
    return this.get('package').get('name');
  },
  validate: function (_attrs: any, _options: any) {
  },
  collectionUsers: function () {
    return this.get("collection_user")
  },
  packageOwner: function () {
    return this.get("packageOwner")
  },
  collector_user: function () {
    return this.get('collector_user');
  },
  collectedAt: function () {
    return this.get('collectedAt');
  },
  parse: function (response: any, _options: any) {
    return response;
  }
});