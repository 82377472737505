import SettingsState from "../type-defs/interfaces/settingsState";
import { ActionContext, Commit } from 'vuex';
import DataService from "../../services/DataService";
import { DATE_FORMATS } from '@/helpers/constants';

// initial state
const state: SettingsState = {
  upSellDuringAdditionalPayment: false,
  taxRate: 0,
  statementDescriptor: '',
  userAgreement: '',
  additionalPaymentsEnabled: false,
  showDistributionInfo: false,
  distributionInfo: '',
  contactInfo: '',
  enableAuthorizedCollectors: false,
  textAuthorizedPickupPrompt: '',
  currentSeason: '',
  accountNeedsReview: true,
  useNewCheckout: false,
  enableItemTemplateLinking: false,
  enableGlobalAddons: false,
  enableContact: false,
  enableCredits: false,
  enableResale: false,
  allowResale: false,
  enableWatch: false,
  enableNewCart: false,
  maxUserSessions: 0,
  enableSubscriptions: false,
  enablePaymentPlan: false,
  liveBanner: '',
  enableMuxAPI: false,
  supportedCurrencies: [],
  printfulApiKey: '',
  useAGGridDatatable: false,
  useEventCatalog: false,
  roleForDiscounts: '',
  offlineClientFeePct: 0,
  enablePackageOwners: true,
  allowSectionLeaderConnectAccount: true,
  paymentProcessor: '',
  writeAccessToSectionLeader: true,
  bandName: '',
  bandEmail: '',
  websiteText: [],
  newOrdersActive: false,
  allowOrderItemEdit: false,
  enableAdditionalCommentsOnOrderItem: false,
  bannerMessage: "",
  showBannerMessage: false,
  facebookPixelId: "",
  salesTaxPct: 0,
  minPaymentIsPct: false,
  minPaymentPct: 20,
  minPaymentFlat: 100,
  minAdditionalPaymentIsPct: false,
  minAdditionalPaymentPct: 20,
  minAdditionalPaymentFlat: 50,
  addOnsPaidInFull: false,
  askUsersForInstagramOnSignup: false,
  userInstagramRequired: false,
  userPhoneShown: false,
  isPhoneNumberRequired: false,
  userAddressShown: false,
  userAddressRequired: false,
  userCountryShown: false,
  userCountryRequired: false,
  userGenderShown: false,
  userGenderRequired: false,
  userDOBShown: false,
  userDOBRequired: false,
  profileImageRequired: false,
  profileImageShown: false,
  cartExpiryTime: 0,
  band_name: '',
  band_address: '',
  band_email: '',
  band_color: '',
  band_header_img: '',
  band_url: '',
  facebook_handle: '',
  instagram_handle: '',
  twitter_handle: '',
  tiktok_handle: '',
  youtube_handle: '',
  sectionLeaderCanCreateDiscounts: false,
  website_text_authorized_pickup_prompt: '',
  dateOfBirthFormat: DATE_FORMATS['MM-DD-YYYY'],
  minCustomerAge: 18,
}

// getters
const getters = {
  upSellDuringAdditionalPayment: () => state.upSellDuringAdditionalPayment,
  taxRate: () => parseFloat(state.taxRate as unknown as string) || 0,
  statementDescriptor: () => state.statementDescriptor,
  userAgreement: () => state.userAgreement,
  convenienceFeePct: () => Parse.Config.current().get("convenienceFeePct"),
  marketplaceActive: () => Parse.Config.current().get("marketplaceActive"),
  useGender: () => Parse.Config.current().get("useGender"),
  webURL: () => Parse.Config.current().get("webURL"),

  currency: () => paidutils.getCurrencyCode(),

  currencySymbol: () => paidutils.getCurrencySymbol(),
  additionalPaymentsEnabled: () => state.additionalPaymentsEnabled,
  showDistributionInfo: () => state.showDistributionInfo,
  distributionInfo: () => state.distributionInfo,
  contactInfo: () => state.contactInfo,
  enableAuthorizedCollectors: () => state.enableAuthorizedCollectors,
  textAuthorizedPickupPrompt: () => state.textAuthorizedPickupPrompt,
  currentSeason: () => state.currentSeason,
  accountNeedsReview: () => state.accountNeedsReview,
  useNewCheckout: () => state.useNewCheckout,
  enableItemTemplateLinking: () => state.enableItemTemplateLinking,
  enableGlobalAddons: () => state.enableGlobalAddons,
  enableContact: () => state.enableContact,
  enableCredits: () => state.enableCredits,
  enableResale: () => state.enableResale,
  allowResale: () => state.allowResale,
  enableWatch: () => state.enableWatch,
  enableNewCart: () => state.enableNewCart,
  maxUserSessions: () => state.maxUserSessions,
  enableSubscriptions: () => state.enableSubscriptions,
  enablePaymentPlan: () => state.enablePaymentPlan,
  liveBanner: () => state.liveBanner,
  enableMuxAPI: () => state.enableMuxAPI,
  supportedCurrencies: () => state.supportedCurrencies,
  printfulApiKey: () => state.printfulApiKey,
  useAGGridDatatable: () => state.useAGGridDatatable,
  useEventCatalog: () => state.useEventCatalog,
  roleForDiscounts: () => state.roleForDiscounts,
  offlineClientFeePct: () => state.offlineClientFeePct,
  enablePackageOwners: () => state.enablePackageOwners,
  allowSectionLeaderConnectAccount: () => state.allowSectionLeaderConnectAccount,
  writeAccessToSectionLeader: () => state.writeAccessToSectionLeader,
  bandName: () => state.bandName,
  bandEmail: () => state.bandEmail,
  websiteText: () => state.websiteText,
  paymentProcessor: () => state.paymentProcessor,
  newOrdersActive: () => state.newOrdersActive,
  allowOrderItemEdit: () => state.allowOrderItemEdit,
  enableAdditionalCommentsOnOrderItem: () => state.enableAdditionalCommentsOnOrderItem,
  bannerMessage: () => state.bannerMessage,
  showBannerMessage: () => state.showBannerMessage,
  facebookPixelId: () => state.facebookPixelId,
  salesTaxPct: () => state.salesTaxPct,
  minPaymentIsPct: () => state.minPaymentIsPct,
  minPaymentPct: () => state.minPaymentPct,
  minPaymentFlat: () => state.minPaymentFlat,
  minAdditionalPaymentIsPct: () => state.minAdditionalPaymentIsPct,
  minAdditionalPaymentPct: () => state.minAdditionalPaymentPct,
  minAdditionalPaymentFlat: () => state.minAdditionalPaymentFlat,
  addOnsPaidInFull: () => state.addOnsPaidInFull,
  askUsersForInstagramOnSignup: () => state.askUsersForInstagramOnSignup,
  userInstagramRequired: () => state.userInstagramRequired,
  userPhoneShown: () => state.userPhoneShown,
  isPhoneNumberRequired: () => state.isPhoneNumberRequired,
  userAddressShown: () => state.userAddressShown,
  userAddressRequired: () => state.userAddressRequired,
  userCountryShown: () => state.userCountryShown,
  userCountryRequired: () => state.userCountryRequired,
  userGenderShown: () => state.userGenderShown,
  userGenderRequired: () => state.userGenderRequired,
  userDOBShown: () => state.userDOBShown,
  userDOBRequired: () => state.userDOBRequired,
  profileImageRequired: () => state.profileImageRequired,
  profileImageShown: () => state.profileImageShown,
  cartExpiryTime: () => state.cartExpiryTime,
  band_name: () => state.band_name,
  band_address: () => state.band_address,
  band_email: () => state.band_email,
  band_color: () => state.band_color,
  band_header_img: () => state.band_header_img,
  band_url: () => state.band_url,
  facebook_handle: () => state.facebook_handle,
  instagram_handle: () => state.instagram_handle,
  twitter_handle: () => state.twitter_handle,
  tiktok_handle: () => state.tiktok_handle,
  youtube_handle: () => state.youtube_handle,
  sectionLeaderCanCreateDiscounts: () => state.sectionLeaderCanCreateDiscounts,
  dateOfBirthFormat: () => state.dateOfBirthFormat,
  minCustomerAge: () => state.minCustomerAge,
}

// actons
const actions = {
  getAppSettings: async ({ commit }: ActionContext<SettingsState, any>): Promise<any> => {

    const settings: any = await paidutils.getAppSettings();
    commitSettings(commit, settings);
    console.info('Settings loaded');
    return settings;
  },
  getNotCachedAppSettings: async ({ commit }: ActionContext<SettingsState, any>): Promise<any> => {
    const settings = await Parse.Cloud.run('getAppSettings');
    commitSettings(commit, settings);
    return settings;
  },
  updatePrintfulApiKey: async ({ commit }: ActionContext<SettingsState, any>, printfulApiKey: string): Promise<any> => {
    await DataService.getInstance().setAppSettings({ printfulApiKey })
    commit('printfulApiKey', printfulApiKey)
  },
  update: async ({ commit }: ActionContext<SettingsState, any>, { key, value }: { key: string, value: string }): Promise<any> => {
    try {
      await DataService.getInstance().updateSetting(key, value);
      commit(key, value);
    }
    catch (e) {
      console.log(e);
    }
  },
  updateWebsiteText: async ({ commit }: ActionContext<SettingsState, any>, clientConfig: any): Promise<any> => {
    await DataService.getInstance().setAppSettings(clientConfig);

    const settings = await Parse.Cloud.run("getAppSettings");

    commit('websiteText', paidutils.getWebsiteTextMapFromSettings(settings, false))
  },
  setAppSettings: async ({ commit }: ActionContext<SettingsState, any>, clientConfig: any): Promise<any> => {
    await DataService.getInstance().setAppSettings(clientConfig);
    const settings = await Parse.Cloud.run("getAppSettings");
    commitSettings(commit, settings);
  },
}

// mutations
const mutations = {
  upSellDuringAdditionalPayment(state: SettingsState, upSellDuringAdditionalPayment: boolean) {
    state.upSellDuringAdditionalPayment = upSellDuringAdditionalPayment
  },
  taxRate(state: SettingsState, taxRate: number) {
    state.taxRate = taxRate
  },
  statementDescriptor(state: SettingsState, statementDescriptor: string) {
    state.statementDescriptor = statementDescriptor
  },
  userAgreement(state: SettingsState, userAgreement: string) {
    state.userAgreement = userAgreement
  },
  additionalPaymentsEnabled(state: SettingsState, additionalPaymentsEnabled: boolean) {
    state.additionalPaymentsEnabled = additionalPaymentsEnabled
  },
  showDistributionInfo(state: SettingsState, showDistributionInfo: boolean) {
    state.showDistributionInfo = showDistributionInfo
  },
  distributionInfo(state: SettingsState, distributionInfo: string) {
    state.distributionInfo = distributionInfo
  },
  contactInfo(state: SettingsState, contactInfo: string) {
    state.contactInfo = contactInfo
  },
  enableAuthorizedCollectors(state: SettingsState, enableAuthorizedCollectors: boolean) {
    state.enableAuthorizedCollectors = enableAuthorizedCollectors
  },
  textAuthorizedPickupPrompt(state: SettingsState, textAuthorizedPickupPrompt: string) {
    state.textAuthorizedPickupPrompt = textAuthorizedPickupPrompt
  },
  currentSeason(state: SettingsState, currentSeason: string) {
    state.currentSeason = currentSeason
  },
  accountNeedsReview(state: SettingsState, accountNeedsReview: boolean) {
    state.accountNeedsReview = accountNeedsReview;
  },
  useNewCheckout(state: SettingsState, useNewCheckout: boolean) {
    state.useNewCheckout = useNewCheckout;
  },
  enableItemTemplateLinking(state: SettingsState, enableItemTemplateLinking: boolean) {
    state.enableItemTemplateLinking = enableItemTemplateLinking;
  },
  enableGlobalAddons(state: SettingsState, enableGlobalAddons: boolean) {
    state.enableGlobalAddons = enableGlobalAddons
  },
  enableContact(state: SettingsState, enableContact: boolean) {
    state.enableContact = enableContact
  },
  enableCredits(state: SettingsState, enableCredits: boolean) {
    state.enableCredits = enableCredits
  },
  enableResale(state: SettingsState, enableResale: boolean) {
    state.enableResale = enableResale
  },
  allowResale(state: SettingsState, allowResale: boolean) {
    state.allowResale = allowResale
  },
  enableWatch(state: SettingsState, enableWatch: boolean) {
    state.enableWatch = enableWatch;
  },
  enableNewCart(state: SettingsState, enableNewCart: boolean) {
    state.enableNewCart = enableNewCart;
  },
  maxUserSessions(state: SettingsState, maxUserSessions: number) {
    state.maxUserSessions = maxUserSessions;
  },
  liveBanner(state: SettingsState, liveBanner: string) {
    state.liveBanner = liveBanner;
  },
  enableMuxAPI(state: SettingsState, enableMuxAPI: boolean) {
    state.enableMuxAPI = enableMuxAPI;
  },
  enableSubscriptions(state: SettingsState, enableSubscriptions: boolean) {
    state.enableSubscriptions = enableSubscriptions;
  },
  enablePaymentPlan(state: SettingsState, enablePaymentPlan: boolean) {
    state.enablePaymentPlan = enablePaymentPlan;
  },
  supportedCurrencies(state: SettingsState, supportedCurrencies: Array<string>) {
    state.supportedCurrencies = supportedCurrencies;
  },
  printfulApiKey(state: SettingsState, printfulApiKey: string) {
    state.printfulApiKey = printfulApiKey
  },
  useAGGridDatatable(state: SettingsState, useAGGridDatatable: boolean) {
    state.useAGGridDatatable = useAGGridDatatable;
  },
  useEventCatalog(state: SettingsState, useEventCatalog: boolean) {
    state.useEventCatalog = useEventCatalog;
  },
  roleForDiscounts(state: SettingsState, roleForDiscounts: string) {
    state.roleForDiscounts = roleForDiscounts;
  },
  offlineClientFeePct(state: SettingsState, offlineClientFeePct: number) {
    state.offlineClientFeePct = offlineClientFeePct;
  },
  enablePackageOwners(state: SettingsState, enablePackageOwners: boolean) {
    state.enablePackageOwners = enablePackageOwners;
  },
  allowSectionLeaderConnectAccount(state: SettingsState, allowSectionLeaderConnectAccount: boolean) {
    state.allowSectionLeaderConnectAccount = allowSectionLeaderConnectAccount;
  },
  writeAccessToSectionLeader(state: SettingsState, writeAccessToSectionLeader: boolean) {
    state.writeAccessToSectionLeader = writeAccessToSectionLeader;
  },
  bandName(state: SettingsState, bandName: string) {
    state.bandName = bandName;
  },
  bandEmail(state: SettingsState, bandEmail: string) {
    state.bandEmail = bandEmail;
  },
  websiteText(state: SettingsState, websiteText: any) {
    state.websiteText = websiteText;
  },
  paymentProcessor(state: SettingsState, paymentProcessor: string) {
    state.paymentProcessor = paymentProcessor
  },
  newOrdersActive(state: SettingsState, newOrdersActive: boolean) {
    state.newOrdersActive = newOrdersActive;
  },
  allowOrderItemEdit(state: SettingsState, allowOrderItemEdit: boolean) {
    state.allowOrderItemEdit = allowOrderItemEdit;
  },
  enableAdditionalCommentsOnOrderItem(state: SettingsState, enableAdditionalCommentsOnOrderItem: boolean) {
    state.enableAdditionalCommentsOnOrderItem = enableAdditionalCommentsOnOrderItem;
  },
  bannerMessage(state: SettingsState, bannerMessage: string) {
    state.bannerMessage = bannerMessage;
  },
  showBannerMessage(state: SettingsState, showBannerMessage: boolean) {
    state.showBannerMessage = showBannerMessage;
  },
  facebookPixelId(state: SettingsState, facebookPixelId: string) {
    state.facebookPixelId = facebookPixelId;
  },
  salesTaxPct(state: SettingsState, salesTaxPct: number) {
    state.salesTaxPct = salesTaxPct;
  },
  minPaymentIsPct(state: SettingsState, minPaymentIsPct: boolean) {
    state.minPaymentIsPct = minPaymentIsPct;
  },
  minPaymentPct(state: SettingsState, minPaymentPct: number) {
    state.minPaymentPct = minPaymentPct;
  },
  minPaymentFlat(state: SettingsState, minPaymentFlat: number) {
    state.minPaymentFlat = minPaymentFlat;
  },
  minAdditionalPaymentIsPct(state: SettingsState, minAdditionalPaymentIsPct: boolean) {
    state.minAdditionalPaymentIsPct = minAdditionalPaymentIsPct;
  },
  minAdditionalPaymentPct(state: SettingsState, minAdditionalPaymentPct: number) {
    state.minAdditionalPaymentPct = minAdditionalPaymentPct;
  },
  minAdditionalPaymentFlat(state: SettingsState, minAdditionalPaymentFlat: number) {
    state.minAdditionalPaymentFlat = minAdditionalPaymentFlat;
  },
  addOnsPaidInFull(state: SettingsState, addOnsPaidInFull: boolean) {
    state.addOnsPaidInFull = addOnsPaidInFull;
  },
  askUsersForInstagramOnSignup(state: SettingsState, askUsersForInstagramOnSignup: boolean) {
    state.askUsersForInstagramOnSignup = askUsersForInstagramOnSignup;
  },
  userInstagramRequired(state: SettingsState, userInstagramRequired: boolean) {
    state.userInstagramRequired = userInstagramRequired;
  },
  userPhoneShown(state: SettingsState, userPhoneShown: boolean) {
    state.userPhoneShown = userPhoneShown;
  },
  isPhoneNumberRequired(state: SettingsState, isPhoneNumberRequired: boolean) {
    state.isPhoneNumberRequired = isPhoneNumberRequired;
  },
  userAddressShown(state: SettingsState, userAddressShown: boolean) {
    state.userAddressShown = userAddressShown;
  },
  userAddressRequired(state: SettingsState, userAddressRequired: boolean) {
    state.userAddressRequired = userAddressRequired;
  },
  userCountryShown(state: SettingsState, userCountryShown: boolean) {
    state.userCountryShown = userCountryShown;
  },
  userCountryRequired(state: SettingsState, userCountryRequired: boolean) {
    state.userCountryRequired = userCountryRequired;
  },
  userGenderShown(state: SettingsState, userGenderShown: boolean) {
    state.userGenderShown = userGenderShown;
  },
  userGenderRequired(state: SettingsState, userGenderRequired: boolean) {
    state.userGenderRequired = userGenderRequired;
  },
  userDOBShown(state: SettingsState, userDOBShown: boolean) {
    state.userDOBShown = userDOBShown;
  },
  userDOBRequired(state: SettingsState, userDOBRequired: boolean) {
    state.userDOBRequired = userDOBRequired;
  },
  profileImageRequired(state: SettingsState, profileImageRequired: boolean) {
    state.profileImageRequired = profileImageRequired;
  },
  profileImageShown(state: SettingsState, profileImageShown: boolean) {
    state.profileImageShown = profileImageShown;
  },
  cartExpiryTime(state: SettingsState, cartExpiryTime: number) {
    state.cartExpiryTime = cartExpiryTime;
  },
  band_name(state: SettingsState, band_name: string) {
    state.band_name = band_name;
  },
  band_address(state: SettingsState, band_address: string) {
    state.band_address = band_address;
  },
  band_email(state: SettingsState, band_email: string) {
    state.band_email = band_email;
  },
  band_color(state: SettingsState, band_color: string) {
    state.band_color = band_color;
  },
  band_header_img(state: SettingsState, band_header_img: string) {
    state.band_header_img = band_header_img;
  },
  band_url(state: SettingsState, band_url: string) {
    state.band_url = band_url;
  },
  facebook_handle(state: SettingsState, facebook_handle: string) {
    state.facebook_handle = facebook_handle;
  },
  instagram_handle(state: SettingsState, instagram_handle: string) {
    state.instagram_handle = instagram_handle;
  },
  twitter_handle(state: SettingsState, twitter_handle: string) {
    state.twitter_handle = twitter_handle;
  },
  tiktok_handle(state: SettingsState, tiktok_handle: string) {
    state.tiktok_handle = tiktok_handle;
  },
  youtube_handle(state: SettingsState, youtube_handle: string) {
    state.youtube_handle = youtube_handle;
  },
  sectionLeaderCanCreateDiscounts(state: SettingsState, sectionLeaderCanCreateDiscounts: boolean) {
    state.sectionLeaderCanCreateDiscounts = sectionLeaderCanCreateDiscounts;
  },
  dateOfBirthFormat(state: SettingsState, dateOfBirthFormat: string) {
    state.dateOfBirthFormat = dateOfBirthFormat;
  },
  minCustomerAge(state: SettingsState, minCustomerAge: number) {
    state.minCustomerAge = minCustomerAge;
  },

}

const commitSettings = (commit: Commit, settings: SettingsState) => {
  commit('upSellDuringAdditionalPayment', settings.upSellDuringAdditionalPayment);
  commit('taxRate', parseFloat(settings.salesTaxPct as unknown as string) || 0);
  commit('statementDescriptor', settings.statementDescriptor)
  commit('userAgreement', settings.userAgreement)
  commit('additionalPaymentsEnabled', settings.additionalPaymentsEnabled)
  commit('showDistributionInfo', settings.showDistributionInfo)
  commit('distributionInfo', settings.distributionInfo)
  commit('contactInfo', settings.contactInfo)
  commit('enableAuthorizedCollectors', settings.enableAuthorizedCollectors)

  commit('textAuthorizedPickupPrompt', paidutils.replaceMergeTags(settings.website_text_authorized_pickup_prompt, settings))
  commit('currentSeason', settings.currentSeason)
  commit('accountNeedsReview', settings.accountNeedsReview)
  commit('useNewCheckout', settings.useNewCheckout)
  commit('enableItemTemplateLinking', settings.enableItemTemplateLinking)
  commit('enableGlobalAddons', settings.enableGlobalAddons)
  commit('enableContact', settings.enableContact)
  commit('enableCredits', settings.enableCredits)
  commit('enableResale', settings.enableResale)
  commit('allowResale', settings.allowResale)
  commit('enableWatch', settings.enableWatch)
  commit('enableNewCart', settings.enableNewCart)
  commit('maxUserSessions', settings.maxUserSessions)
  commit('enableSubscriptions', settings.enableSubscriptions)
  commit('enablePaymentPlan', settings.enablePaymentPlan)
  commit('liveBanner', settings.liveBanner)
  commit('enableMuxAPI', settings.enableMuxAPI)
  commit('supportedCurrencies', settings.supportedCurrencies)
  commit('printfulApiKey', settings.printfulApiKey)
  commit('printfulApiKey', settings.printfulApiKey)
  commit('useAGGridDatatable', settings.useAGGridDatatable)
  commit('useEventCatalog', settings.useEventCatalog)
  commit('roleForDiscounts', settings.roleForDiscounts)
  commit('offlineClientFeePct', settings.offlineClientFeePct)
  commit('enablePackageOwners', settings.enablePackageOwners)
  commit('allowSectionLeaderConnectAccount', settings.allowSectionLeaderConnectAccount)
  commit('writeAccessToSectionLeader', settings.writeAccessToSectionLeader)
  commit('paymentProcessor', settings.paymentProcessor)
  commit('bandName', settings.band_name)
  commit('bandEmail', settings.band_email)
  commit('newOrdersActive', settings.newOrdersActive)
  commit('allowOrderItemEdit', settings.allowOrderItemEdit)
  commit('enableAdditionalCommentsOnOrderItem', settings.enableAdditionalCommentsOnOrderItem)
  commit('bannerMessage', settings.bannerMessage)
  commit('showBannerMessage', settings.showBannerMessage)
  commit('facebookPixelId', settings.facebookPixelId)
  commit('salesTaxPct', settings.salesTaxPct)
  commit('minPaymentIsPct', settings.minPaymentIsPct)
  commit('minPaymentPct', settings.minPaymentPct)
  commit('minPaymentFlat', settings.minPaymentFlat)
  commit('minAdditionalPaymentIsPct', settings.minAdditionalPaymentIsPct)
  commit('minAdditionalPaymentPct', settings.minAdditionalPaymentPct)
  commit('minAdditionalPaymentFlat', settings.minAdditionalPaymentFlat)
  commit('addOnsPaidInFull', settings.addOnsPaidInFull)
  commit('askUsersForInstagramOnSignup', settings.askUsersForInstagramOnSignup)
  commit('userInstagramRequired', settings.userInstagramRequired)
  commit('userPhoneShown', settings.userPhoneShown)
  commit('isPhoneNumberRequired', settings.isPhoneNumberRequired)
  commit('userAddressShown', settings.userAddressShown)
  commit('userAddressRequired', settings.userAddressRequired)
  commit('userCountryShown', settings.userCountryShown)
  commit('userCountryRequired', settings.userCountryRequired)
  commit('userGenderShown', settings.userGenderShown)
  commit('userGenderRequired', settings.userGenderRequired)
  commit('userDOBShown', settings.userDOBShown)
  commit('userDOBRequired', settings.userDOBRequired)
  commit('profileImageRequired', settings.profileImageRequired)
  commit('profileImageShown', settings.profileImageShown)
  commit('cartExpiryTime', settings.cartExpiryTime)
  commit('band_name', settings.band_name)
  commit('band_address', settings.band_address)
  commit('band_email', settings.band_email)
  commit('band_color', settings.band_color)
  commit('band_header_img', settings.band_header_img)
  commit('band_url', settings.band_url)
  commit('facebook_handle', settings.facebook_handle)
  commit('instagram_handle', settings.instagram_handle)
  commit('twitter_handle', settings.twitter_handle)
  commit('youtube_handle', settings.youtube_handle)
  commit('tiktok_handle', settings.tiktok_handle)
  commit('sectionLeaderCanCreateDiscounts', settings.sectionLeaderCanCreateDiscounts)
  commit('dateOfBirthFormat', settings.dateOfBirthFormat)
  commit('minCustomerAge', settings.minCustomerAge)

  commit('websiteText', paidutils.getWebsiteTextMapFromSettings(settings, false))
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
