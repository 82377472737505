/* eslint-disable @typescript-eslint/no-unused-vars */
export const EntitlementLimit = Parse.Object.extend("EntitlementLimit", {

  initialize: function () {
  },

  paid: function () { return this.get("paid"); },
  limit: function () { return this.get("limit"); },
  template: function () { return this.get("template"); },

  defaults: {
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});
