import EventSection from "./eventSection"

export default class Event {
  id = ''
  name = ''
  slug = ''
  desc = ''
  terms = ''
  startDateTime: Date | null = null
  endDateTime: Date | null = null
  isSelling = false
  image: Parse.File | null = null
  thumb_sm: Parse.File | null = null
  thumb_sq: Parse.File | null = null
  thumbnail: Parse.File | null = null
  useMeals = false
  mealsArray: string[] = ['Meat', 'Vegetarian']
  type: 'livestreamed' | 'costumed' | 'ticketed' = 'costumed'
  sections: EventSection[] = []
  presentationIds: string[] = []
  address: object = {
    addressLine1: "",
    addressLine2: "",
    city: "",
    zip: "",
    state: "",
    country: "",
    coords: {
      latitude: null,
      longitude: null,
    }
  }
  salesChannels: string[] = []
  deleted = false
  defaultCommitteeMember: object = {}
  timezone = ''
  inPreviewMode = false
  resaleEnabled = true

  private parseEvent = new Parse.Object()
  private dirty = false

  public constructor(event?: Parse.Object, presentationIds?: string[]) {
    this.parseEvent = event ? event : this.parseEvent
    this.id = event ? event.id : this.id
    this.name = event ? event.attributes.name : this.name
    this.slug = event ? event.attributes.slug : this.slug
    this.desc = event ? event.attributes.desc : this.desc
    this.terms = event ? event.attributes.terms : this.terms
    this.startDateTime = event ? event.attributes.startDateTime : this.startDateTime
    this.endDateTime = event ? event.attributes.endDateTime : this.endDateTime
    this.isSelling = event ? event.attributes.isSelling : this.isSelling
    this.image = event ? event.attributes.image : this.image
    this.thumb_sm = event ? event.attributes.thumb_sm : this.thumb_sm
    this.thumb_sq = event ? event.attributes.thumb_sq : this.thumb_sq
    this.thumbnail = event ? event.attributes.thumbnail : this.thumbnail
    this.useMeals = event ? event.attributes.useMeals : this.useMeals
    this.mealsArray = event ? event.attributes.mealsArray || this.mealsArray : this.mealsArray
    this.type = event ? event.attributes.type : this.type
    this.presentationIds = presentationIds ? presentationIds : []
    this.address = event ? event.attributes.address : this.address
    this.defaultCommitteeMember = event ? event.attributes.defaultCommitteeMember : this.defaultCommitteeMember
    this.salesChannels = event? event.attributes.salesChannels || this.salesChannels : this.salesChannels
    this.deleted = event ? event.attributes.deleted : false
    this.timezone = event ? event.attributes.timezone : this.timezone
    this.inPreviewMode = event ? event.attributes.inPreviewMode : false;
    this.resaleEnabled = event ? event.attributes.resaleEnabled : false;
    this.sections = []
    this.dirty = false
  }

  public updateParseEvent(): Parse.Object {
    if(this.parseEvent.get('name') !== this.name) {
      this.dirty = true
      this.parseEvent.set('name', this.name)
    }

    if(this.parseEvent.get('slug') !== this.slug) {
      this.dirty = true
      this.parseEvent.set('slug', this.slug)
    }

    if(this.parseEvent.get('desc') !== this.desc) {
      this.dirty = true
      this.parseEvent.set('desc', this.desc)
    }

    if(this.parseEvent.get('terms') !== this.terms) {
      this.dirty = true
      this.parseEvent.set('terms', this.terms)
    }

    if(this.parseEvent.get('startDateTime') !== this.startDateTime) {
      this.dirty = true
      this.parseEvent.set('startDateTime', this.startDateTime)
    }

    if(this.parseEvent.get('endDateTime') !== this.endDateTime) {
      this.dirty = true
      this.parseEvent.set('endDateTime', this.endDateTime)
    }

    if(this.parseEvent.get('isSelling') !== this.isSelling) {
      this.dirty = true
      this.parseEvent.set('isSelling', this.isSelling)
    }

    if(this.parseEvent.get('image') !== this.image) {
      this.dirty = true
      this.parseEvent.set('image', this.image)
    }

    if(this.parseEvent.get('useMeals') !== this.useMeals) {
      this.dirty = true
      this.parseEvent.set('useMeals', this.useMeals)
    }

    if(this.parseEvent.get('mealsArray') !== this.mealsArray) {
      this.dirty = true
      this.parseEvent.set('mealsArray', this.mealsArray)
    }

    if(this.parseEvent.get('type') !== this.type) {
      this.dirty = true
      this.parseEvent.set('type', this.type)
    }

    if(this.parseEvent.get('address') !== this.address) {
      this.dirty = true
      this.parseEvent.set('address', this.address)
    }

    if(this.parseEvent.get('defaultCommitteeMember') !== this.defaultCommitteeMember) {
      this.dirty = true
      this.parseEvent.set('defaultCommitteeMember', this.defaultCommitteeMember)
    }

    if(this.parseEvent.get('salesChannels') !== this.salesChannels) {
      this.dirty = true
      this.parseEvent.set('salesChannels', this.salesChannels)
    }

    if(this.parseEvent.get('deleted') !== this.deleted) {
      this.dirty = true
      this.parseEvent.set('deleted', this.deleted)
    }

    if(this.parseEvent.get('timezone') !== this.timezone) {
      this.dirty = true
      this.parseEvent.set('timezone', this.timezone)
    }

    if(this.parseEvent.get('inPreviewMode') !== this.inPreviewMode) {
      this.dirty = true
      this.parseEvent.set('inPreviewMode', this.inPreviewMode)
    }

    if(this.parseEvent.get('resaleEnabled') !== this.resaleEnabled) {
      this.dirty = true
      this.parseEvent.set('resaleEnabled', this.resaleEnabled)
    }
    return this.parseEvent
  }

  public isDirty(): boolean {
    return this.dirty
  }
}