import { ActionContext } from 'vuex';
import DataService from "../../services/DataService";
const ds = DataService.getInstance();

// initial state
const state: any = {
  timeline: {
    timelineItems: [],
    loading: false,
  },
}

function resetTimelineState(state: any) {
  state.timeline.timelineItems = []
}

// getters
const getters = {
  timelineItems: () => state.timeline.timelineItems,
  timelineLoading: () => state.timeline.loading,
}

// actons
const actions = {
  getTimelineItems: async ({ commit, state }: ActionContext<any, any>, { entityId, entityName }: { entityId: string, entityName: string }) => {
    commit('loading', true);
    resetTimelineState(state)
    const timelineItems = await ds.getTimeLine(entityId, entityName);
    commit('timelineItems', timelineItems)
    commit('loading', false);
  },
}

// mutations
const mutations = {
  timelineItems(state: any, timelineItems: any) {
    state.timeline.timelineItems = timelineItems;
  },

  loading(state: any, loading: boolean) {
    state.timeline.loading = loading;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
