/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';

export const Transaction = Parse.Object.extend("Transaction", {
  initialize: function () {
    this.isHighlighted = false;
  },

  createTime: function () { return moment(this.createdAt).format("YYYY-MM-DD"); },
  amount: function () { return paidutils.formatMoney(this.get("amount")); },
  note: function () { return this.get("note"); },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});