import { getParsePointer } from "../../lib/common"
import EventPackageImage from "./eventPackageImage"

export default class EventPackage {
  sectionId: string | null = null
  id = ''
  name = ''
  desc = ''
  cost = 0
  image: Parse.File | null = null
  images: EventPackageImage[] = []
  thumbnail: Parse.File | null = null
  thumbnail_small: Parse.File | null = null
  thumbnail_large: Parse.File | null = null
  online = false
  soldOutOverride = false
  qtyAvailable = 0
  maxPackagesPerPerson = 0
  qtySold = 0
  minPaymentOverride: number | null = null
  sortOrder = 0
  useApplication = false
  allowNewApplication = false
  maxApplicationPerCustomer = 0
  maxDiscountCodeAllowed = 0
  section: any = null

  private parseEventPackage: Parse.Object = new Parse.Object()
  private dirty = false

  public constructor(pckage?: Parse.Object) {
    this.parseEventPackage = pckage ? pckage : this.parseEventPackage
    this.id = pckage ? pckage.id : this.id

    this.name = pckage ? pckage.get('name') : this.name
    this.desc = pckage ? pckage.get('desc') : this.desc
    this.cost = pckage ? pckage.get('cost') : this.cost
    this.online = pckage ? pckage.get('online') : this.online
    this.image = pckage ? pckage.get('image') : this.image
    this.images = []
    this.thumbnail = pckage ? pckage.get('thumbnail') : this.thumbnail
    this.thumbnail_small = pckage ? pckage.get('thumbnail_small') : this.thumbnail_small
    this.thumbnail_large = pckage ? pckage.get('thumbnail_large') : this.thumbnail_large
    this.soldOutOverride = pckage ? pckage.get('soldOutOverride') : this.soldOutOverride
    this.qtyAvailable = pckage ? pckage.get('qtyAvailable') : this.qtyAvailable
    this.maxPackagesPerPerson = pckage ? pckage.get('maxPackagesPerPerson') : this.maxPackagesPerPerson
    this.qtySold = pckage ? pckage.get('qtySold') : this.qtySold
    this.minPaymentOverride = pckage ? pckage.get('minPaymentOverride') : this.minPaymentOverride
    this.useApplication = pckage ? pckage.get('useApplication') : this.useApplication
    this.allowNewApplication = pckage ? pckage.get('allowNewApplication') : this.allowNewApplication
    this.maxApplicationPerCustomer = pckage ? pckage.get('maxApplicationPerCustomer') : this.maxApplicationPerCustomer
    this.maxDiscountCodeAllowed = pckage ? pckage.get('maxDiscountCodeAllowed') : this.maxDiscountCodeAllowed

    if(pckage && pckage.get('section')) {
      const section = pckage.get('section')
      this.sectionId = section.objectId || section.id
    }

    this.sortOrder = pckage ? pckage.get('sortOrder') || 0 : this.sortOrder
    this.dirty = false
  }

  public updateParsePackage(): Parse.Object {
    if(this.parseEventPackage.get('name') !== this.name) {
      this.dirty = true
      this.parseEventPackage.set('name', this.name)
    }

    if(this.parseEventPackage.get('desc') !== this.desc) {
      this.dirty = true
      this.parseEventPackage.set('desc', this.desc)
    }

    if(this.parseEventPackage.get('cost') !== this.cost) {
      this.dirty = true
      this.parseEventPackage.set('cost', this.cost)
    }

    if(this.parseEventPackage.get('online') !== this.online) {
      this.dirty = true
      this.parseEventPackage.set('online', this.online)
    }

    if(this.parseEventPackage.get('image') !== this.image) {
      this.dirty = true
      this.parseEventPackage.set('image', this.image)
    }

    if(this.parseEventPackage.get('soldOutOverride') !== this.soldOutOverride) {
      this.dirty = true
      this.parseEventPackage.set('soldOutOverride', this.soldOutOverride)
    }

    if(this.parseEventPackage.get('qtyAvailable') !== this.qtyAvailable) {
      this.dirty = true
      this.parseEventPackage.set('qtyAvailable', this.qtyAvailable)
    }

    if(this.parseEventPackage.get('maxPackagesPerPerson') !== this.maxPackagesPerPerson) {
      this.dirty = true
      this.parseEventPackage.set('maxPackagesPerPerson', this.maxPackagesPerPerson)
    }

    if(this.parseEventPackage.get('qtySold') !== this.qtySold) {
      this.dirty = true
      this.parseEventPackage.set('qtySold', this.qtySold)
    }

    if(this.parseEventPackage.get('minPaymentOverride') !== this.minPaymentOverride) {
      this.dirty = true
      this.parseEventPackage.set('minPaymentOverride', this.minPaymentOverride)
    }

    if(this.sectionId && this.parseEventPackage.get('section').id !== this.sectionId) {
      this.dirty = true
      const sectionPointer = getParsePointer('Section', this.sectionId)
      this.parseEventPackage.set('section', sectionPointer)
    }

    if(this.parseEventPackage.get('sortOrder') !== this.sortOrder) {
      this.dirty = true
      this.parseEventPackage.set('sortOrder', this.sortOrder)
    }

    if(this.parseEventPackage.get('useApplication') !== this.useApplication) {
      this.dirty = true
      this.parseEventPackage.set('useApplication', this.useApplication)
    }

    if(this.parseEventPackage.get('allowNewApplication') !== this.allowNewApplication) {
      this.dirty = true
      this.parseEventPackage.set('allowNewApplication', this.allowNewApplication)
    }

    if(this.parseEventPackage.get('maxApplicationPerCustomer') !== this.maxApplicationPerCustomer) {
      this.dirty = true
      this.parseEventPackage.set('maxApplicationPerCustomer', this.maxApplicationPerCustomer)
    }

    if (this.parseEventPackage.get('maxDiscountCodeAllowed') !== this.maxDiscountCodeAllowed) {
      this.dirty = true
      this.parseEventPackage.set('maxDiscountCodeAllowed', this.maxDiscountCodeAllowed)
    }

    return this.parseEventPackage
  }

  public isDirty(): boolean {
    return this.dirty
  }

  public setNotDirty() {
    this.dirty = false
  }
}