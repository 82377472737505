/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';

export const OrderCollection = Parse.Object.extend("OrderCollection", {
  initialize: function () {
  },

  hasSignature: function () {
    return this.get("signature") ? true : false;
  },

  hasPicture: function () {
    return this.get("picture") ? true : false;
  },

  hasIdentification: function () {
    return this.get("identification_image") ? true : false;
  },

  signature: function () {
    if (this.get("signature")) {
      return this.get("signature").url();
    } else {
      return paidutils.getNoImageURL();
    }
  },
  picture: function () {
    if (this.get("picture")) {
      return this.get("picture").url();
    } else {
      return paidutils.getNoImageURL();
    }
  },
  identification: function () {
    if (this.get("identification_image")) {
      return this.get("identification_image").url();
    } else {
      return paidutils.getNoImageURL();
    }
  },
  collectedUser: function () { return this.get("collected_user"); },
  fromCashier: function () { return this.get("cashier"); },
  fromNowDate: function () { return moment(this.createdAt).fromNow(); },
  prettyDate: function () { return moment(this.createdAt).format("D MMM YYYY"); },
  collectedDate: function () { return moment(this.createdAt).format("YYYY/DD/MM"); },
  collectedTime: function () { return moment(this.createdAt).format("hh:mmA"); },

  defaults: {
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});