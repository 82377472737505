/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';

export const OrderMeta = Parse.Object.extend("Order", {
  initialize: function () {

  },

  currency: function () { return this.get("currency"); },
  totalCost: function () { return paidutils.formatMoney(this.get("totalCost"), this.currency()); },
  totalPaid: function () { return paidutils.formatMoney(this.get("amountPaid"), this.currency()); },
  balance: function () { return paidutils.formatMoney(this.balanceValue(), this.currency()); },
  balanceValue: function () { return this.get("totalCost") - this.get("amountPaid"); },
  orderDateTime: function () { return moment(this.createdAt).format("D MMM YYYY h:mmA"); },
  orderUpdatedAt: function () { return moment(this.updatedAt).format("D MMM YYYY h:mmA"); },
  prettyDate: function () { return moment(this.createdAt).format("D MMM YYYY"); },
  prettyTime: function () { return moment(this.createdAt).format("h:mmA"); },
  fromNowDate: function () { return moment(this.createdAt).fromNow(); },
  status: function () { return this.get("state"); },
  note: function () { return this.get("note"); },
  createdBy: function () {
    let isCreatedByPresent = false
    if (this.get("createdBy") !== undefined) {
      isCreatedByPresent = true
    }
    return isCreatedByPresent
  },
  createdByName: function () {
    return this.get("createdBy").get("firstName") + " " + this.get("createdBy").get("lastName");
  },
  createdById: function () { return this.get("createdBy").id },
  user: function () { return this.get("user"); },
  originalOwner: function () { return this.get("originalOwner"); },
  code: function () {
    let code;
    if (this.get("code")) {
      code = this.get("code").get("code");
    }
    return code;
  },
  committee: function () {
    let committee;
    if (this.get("code") && this.get("code").get("user")) {
      committee = this.get("code").get("user");
    }
    return committee;
  },
  mealPreference: function () {
    return paidutils.getMealName(this.get("meal"));
  },

  isCollected: function () {
    return this.get('collectionStatus') === 'collected' || this.get('collectionStatus') === 'COLLECTED';
  },

  isPartiallyCollected: function () {
    return this.get('collectionStatus') == 'partially_collected' || this.get('collectionStatus') === 'PARTIALLY_COLLECTED';
  },

  collectionDetails: function () {
    return this.get("collection");
  },

  collectionUsers: function () {
    const collection_users = [];
    collection_users.push(this.get("user"));
    if (this.get("collection_user")) {
      collection_users.push(this.get("collection_user"));
    }
    return collection_users;
  },

  isCancelled: function () {
    return this.get("cancelled");
  },

  isForReSale: function () {
    return this.get("isForReSale");
  },

  isReSold: function () {
    return this.get("isReSold");
  },


  package: function () { return this.get("package"); },

  defaults: {
  },

  validate: function (_attrs: any, _options: any) {

  },

  parse: function (response: any, _options: any) {
    return response;
  }
});
