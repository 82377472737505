import { ActionContext } from 'vuex';
import DataService from "../../../services/DataService";
import _ from 'lodash';
const ds = DataService.getInstance();

// initial state
const state: any = {
  order: {
    orderDetails: {},
    orderItems: [],
    orderPayments: [],
    orderCollections: [],
    ordersByUserId: [],
    refundPayments: {},
    nonRefundPayments: [],
    loading: { orderDetails: false, orderItems : false, orderPayments: false, orderCollections: false},
  },
}

function resetOrderDetailsState(state: any) {
  state.order.orderDetails = {}
}

function resetOrderItemsState(state: any) {
  state.order.orderItems = []
}

function resetOrderPaymentsState(state: any) {
  state.order.orderPayments = []
  state.order.refundPayments = {}
  state.order.nonRefundPayments = []
}

function resetOrderCollectionsState(state: any) {
  state.order.orderCollections = []
}

function resetOrdersByUserIdState(state: any) {
  state.order.ordersByUserId = []
}
// getters
const getters = {
  orderDetails: () => state.order.orderDetails,
  orderItems: () => state.order.orderItems,
  orderPayments: () => state.order.orderPayments,
  orderCollections: () => state.order.orderCollections,
  ordersByUserId: () => state.order.ordersByUserId,
  loading: () => state.order.loading,
  refundPayments: () => state.order.refundPayments,
  nonRefundPayments: () => state.order.nonRefundPayments,
}

// actons
const actions = {
  getOrder: async ({ commit, state }: ActionContext<any, any>, orderId: string) => {
    commit('loading', { key: 'orderDetails', isLoading: true });
    resetOrderDetailsState(state)
    const order = await ds.getBasicOrderDetails(orderId)
    if (!order) {
      throw new Error('Order not found')
    }
    commit('orderDetails', order);
    commit('loading', { key: 'orderDetails', isLoading: false });
  },

  getOrderPayments: async ({ commit, state }: ActionContext<any, any>, orderId: string) => {
    commit('loading', { key: 'orderPayments', isLoading: true })
    resetOrderPaymentsState(state)
    const payments = await ds.getPaymentsForOrderId(orderId)
    const isMaster = await ds.isMaster();
    payments.forEach(function (payment: { isMaster: any; }) {
      payment.isMaster = isMaster;
    });
    commit('orderPayments', payments)
    commit('refundPayments', payments)
    commit('nonRefundPayments', payments)
    commit('loading', { key: 'orderPayments', isLoading: false })
  },

  getOrderItems: async ({ commit, state }: ActionContext<any, any>, orderId: string) => {
    commit('loading', { key: 'orderItems', isLoading: true });
    resetOrderItemsState(state)
    let orderItems = await ds.getOrderItemsCardDataForOrder(orderId)
    if(orderItems && orderItems.length > 0){
      orderItems = orderItems.filter((orderItem: any)=> orderItem.cancelled !== true);
    }
    commit('orderItems', orderItems)
    commit('loading', { key: 'orderItems', isLoading: false });
  },

  getOrderCollections: async ({ commit, state }: ActionContext<any, any>, orderId: string) => {
    commit('loading', { key: 'orderCollections', isLoading: true });
    resetOrderCollectionsState(state)
    const orderCollections = await ds.getOrderCollectionsForOrder(orderId)
    commit('orderCollections', orderCollections)
    commit('loading', { key: 'orderCollections', isLoading: false });
  },

  getOrdersByUserId: async ({ commit, state }: ActionContext<any, any>, userId: string) => {
    resetOrdersByUserIdState(state)
    const ordersByUserId = await ds.getOrdersForUserIdForSeason(userId,false)
    commit('ordersByUserId', ordersByUserId)
  },
}

// mutations
const mutations = {
  orderDetails(state: any, orderDetails: any) {
    state.order.orderDetails = orderDetails;
  },

  orderPayments(state: any, orderPayments: any) {
    state.order.orderPayments = orderPayments;
  },

  orderItems(state: any, orderItems: any) {
    state.order.orderItems = orderItems;
  },

  orderCollections(state: any, orderCollections: any) {
    state.order.orderCollections = orderCollections;
  },

  ordersByUserId(state: any, ordersByUserId: any) {
    state.order.ordersByUserId = ordersByUserId;
  },

  loading(state: any, loading: { key: string; isLoading: boolean; }){
    state.order.loading[loading.key] = loading.isLoading;
  },

  refundPayments(state: any, payments: any) {
    const refunds = payments.filter((payment: { parent: any; }) => payment.parent)
    state.order.refundPayments = _.groupBy(refunds, refund => refund.parent.id)
  },

  nonRefundPayments(state: any, payments: any) {
    const nonRefunds = payments.filter((payment: { parent: any; }) => !payment.parent)
    state.order.nonRefundPayments = nonRefunds;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
