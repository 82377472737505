import { User } from './user';
import { LoggedInUser } from './loggedInUser';
import { ItemAddOn } from './itemAddon';
import { ItemTemplate } from './itemTemplate';
import { Season } from './season';
import { AddOnMembership } from './addOnMembership';
import { Package } from './package';
import { Section } from './section';
import { Dispute } from './dispute';
import { EntitlementEvent } from './entitlementEvent';
import { EntitlementInstance } from './entitlementInstance';
import { EntitlementLimit } from './entitlementLimit';
import { EntitlementRight } from './entitlementRight';
import { EntitlementTemplate } from './entitlementTemplate';
import { ItemInstance } from './itemInstance';
import { Order } from './order';
import { OrderItem } from './orderItem';
import { OrderCollection } from './orderCollection';
import { OrderMeta } from './orderMeta';
import { Payment } from './payment';
import { Presentation } from './presentation';
import { Role } from './role';
import { Transaction } from './transaction';
import { TransactionReport } from './transactionReport';

type IParseModels = {
  User: typeof User;
  LoggedInUser: typeof LoggedInUser;
  ItemAddOn: typeof ItemAddOn;
  ItemTemplate: typeof ItemTemplate;
  Season: typeof Season;
  AddOnMembership: typeof AddOnMembership;
  Package: typeof Package;
  Section: typeof Section;
  Dispute: typeof Dispute;
  EntitlementEvent: typeof EntitlementEvent;
  EntitlementInstance: typeof EntitlementInstance;
  EntitlementLimit: typeof EntitlementLimit;
  EntitlementRight: typeof EntitlementRight;
  EntitlementTemplate: typeof EntitlementTemplate;
  ItemInstance: typeof ItemInstance;
  Order: typeof Order;
  OrderItem: typeof OrderItem;
  OrderCollection: typeof OrderCollection;
  OrderMeta: typeof OrderMeta;
  Payment: typeof Payment;
  Presentation: typeof Presentation;
  Role: typeof Role;
  Transaction: typeof Transaction;
  TransactionReport: typeof TransactionReport;
};

const ParseModels: IParseModels = {} as IParseModels;
ParseModels.User = User;
ParseModels.LoggedInUser = LoggedInUser;
ParseModels.ItemAddOn = ItemAddOn;
ParseModels.ItemTemplate = ItemTemplate;
ParseModels.Season = Season;
ParseModels.AddOnMembership = AddOnMembership;
ParseModels.Package = Package;
ParseModels.Section = Section;
ParseModels.Dispute = Dispute;
ParseModels.EntitlementEvent = EntitlementEvent;
ParseModels.EntitlementInstance = EntitlementInstance;
ParseModels.EntitlementLimit = EntitlementLimit;
ParseModels.EntitlementRight = EntitlementRight;
ParseModels.EntitlementTemplate = EntitlementTemplate;
ParseModels.ItemInstance = ItemInstance;
ParseModels.Order = Order;
ParseModels.OrderItem = OrderItem;
ParseModels.OrderCollection = OrderCollection;
ParseModels.OrderMeta = OrderMeta;
ParseModels.Payment = Payment;
ParseModels.Presentation = Presentation;
ParseModels.Role = Role;
ParseModels.Transaction = Transaction;
ParseModels.TransactionReport = TransactionReport;

export default ParseModels;
export type ParseModels = typeof ParseModels;

