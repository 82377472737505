import Vue from "vue";
import Vuex from "vuex";
import orderCollection from "./modules/Orders/collection";
import order from "./modules/Orders/order";
import timeline from "./modules/timeline";
import settings from "./modules/settings";
import user from "./modules/user";
import event from "./modules/event";
import eventPackage from "./modules/eventPackage";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        orderCollection,
        order,
        timeline,
        settings,
        user,
        event,
        eventPackage,
    },
});

export default store;
