/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getParsePointer } from "../lib/common";
import { IUploadImage } from "../lib/image";
import Parse from "parse";

interface CheckInventoryAddon {
    package_id: string;
    itemTemplate: {
        id: string;
        name: string;
        hashVersion: number;
        eventId: string;
    };
    options: Array<CheckInventoryOption>;
}

interface CheckInventoryOption {
    option_id: string;
    name: string;
    value: string;
}

interface EditOrderItemUpdates {
    // new addons added to order item
    id: string; // addon id
    parent: string; //  orderItem id,
    options: Array<CheckInventoryOption>;
}

interface EditOrderItemChanges {
    // changes to which addon/ item template options are selected for the order item
    itemInstance: string; // itemInstance id
    option: string; // option id
    newVal: string;
}

interface EditOrderItem {
    orderItem: string;
    changes: Array<EditOrderItemChanges>;
    orderItemUpdates: Array<EditOrderItemUpdates>;
}

interface Season {
    name: string;
    desc: string;
    eventDate: string;
    salesDate: string;
}

interface UserId {
    userId?: string;
}
export default class DataService {
    private static _instance: DataService = new DataService();

    public static getInstance(): DataService {
        return DataService._instance;
    }

    constructor() {
        if (DataService._instance) {
            throw new Error("Use getInstance() instead of new.");
        }
        DataService._instance = this;
    }

    //********************************
    // Band's common endpoints
    //*******************************/

    public async isMaster() {
        const isMaster = await Parse.Cloud.run("isMaster");
        return isMaster;
    }

    public async isUsingConnect() {
        // return Parse.Cloud.run("isUsingConnectedAccount");
        return true;
    }

    public async getUnpaidBills() {
        return Parse.Cloud.run("getUnpaidBills");
    }

    public async payInvoice(invoiceId: string, amount: number) {
        return Parse.Cloud.run("payOutstandingXeroInvoice", {
            invoiceId,
            amount,
        });
    }

    public async payOutstandingInvoice(invoiceId: string) {
        return Parse.Cloud.run("payOutstandingInvoice", { invoiceId });
    }

    public async reverseDispute(paymentId: string) {
        console.log(`[debug] reverseDispute(paymentId: '${paymentId}'`);
        const result = await Parse.Cloud.run("reverseDispute", {
            payment_id: paymentId,
        });
        console.log("[debug] reverseDispute():", result);
        return result;
    }

    public async exportPackageLinks(eventId: string) {
        return await Parse.Cloud.run("exportProductLinks", {
            seasonId: eventId,
        });
    }

    public async exportSectionLinks(eventId: string) {
        return await Parse.Cloud.run("exportSectionLinks", {
            seasonId: eventId,
        });
    }

    public async getPayment(paymentId: string) {
        const result = await Parse.Cloud.run("getPayment", { paymentId });
        return result;
    }

    public async getPaymentCharge(paymentId: string) {
        const result = await Parse.Cloud.run("getPaymentCharge", { paymentId });
        return result;
    }

    public async getPaymentAccount(paymentId: string) {
        const result = await Parse.Cloud.run("getPaymentAccount", {
            paymentId,
        });
        return result;
    }

    public async getPaymentChildren(paymentId: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const query = new Parse.Query(Portal.Models.Payment);
        query.equalTo("parent", getParsePointer("Payment", paymentId));
        return query.find();
    }

    public async markPaymentDisputed(paymentId: string) {
        console.log(`[debug] markPaymentDisputed(paymentId: '${paymentId}'`);
        const result = await Parse.Cloud.run("markPaymentDisputed", {
            payment_id: paymentId,
        });
        console.log("[debug] markPaymentDisputed():", result);
        return result;
    }

    public disputeObjectModel(portalDispute: any) {
        const Dispute = Parse.Object.extend(portalDispute);
        const query = new Parse.Query(Dispute);
        return query;
    }

    public eventObjectModel(eventModel: any, sectionFilter: string[]) {
        const Event = Parse.Object.extend(eventModel);
        const query = new Parse.Query(Event);
        query.notEqualTo("deleted", true);

        if (sectionFilter && sectionFilter.length) {
            const SectionObj = Parse.Object.extend("Section");
            const querySection = new Parse.Query(SectionObj);
            querySection.containedIn("objectId", sectionFilter);
            querySection.exists("presentation");

            const PresentationObj = Parse.Object.extend("Presentation");
            const queryPres = new Parse.Query(PresentationObj);
            queryPres.matchesKeyInQuery(
                "objectId",
                "presentation.objectId",
                querySection
            );

            query.matchesKeyInQuery("objectId", "season.objectId", queryPres);
        }

        return query;
    }

    public orderItemObjectModel(portalOrderItem: any) {
        const Payment = Parse.Object.extend(portalOrderItem);
        const query = new Parse.Query(Payment);
        return query;
    }

    public paymentObjectModel(portalPayment: any) {
        const Payment = Parse.Object.extend(portalPayment);
        const query = new Parse.Query(Payment);
        return query;
    }

    public async getMasterTabData() {
        console.log(`[debug] getMasterTabData`);
        const result = await Parse.Cloud.run("getMasterTabData");
        return result;
    }

    public async createFinDraft() {
        console.log(`[debug] createFinDraft`);
        const result = await Parse.Cloud.run("doInvoice", { isDraft: true });
        return result;
    }

    public async RebuildInventory() {
        console.log(`[debug] RebuildInventory`);
        const result = await Parse.Cloud.run("fastInventorySync");
        return result;
    }

    public async PerformMasosImport(
        bandId: string,
        authKey: string,
        options: any
    ) {
        console.log(`[debug] PerformMasosImport`);
        const result = await Parse.Cloud.run("masosImport", {
            bandId,
            authKey,
            options,
        });
        return result;
    }

    public async RefreshAccountStatus() {
        console.log(`[debug] UpdatePackageQtys`);
        const result = await Parse.Cloud.run("refreshAccountStatus");
        return result;
    }

    public async disconnectConnectedAccount() {
        return await Parse.Cloud.run("disconnectConnectedAccount");
    }

    public async disconnectUserConnectedAccount(params: any) {
        return await Parse.Cloud.run("disconnectUserConnectedAccount", params);
    }

    public async UpdatePackageQtys() {
        console.log(`[debug] UpdatePackageQtys`);
        const result = await Parse.Cloud.run("updatePackageQuantities");
        return result;
    }

    public async tadRebuildOrderDescriptions() {
        console.log(`[debug] RebuildOrderDescriptions`);
        const result = await Parse.Cloud.run("rebuildOrderItemCache");
        await Parse.Cloud.run("rebuildOrderCache");
        return result;
    }

    public async updateSetting(
        key: string,
        newValue: number | string | boolean
    ) {
        console.log(`[debug] updateSetting ${key} ${newValue}`);
        const result = await Parse.Cloud.run("masterUpdateSetting", {
            key,
            val: newValue,
        });
        return result;
    }

    public async setAppSettings(settings: any) {
        const result = await Parse.Cloud.run("setAppSettings", settings);
        return result;
    }

    public async getAppSettings() {
        const result = await Parse.Cloud.run("getAppSettings");
        return result;
    }

    public async getItemTemplates() {
        console.log(`[debug] getItemTemplates`);
        const result = await Parse.Cloud.run("getItemTemplates");
        console.log(`[debug] getItemTemplates ${result.length}`);
        console.log(result);
        return result;
    }

    public async getItemTemplate(id: string) {
        const result = await Parse.Cloud.run("getItemTemplate", { id });
        return result;
    }

    public async getTemplateOptions(templateId: string) {
        console.log(`[debug] getItemTemplateWithOptions ${templateId}`);
        const result = await Parse.Cloud.run("getItemTempalteOptions", {
            templateId,
        });
        console.log(`[debug] getItemTemplateWithOptions ${result}`);
        return result;
    }

    public async addPackageAssociation(templateId: string, packageId: string) {
        console.log(`[debug] getItemTemplateWithOptions ${templateId}`);
        const result = await Parse.Cloud.run("addPackageAssociation", {
            templateId,
            packageId,
        });
        console.log(`[debug] getItemTemplateWithOptions ${result}`);
        return result;
    }

    public async copyItemTemplate(templateId: string) {
        console.log(`[debug] copyItemTemplate ${templateId}`);
        const result = await Parse.Cloud.run("copyItemTemplate", {
            templateId,
        });
        console.log(`[debug] copyItemTemplate ${result}`);
        return result;
    }

    public async getRecommendedPackages() {
        console.log(`[debug] getRecommendedPackages`);
        const result = await Parse.Cloud.run("getRecommendedPackages");
        console.log(`[debug] getRecommendedPackages ${result}`);
        return result;
    }

    public async getContactQueries() {
        console.log(`[debug] getContactQueries`);
        const result = await Parse.Cloud.run("getContactQueries");
        return result;
    }

    public async getUserSegment(segmentId: string) {
        const result = await Parse.Cloud.run("getUserSegment", { segmentId });
        return result;
    }
    public async saveSegment(params: object) {
        const result = await Parse.Cloud.run(
            "saveUserSegmentComposition",
            params
        );
        return result;
    }
    public async updateUserSegment(segmentId: string, params: object) {
        const result = await Parse.Cloud.run("updateUserSegment", {
            segmentId,
            params,
        });
        return result;
    }

    public async getContactQueryCount(
        type: string,
        queryName: string,
        requirements: object
    ) {
        console.log(`[debug] getContactQueryCount ${type} ${queryName}`);
        const result = await Parse.Cloud.run("getContactQueryCount", {
            queryName,
            type,
            requirements,
        });
        console.log(`[debug] getContactQueryCount ${result}`);
        return result;
    }

    public async getSegmentCompositionQueryCount(segmentComposition: any) {
        const result = await Parse.Cloud.run(
            "getSegmentCompositionQueryCount",
            { segmentComposition }
        );
        return JSON.parse(JSON.stringify(result));
    }

    public async deleteUserSegmentComposition(
        fieldName: string,
        campaignId?: string,
        packageId?: string
    ) {
        const result = await Parse.Cloud.run("deleteUserSegmentComposition", {
            fieldName,
            campaignId,
            packageId,
        });
        return result;
    }

    public async createCampaign(name: string) {
        const result = await Parse.Cloud.run("createMessageCampaign", { name });
        return result;
    }

    public async copyMessageCampaign(campaignId: string, name: string) {
        const result = await Parse.Cloud.run("copyMessageCampaign", {
            id: campaignId,
            name,
        });
        return result;
    }

    public async removeMessageSendSchedule(campaignId: string) {
        const result = await Parse.Cloud.run("removeMessageSendSchedule", {
            campaignId,
        });
        return result;
    }

    public async createMessageBlock(
        name: string,
        campaignId: string,
        definition: string
    ) {
        const result = await Parse.Cloud.run("createMessageBlock", {
            name,
            campaignId,
            definition,
        });
        return result;
    }

    public async updateMessageBlock(hashNum: string, name: string) {
        const result = await Parse.Cloud.run("updateMessageBlock", {
            hashNum,
            name,
        });
        return result;
    }

    public async deleteMessageBlock(hashNum: string) {
        const result = await Parse.Cloud.run("deleteMessageBlock", { hashNum });
        return result;
    }

    public async getMessageBlocks() {
        const result = await Parse.Cloud.run("getMessageBlocks");
        return result;
    }

    public async updateMessageCampaign(campaignId: string, params: object) {
        const result = await Parse.Cloud.run("updateMessageCampaign", {
            campaignId,
            params,
        });
        return result;
    }
    public async selectMessageTemplate(templateId: string, campaignId: string) {
        const result = await Parse.Cloud.run("selectMessageTemplate", {
            templateId,
            campaignId,
        });
        return result;
    }
    public async getUserSegmentsForCampaign(campaignId: string) {
        const result = await Parse.Cloud.run("getUserSegmentsForCampaign", {
            campaignId,
        });
        return result;
    }
    public async listEmailTemplates(perPage?: number, currentPage?: number) {
        const result = await Parse.Cloud.run("listEmailTemplates", {
            perPage,
            currentPage,
        });
        return result;
    }
    public async getEmailEditorAuthKey() {
        const result = await Parse.Cloud.run("getEmailEditorAuthKey");
        return result;
    }
    public async getMessageCampaign(id: string) {
        const result = await Parse.Cloud.run("getMessageCampaign", { id });
        return result;
    }
    public async deleteMessageCampaign(id: string) {
        const result = await Parse.Cloud.run("deleteMessageCampaign", { id });
        return result;
    }
    public async restoreMessageCampaign(id: string) {
        const result = await Parse.Cloud.run("restoreMessageCampaign", { id });
        return result;
    }
    public async sendMessageCampaign(campaignId: string) {
        const result = await Parse.Cloud.run("sendMessageCampaign", {
            campaignId,
        });
        return result;
    }
    public async scheduleMessageCampaign(campaignId: string) {
        const result = await Parse.Cloud.run("scheduleCampaign", {
            campaignId,
        });
        return result;
    }
    public async cancelScheduledCampaign(campaignId: string) {
        const result = await Parse.Cloud.run("cancelScheduledCampaign", {
            campaignId,
        });
        return result;
    }
    public async sendTestMessageToUser(
        subject: string,
        content: string,
        email: string
    ) {
        const result = await Parse.Cloud.run("sendTestMessageToUser", {
            subject,
            content,
            email,
        });
        return result;
    }
    public async getMessageSaveContent(campaignId: string) {
        const result = await Parse.Cloud.run("getMessageSaveContent", {
            campaignId,
        });
        return result;
    }
    public async getEmailTemplate(templateId: string) {
        const result = await Parse.Cloud.run("getEmailTemplate", {
            templateId,
        });
        return result;
    }

    public async previewMessageTemplate(
        type: string,
        message: string,
        subject: string
    ) {
        console.log(
            `[debug] previewMessageTemplate ${type} ${message} ${subject}`
        );
        const result = await Parse.Cloud.run("previewMessageTemplate", {
            type,
            message,
            subject,
        });
        console.log(`[debug] previewMessageTemplate - results ${result}`);
        return result;
    }

    public async sendTemplatedMessage({
        type,
        message,
        subject,
        query,
        params,
        isTestEmail,
    }: {
        type: string;
        message: string;
        subject: string;
        query: any;
        params: Record<string, any>;
        isTestEmail?: boolean;
    }) {
        console.log(
            `[debug] sendTemplatedMessage ${type} ${message} ${query} ${params}`
        );
        const result = await Parse.Cloud.run("sendTemplatedMessage", {
            type,
            message,
            query,
            subject,
            isTestEmail,
            ...params,
        });
        console.log(`[debug] sendTemplatedMessage - results ${result}`);
        return result;
    }

    public async getContactPriceEstimate(
        type: string,
        queryName: string,
        requirements: object
    ) {
        console.log(`[debug] getContactPriceEstimate ${type} ${queryName}`);
        const result = await Parse.Cloud.run("getContactPriceEstimate", {
            type,
            queryName,
            requirements,
        });
        console.log(`[debug] getContactPriceEstimate - results ${result}`);
        return result;
    }

    public async getValidTemplateTokens() {
        console.log(`[debug] getValidTemplateTokens`);
        const result = await Parse.Cloud.run("getValidTemplateTokens");
        console.log(`[debug] getValidTemplateTokens - results ${result}`);
        return result;
    }

    public async getSubQueryResults(queryName: string, params: any) {
        const result = await Parse.Cloud.run("getSubQueryResults", {
            queryName,
            ...params,
        });
        return result;
    }

    public async getPackagesList(
        packageIds: string[]
    ): Promise<Parse.Object[]> {
        console.log(`[debug] getPackagesList ${packageIds}`);
        const Pack = Parse.Object.extend("Package");
        const query = new Parse.Query(Pack);
        query.containedIn("objectId", packageIds);
        query.descending("createdAt");
        query.notEqualTo("deleted", true);
        query.include("section.name");
        const result = await query.find();
        console.log(`[debug] getPackagesList - results ${result}`);
        return result;
    }

    public async getPackageById(packageId: string) {
        const query = this.getPackageQuery("Package");
        return query.get(packageId);
    }

    private getPackageQuery(model: any) {
        const Event = Parse.Object.extend(model);
        const query = new Parse.Query(Event);
        query.notEqualTo("deleted", true);
        query.include("section.name");

        return query;
    }

    public async getPackages(
        model: any,
        filter: string,
        sectionFilter: string[],
        page = 0,
        limit = 10
    ) {
        const countQuery = this.getPackageQuery(model);

        let searchText = filter
            .toLowerCase()
            .replace(/[*+?^${}()|[\]\\/]/g, "");
        searchText = String(searchText);
        const regExp = new RegExp(searchText);
        console.log(regExp);

        if (sectionFilter && sectionFilter.length) {
            const SectionObj = Parse.Object.extend("Section");
            const querySection = new Parse.Query(SectionObj);
            querySection.containedIn("objectId", sectionFilter);
            countQuery.matchesQuery("section", querySection);
        }

        if (searchText) {
            countQuery.matches("search", regExp, "i");
        }
        const count = await countQuery.count();

        const itemQuery = this.getPackageQuery(model);
        if (sectionFilter && sectionFilter.length) {
            const SectionObj = Parse.Object.extend("Section");
            const querySection = new Parse.Query(SectionObj);
            querySection.containedIn("objectId", sectionFilter);
            itemQuery.matchesQuery("section", querySection);
        }

        if (searchText) {
            itemQuery.matches("search", regExp, "i");
        }
        itemQuery.skip(page * limit);
        itemQuery.limit(limit);
        itemQuery.descending("createdAt");
        const items = await itemQuery.find();

        return { count, items };
    }

    public async removeRecommendation(
        packageId: string,
        recommendationId: string
    ) {
        console.log(`[debug] removeRecommendation ${packageId}`);
        const response = await Parse.Cloud.run("removeRecommendation", {
            packageId,
            recommendationId,
        });
        console.log(`[debug] removeRecommendation ${response}`);
        return response;
    }

    public async addRecommendation(
        packageId: string,
        recommendationId: string
    ) {
        console.log(`[debug] addRecommendation ${packageId}`);
        const response = await Parse.Cloud.run("addRecommendation", {
            packageId,
            recommendationId,
        });
        console.log(`[debug] addRecommendation ${response}`);
        return response;
    }

    public async getRecommendationsPackageMakes(packageId: string) {
        console.log(`[debug] getRecommendationsPackageMakes ${packageId}`);
        const response = await Parse.Cloud.run(
            "getRecommendationsPackageMakes",
            { forPackage: packageId }
        );
        console.log(`[debug] getRecommendationsPackageMakes ${response}`);
        return response;
    }

    public async getSection(sectionModal: any, sectionId: string) {
        const sectionQuery = new Parse.Query(sectionModal);
        sectionQuery.include("presentation.season");
        const response = await sectionQuery.get(sectionId);
        return response;
    }

    public async createSection(
        sectionDetails: {
            name: string;
            image: Parse.File;
            desc: string;
            isSelling: boolean;
            mealsDisabled: boolean;
            authType: string;
            inPreviewMode: boolean;
        },
        presentationId: string
    ): Promise<Parse.Object> {
        const Section = Parse.Object.extend("Section");
        const section = new Section();
        section.set("image", sectionDetails.image);
        section.set("name", sectionDetails.name);
        section.set("desc", sectionDetails.desc);
        section.set("mealsDisabled", sectionDetails.mealsDisabled);
        section.set("isSelling", sectionDetails.isSelling);
        section.set("authType", sectionDetails.authType);
        section.set('inPreviewMode', sectionDetails.inPreviewMode)
        section.set(
            "presentation",
            getParsePointer("Presentation", presentationId)
        );

        const newSection = await section.save();
        return newSection;
    }

    public async createPackageImage(
        image: Parse.File,
        order: number,
        packageId: string
    ): Promise<Parse.Object> {
        const PackageImage = Parse.Object.extend("PackageImage");
        const packageImage = new PackageImage();
        packageImage.set("package", getParsePointer("Package", packageId));
        packageImage.set("image", image);
        packageImage.set("order", order);
        return await packageImage.save();
    }

    public async createPackage(packageDetails: any): Promise<Parse.Object> {
        const packageObject = {
            ...packageDetails,
            cost: parseInt(packageDetails.cost),
            qtyAvailable: parseInt(packageDetails.qtyAvailable),
            minPaymentOverride: parseInt(packageDetails.minPaymentOverride),
            maxPackagesPerPerson: parseInt(packageDetails.maxPackagesPerPerson),
            maxApplicationPerCustomer: parseInt(
                packageDetails.maxApplicationPerCustomer
            ),
        };

        const uploadImages: IUploadImage[] = packageDetails.images;
        if (uploadImages && uploadImages.length) {
            const slateImage = uploadImages[0];
            packageObject.image = slateImage.parseFile;
        }
        delete packageObject.section;
        delete packageObject.images;
        delete packageObject.gender;
        delete packageObject.sectionId;

        const newPackage = await this.createPackageCloudFn(
            packageObject,
            packageDetails.sectionId
        );

        for (const index in uploadImages) {
            const uploadImage = uploadImages[index];
            await this.createPackageImage(
                uploadImage.parseFile,
                Number(index),
                newPackage.id
            );
        }

        return newPackage;
    }

    public async deleteEventPackageImages(packageId: string, imageIds: string[]) {
        const response = await Parse.Cloud.run('deleteEventPackageImages', { packageId, imageIds });
        return response;
    }

    public async createPackageCloudFn(
        packge: Record<string, any>,
        forSection: string
    ) {
        return await Parse.Cloud.run("createPackage", {
            package: packge,
            forSection,
        });
    }

    public async getRoleForSection(sectionId: string) {
        const response = await Parse.Cloud.run("getRoleForSection", {
            sectionId,
        });
        return response;
    }

    public async getPresentationForEvent(
        eventId: string
    ): Promise<Parse.Object[] | undefined> {
        const query = new Parse.Query("Presentation");
        query.equalTo("season", getParsePointer("Season", eventId));
        // query.notEqualTo('deleted', true)
        return await query.find();
    }

    public async getSectionsForPresentations(
        presentationIds: string[]
    ): Promise<Parse.Object[]> {
        const presentationPointers = presentationIds.map((presentationId) =>
            getParsePointer("Presentation", presentationId)
        );
        const query = new Parse.Query("Section");
        query.containedIn("presentation", presentationPointers);
        query.notEqualTo("deleted", true);
        return await query.find();
    }

    public async getPackagesForSection(
        sectionId: string
    ): Promise<Parse.Object[]> {
        const query = new Parse.Query("Package");
        query.equalTo("section", getParsePointer("Section", sectionId));
        query.notEqualTo("deleted", true);
        return await query.find();
    }

    public async getImagesForPackage(
        packageId: string
    ): Promise<Parse.Object[]> {
        const query = new Parse.Query("PackageImage");
        query.equalTo("package", getParsePointer("Package", packageId));
        return await query.find();
    }

    public async getPresentation(presentationId: string) {
        // @ts-ignore
        const query = new Parse.Query(Portal.Models.Presentation);
        query.include("season");
        const response = await query.get(presentationId);
        return response;
    }

    public async getCreditBalance() {
        console.log(`[debug] getCreditBalance `);
        const result = await Parse.Cloud.run("getCreditBalance");
        console.log(`[debug] getCreditBalance`);
        return result;
    }

    public async createSeason(event: Season) {
        const result = await Parse.Cloud.run("createEvent", { event });
        return result;
    }

    public async updateEvent(id: string, event: any) {
        const result = await Parse.Cloud.run("updateEvent", {
            id: id,
            event: event,
        });
        return result;
    }

    public async agreeToTOS() {
        const result = await Parse.Cloud.run("agreeToTOS");
        return result;
    }

    public async getActiveEvents() {
        return await Parse.Cloud.run("getActiveEvents", {
            fromAdminPanel: true,
        });
    }

    public async getSectionForEvent(eventId: string) {
        return await Parse.Cloud.run("getSectionForEvent", {
            eventId,
            fromAdminPanel: true,
        });
    }

    public async exportOrders(params: any) {
        return await Parse.Cloud.run("exportOrders", params);
    }
    public async deleteEvent(id: string) {
        return Parse.Cloud.run("deleteEvent", { rowIds: [id] });
    }

    public async softDeleteRows(rowIds: string[], objectName: string) {
        return Parse.Cloud.run("softDeleteRows", { rowIds, objectName });
    }

    public async permanentlyDeleteUser(userId: string) {
        return Parse.Cloud.run("permanentlyDeleteUser", { userId });
    }

    public async getEvent(id: string) {
        // @ts-ignore
        const { event } = await Parse.Cloud.run("getEventDetail", { id: id });
        return event;
    }

    public async getItemAddons(packageId: string) {
        //@ts-ignore
        const query = new Parse.Query(Portal.Models.ItemAddOn);
        query.equalTo("package", {
            __type: "Pointer",
            className: "Package",
            objectId: packageId,
        });
        query.include("itemTemplate");
        query.equalTo("type", "addon");
        query.notEqualTo("deleted", true);

        //@ts-ignore
        const innerQuery = new Parse.Query(Portal.Models.ItemTemplate);
        innerQuery.notEqualTo("deleted", true);
        innerQuery.equalTo("type", "addon");
        query.matchesQuery("itemTemplate", innerQuery);

        return await query.find();
    }

    public async getPackageMemberships(packageId: string) {
        const PackageMembership = Parse.Object.extend("PackageMembership");
        const query = new Parse.Query(PackageMembership);
        query.equalTo("package", {
            __type: "Pointer",
            className: "Package",
            objectId: packageId,
        });
        query.notEqualTo("deleted", true);
        query.include("itemTemplate");
        query.notEqualTo("type", "addon");

        //@ts-ignore
        const innerQuery = new Parse.Query(Portal.Models.ItemTemplate);
        innerQuery.notEqualTo("deleted", true);
        innerQuery.notEqualTo("type", "addon");
        query.matchesQuery("itemTemplate", innerQuery);

        return await query.find();
    }

    public async getAddonOptions(itemTemplate: Parse.Object) {
        const OptionMembership = Parse.Object.extend("OptionMembership");
        const query = new Parse.Query(OptionMembership);
        query.include("option");
        query.equalTo("itemTemplate", itemTemplate);

        const options = await query.find();
        return options;
    }

    public async checkInventory(checkInventory: Array<CheckInventoryAddon>) {
        const response = await Parse.Cloud.run("getInventoryItemQuantity", {
            itemOption: checkInventory,
        });
        return response;
    }

    public async checkInventoryCache(
        optionObject: Array<CheckInventoryOption>,
        itemTemplate: any,
        packageId: string
    ) {
        const options = Object.values(optionObject);

        const changedTemplate: CheckInventoryAddon = {
            itemTemplate: {
                id: itemTemplate.id,
                name: itemTemplate.get("name"),
                hashVersion: itemTemplate.get("hashVersion"),
                eventId: itemTemplate.get("event")?.id,
            },
            package_id: packageId,
            options: options,
        };

        //@ts-ignore
        const inventory = await this.checkInventory(changedTemplate);
        if (inventory.soldOutOverride) {
            return false;
        } else {
            return (
                inventory.limit === 0 ||
                (inventory.limit > 0 && inventory.availableQuantity > 0)
            );
        }
    }

    public async getInventoryItemQuantity(
        optionObject: Array<CheckInventoryOption>,
        itemTemplate: any,
        packageId: string
    ) {
        const options = Object.values(optionObject);

        const changedTemplate: CheckInventoryAddon = {
            itemTemplate: {
                id: itemTemplate.id,
                name: itemTemplate.get("name"),
                hashVersion: itemTemplate.get("hashVersion"),
                eventId: itemTemplate.get("event")?.id,
            },
            package_id: packageId,
            options: options,
        };

        const response = await Parse.Cloud.run("getInventoryItemQuantity", {
            itemOption: changedTemplate,
        });
        return response;
    }

    public async removeAddonFromOrderItem(
        orderItemId: string,
        addOnId: string
    ) {
        const response = await Parse.Cloud.run("removeAddonFromOrderItem", {
            orderItemId,
            addOnId,
        });
        return response;
    }

    public async editOrderItem(params: EditOrderItem) {
        const response = await Parse.Cloud.run("editOrderItem", params);
        return response;
    }

    public async getInventoryItems({
        itemTemplateId,
        packageId,
    }: {
        itemTemplateId: string;
        packageId: string;
    }) {
        const response = await Parse.Cloud.run("getInventoryItems", {
            packageId,
            itemTemplateId,
        });
        return response;
    }

    public async getItemTempalteOptions(templateId: string) {
        const response = await Parse.Cloud.run("getItemTempalteOptions", {
            templateId,
        });
        return response;
    }

    public async getGlobalItemTemplates(eventId: string) {
        const response = await Parse.Cloud.run("getGlobalItemTemplates", {
            eventId,
        });
        return response;
    }

    public async getGlobalAddOns(eventId: string) {
        const response = await Parse.Cloud.run("getGlobalAddOns", { eventId });
        return response;
    }

    public async getAssociatedPackageListForGlobalItemTemplate(
        templateId: string
    ) {
        const response = await Parse.Cloud.run(
            "getAssociatedPackageListForGlobalItemTemplate",
            { id: templateId }
        );
        return response;
    }

    public async updateBulkInventoryItems(items: string) {
        const response = await Parse.Cloud.run("updateBulkInventoryItems", {
            items,
        });
        return response;
    }

    public async createUser(userInfo: any, ignoreEmailVerification?: boolean) {
        return await Parse.Cloud.run("createUser", {
            userInfo: userInfo,
            ignoreEmailVerification,
        });
    }

    public async updateUser(userInfo: any) {
        return await Parse.Cloud.run("updateUser", { userInfo: userInfo });
    }

    public async unbanUser(userId: string, reason: string) {
        return await Parse.Cloud.run("unbanUser", { user_id: userId, reason });
    }

    public async banUser(userId: string, reason: string) {
        return await Parse.Cloud.run("banUser", { user_id: userId, reason });
    }

    public async contactUser(email: string, message: string, subject: string) {
        return await Parse.Cloud.run("contactUser", {
            email,
            message,
            subject,
        });
    }

    public async removeFromRole(userId: string, role: string) {
        return await Parse.Cloud.run("removeFromRole", { user_id: userId, role });
    }

    public async manuallyVerifyUser(userId: string) {
        return await Parse.Cloud.run("manuallyVerifyUser", {
            targetId: userId,
        });
    }

    public async getBanHistory(userId: string) {
        return await Parse.Cloud.run("getBanHistory", {
            filters: { user_id: userId },
        });
    }

    public async getOrdersForResale(page: number, perPage: number) {
        return await Parse.Cloud.run("getOrdersForResale", {
            page,
            size: perPage,
        });
    }

    public async getResaleStats() {
        return await Parse.Cloud.run("getResaleStats");
    }

    public async getMessages(
        page: number,
        limit: number,
        byUser?: string,
        forUser?: string
    ) {
        return await Parse.Cloud.run("getMessages", {
            page,
            limit,
            byUser,
            forUser,
        });
    }

    public async userHasSentMessages(userId: string) {
        return await Parse.Cloud.run("userHasSentMessages", { userId });
    }

    public async getEmailLogs(email: string) {
        return await Parse.Cloud.run("findEmailsByEmail", { email });
    }

    public async getSectionPackages(model: any, sectionId: string) {
        const sectionParseObject = {
            __type: "Pointer",
            className: "Section",
            objectId: sectionId,
        };

        const query = new Parse.Query(model);
        query.equalTo("section", sectionParseObject);
        query.notEqualTo("deleted", true);
        return await query.find();
    }

    public async getUserEntitlements(userId: string) {
        const EntitlementInstance = Parse.Object.extend("EntitlementInstance");
        const query = new Parse.Query(EntitlementInstance);
        query.include("template");
        query.equalTo("applicant", {
            __type: "Pointer",
            className: "_User",
            objectId: userId,
        });
        query.notEqualTo("deleted", true);

        return await query.find();
    }

    public async createOrder(cart: object, forUserId: string) {
        return await Parse.Cloud.run("createOrder", { cart, forUserId });
    }

    public async addOrderItemToOrder(cart: object, orderId: string) {
        return await Parse.Cloud.run("addOrderItemToOrder", { cart, orderId });
    }

    public async getOrdersForUserId(userId: string) {
        return await Parse.Cloud.run("getOrdersForUserByUserId", { userId });
    }

    public async getPaymentRefundDetails(paymentId: string) {
        return await Parse.Cloud.run("getPaymentRefundDetails", { paymentId });
    }

    public async refundPayment(
        paymentId: string,
        amount: number,
        reason: string
    ) {
        return await Parse.Cloud.run("refundPayment", {
            paymentId,
            amount,
            reason,
        });
    }

    public async reversePayment(paymentId: string) {
        return await Parse.Cloud.run("reversePayment", { paymentId });
    }

    public async revertCollection(id: string) {
        return await Parse.Cloud.run("revertCollection", { id });
    }

    public async MasterTransferOrder(orderId: string, toUserId: string, paymentIdsToDetach: string[]) {
        return await Parse.Cloud.run("resaleOrder", { orderId, toUserId, paymentIdsToDetach });
    }

    public async transferAddOn(
        itemInstanceId: string,
        destinationOrderItemId: string
    ) {
        return await Parse.Cloud.run("transferAddOn", {
            itemInstanceId,
            destinationOrderItemId,
        });
    }

    public async getPaymentsForOrderId(orderId: string) {
        return await Parse.Cloud.run("getPaymentsForOrderId", { orderId });
    }

    public async getOrderItemsCardDataForOrder(orderId: string) {
        return await Parse.Cloud.run("getOrderItemsCardDataForOrder", {
            id: orderId,
        });
    }

    public async attachPaymentToOrder(paymentId: string, orderId: string) {
        return await Parse.Cloud.run("attachPaymentToOrder", {
            paymentId,
            orderId,
        });
    }

    public async detachPaymentFromOrder(paymentId: string) {
        return await Parse.Cloud.run("detachPaymentFromOrder", { paymentId });
    }

    public async movePaymentFromOrder(
        paymentId: string,
        fromOrderId: string,
        toOrderId: string
    ) {
        return await Parse.Cloud.run("movePayment", {
            payment: paymentId,
            fromOrder: fromOrderId,
            toOrder: toOrderId,
        });
    }

    public async fetchAllDetachedPayments(userId?: string) {
        const params: UserId = {};
        if (userId) {
            params.userId = userId;
        }
        return await Parse.Cloud.run("fetchAllDetachedPayments", params);
    }

    public async markForResaleAdmin(orderId: string) {
        return await Parse.Cloud.run("markForResaleAdmin", { orderId });
    }

    public async removeFromResaleAdmin(orderId: string) {
        return await Parse.Cloud.run("removeFromResaleAdmin", { orderId });
    }

    public async getPricingData(priceId: string, sectionId: string) {
        return Parse.Cloud.run("getPricingData", { priceId, sectionId });
    }

    public async getSectionRolesForUser() {
        return Parse.Cloud.run("getSectionRolesForUser");
    }

    public async getTicketedEvent(eventId: string) {
        return Parse.Cloud.run("getTicketedEvent", { eventId });
    }

    public async getLivestream(livestreamId: string) {
        return Parse.Cloud.run("getLivestream", { livestreamId });
    }

    public async completeLivestream(livestreamId: string) {
        return Parse.Cloud.run("completeLivestream", { livestreamId });
    }

    public async getTicketsForEvent(eventId: string) {
        return Parse.Cloud.run("getTicketsForEvent", { eventId });
    }

    public async getAccessTemplateForEvent(eventId: string) {
        return Parse.Cloud.run("getAccessTemplateForEvent", { eventId });
    }

    public async createTicket(ticketData: string, eventId: string) {
        return Parse.Cloud.run("createTicket", { ticketData, eventId });
    }

    public async updateTicket(ticketData: string) {
        return Parse.Cloud.run("updateTicket", { ticketData });
    }

    public async deleteTicket(ticketId: string) {
        return Parse.Cloud.run("deleteTicket", { ticketId });
    }

    public async exportTickets(eventId: string) {
        return Parse.Cloud.run("exportEventTickets", { eventId });
    }

    public async updateMerchant(merchantData: any, merchantId: string) {
        return Parse.Cloud.run("updateMerchant", {
            ...merchantData,
            merchantId,
        });
    }

    public async getAllTagsByEntityClass(entityClass: string) {
        return Parse.Cloud.run("getAllTagsByEntityClass", { entityClass });
    }

    public async addTagsInRepo({
        tags,
        entityClass,
    }: {
        tags: any;
        entityClass: string;
    }) {
        return Parse.Cloud.run("addTagsInRepo", { tags, entityClass });
    }

    public async updateEntityRelatedTagsByEntityId({
        tags,
        entityId,
        entityClass,
    }: {
        tags: any;
        entityId: string;
        entityClass: string;
    }) {
        return Parse.Cloud.run("updateEntityRelatedTagsByEntityId", {
            tags,
            entityId,
            entityClass,
        });
    }

    public async manualInvoice(
        amount: number,
        description: string,
        forGlobal: boolean,
        merchantId: string | null
    ) {
        return Parse.Cloud.run("manualAddInvoice", {
            amount,
            description,
            forGlobal,
            merchantId,
        });
    }

    public async addUserAsAffiliateForEvent(
        userId: string,
        eventId: string,
        sectionId: string
    ) {
        return Parse.Cloud.run("addUserAsAffiliateForEvent", {
            userId,
            eventId,
            sectionId,
        });
    }

    public async getAffiliatesForEvent(eventId: string, agGridConfig: any) {
        return Parse.Cloud.run("getAffiliatesForEvent", {
            eventId,
            agGridConfig,
        });
    }

    public async removeAffiliateFromEvent(
        affiliateId: string,
        eventId: string,
        sectionId: string
    ) {
        return Parse.Cloud.run("removeAffiliateFromEvent", {
            affiliateId,
            eventId,
            sectionId,
        });
    }

    public async getAffiliatesWithPerformance(eventId: string) {
        return Parse.Cloud.run("getAffiliatesWithPerformance", {
            eventId,
            agGridParams: {
                startRow: 0,
                endRow: 5000,
            },
        });
    }

    public async testPrintfulConnection(printfulApiKey: string) {
        return Parse.Cloud.run("testPrintfulConnection", { printfulApiKey });
    }

    public async getPrintfulStore() {
        return Parse.Cloud.run("getPrintfulStore");
    }

    public async getPrintfulProducts() {
        return Parse.Cloud.run("getPrintfulProducts");
    }

    public async importPrintfulProducts() {
        return Parse.Cloud.run("importPrintfulProducts");
    }

    public async getActiveSections() {
        return Parse.Cloud.run("getActiveSections", { fromAdminPanel: true });
    }

    public async exportReceipts(
        orderFilter: string,
        forEvent: string,
        forSection: string
    ) {
        return Parse.Cloud.run("exportReceipts", {
            orderFilter,
            forEvent,
            forSection,
        });
    }

    public async exportProductionInfo({
        eventId,
        sectionId,
        packageId,
        includeCustomerInfo,
        collectionStatus,
    }: {
        eventId: string;
        sectionId: string;
        packageId: string;
        includeCustomerInfo: boolean;
        collectionStatus: string;
    }) {
        return Parse.Cloud.run("exportProductionInfo", {
            eventId,
            sectionId,
            packageId,
            includeCustomerInfo,
            collectionStatus,
        });
    }

    public async exportOrderItemsForGlobalItems({
        eventId,
        itemTemplateId,
        includeCustomerInfo,
    }: {
        eventId: string;
        itemTemplateId: string;
        includeCustomerInfo: boolean;
    }) {
        return Parse.Cloud.run("exportOrderItemsForGlobalItems", {
            eventId,
            itemTemplateId,
            includeCustomerInfo,
            includePackageAndSectionColumn: true,
        });
    }

    public async exportPackingSlips(
        forEvent: string,
        forSection: string,
        addresses: boolean
    ) {
        return Parse.Cloud.run("exportPackingSlips", {
            forEvent,
            forSection,
            addresses,
        });
    }

    public async exportOrdersChecklist(forEvent: string, forSection: string) {
        return Parse.Cloud.run("exportOrdersChecklist", {
            forEvent,
            forSection,
        });
    }

    public async printOrderLabels(params: object) {
        return Parse.Cloud.run("printOrderLabels", params);
    }

    public async addUserAsSectionLeader(userId: string, sectionId: string) {
        return Parse.Cloud.run("addUserAsSectionLeader", { userId, sectionId });
    }

    public async removeUserAsSectionLeader(userId: string, sectionId: string) {
        return Parse.Cloud.run("removeUserAsSectionLeader", {
            userId,
            sectionId,
        });
    }

    public async resendReceipt(order_id: string) {
        return Parse.Cloud.run("resendReceipt", { order_id });
    }

    public async downloadOrderReceipt(id: string) {
        return Parse.Cloud.run("downloadOrderReceipt", { id });
    }

    public async downloadOrderTickets(orderId: string) {
        return Parse.Cloud.run("downloadOrderTickets", { orderId });
    }

    public async cancelNoPaymentOrder(orderId: string) {
        return Parse.Cloud.run("cancelNoPaymentOrder", { orderId });
    }

    public async makePayment(paymentInfo: object) {
        return Parse.Cloud.run("makePayment", { paymentInfo });
    }

    public async getOrder(order_id: string) {
        return Parse.Cloud.run("getOrder", { order_id });
    }

    public async getBasicOrderDetails(orderId: string) {
        return Parse.Cloud.run("getBasicOrderDetails", { id: orderId });
    }

    public async addOrderNote(orderId: string, note: string) {
        return Parse.Cloud.run("addOrderNote", {
            orderId,
            note,
            isSingleNote: true,
        });
    }

    public async refreshOrderCache(orderId: string) {
        return Parse.Cloud.run("refreshOrderCache", { orderId });
    }

    public async getOrdersForUserIdForSeason(
        userId: string,
        forSeason: boolean
    ) {
        return await Parse.Cloud.run("getOrdersForUserByUserId", {
            userId,
            forSeason,
        });
    }

    public async exportUsers() {
        return Parse.Cloud.run("exportUsers");
    }

    public async exportUserCountryReport(eventId: string, sectionId: string) {
        return Parse.Cloud.run("exportUserCountryReport", {
            eventId,
            sectionId,
        });
    }

    public async exportSeasonUserBalances(eventId: string, sectionId: string) {
        return Parse.Cloud.run("exportSeasonUserBalances", {
            eventId,
            sectionId,
        });
    }

    public async exportSupplierInfo(
        eventId: string,
        packageId: string,
        includeZeroQty: boolean
    ) {
        return Parse.Cloud.run("exportSupplierInfo", {
            eventId,
            packageId,
            includeZeroQty,
        });
    }

    public async exportInventory(
        forEvent: string,
        forSection: string,
        includeZeroQty: boolean,
        forPackage: string
    ) {
        return Parse.Cloud.run("exportInventory", {
            forEvent,
            forSection,
            forPackage,
            includeZeroQty,
        });
    }

    public async getEventSetting(eventId: string) {
        const response = await Parse.Cloud.run("getEventSetting", { eventId });
        return JSON.parse(JSON.stringify(response));
    }

    public async saveEventSetting(eventId: string, settingData: object) {
        return Parse.Cloud.run("saveEventSetting", { eventId, settingData });
    }

    public async getEventSettingForOrder(orderId: string) {
        const response = await Parse.Cloud.run("getEventSettingForOrder", {
            orderId,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async getDisputeForPayment(id: string) {
        const response = await Parse.Cloud.run("getDisputeForPayment", { id });
        return JSON.parse(JSON.stringify(response));
    }

    public async getCommitteeMembersForEvent(eventId: string) {
        const response = await Parse.Cloud.run("getCommitteeMembersForEvent", {
            eventId,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async addUserAsCommitteeMemberForEvent(
        eventId: string,
        userId: string
    ) {
        const response = await Parse.Cloud.run(
            "addUserAsCommitteeMemberForEvent",
            { eventId, userId }
        );
        return JSON.parse(JSON.stringify(response));
    }

    public async removeUserAsCommitteeMemberForEvent(
        userId: string,
        eventId: string
    ) {
        const response = await Parse.Cloud.run(
            "removeUserAsCommitteeMemberForEvent",
            { userId, eventId }
        );
        return JSON.parse(JSON.stringify(response));
    }

    public async getApplicationsForUser(userId: string) {
        const response = await Parse.Cloud.run("getApplicationsForUser", {
            userId,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async approveBulkApplications(
        applicationIds: any,
        approveCount: number
    ) {
        const response = await Parse.Cloud.run("approveBulkApplications", {
            applicationIds,
            approve_count: approveCount,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async rejectBulkApplications(
        applicationIds: any,
        informApplicant: boolean,
        declineReason: string
    ) {
        const response = await Parse.Cloud.run("rejectBulkApplications", {
            applicationIds,
            informApplicant,
            declineReason,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async rejectApplication(applicationId: string) {
        const response = await Parse.Cloud.run("rejectApplication", {
            application_id: applicationId,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async approveApplication(
        applicationId: string,
        approveCount: number
    ) {
        const response = await Parse.Cloud.run("approveApplication", {
            application_id: applicationId,
            approve_count: approveCount,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async assignBulkApplications(applicationIds: any, memberId: string) {
        const response = await Parse.Cloud.run("updateBulkApplicationMember", {
            applicationIds,
            memberId,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async getAllEntitlementTemplates() {
        const result = await Parse.Cloud.run("getAllEntitlementTemplates");
        return JSON.parse(JSON.stringify(result));
    }

    public async getCommitteeMembersForEntitlementTemplate(templateId: string) {
        const result = await Parse.Cloud.run(
            "getCommitteeMembersForEntitlementTemplate",
            { templateId }
        );
        return JSON.parse(JSON.stringify(result));
    }

    public async createEntitlement(
        member_id: string,
        template_id: string,
        userIds: string[],
        approved: number,
        requested: number,
        used: number
    ) {
        const result = await Parse.Cloud.run("createEntitlement", {
            member_id,
            template_id,
            userIds,
            approved,
            requested,
            used,
        });
        return JSON.parse(JSON.stringify(result));
    }

    public async updateEntitlement(
        id: string,
        member_id: string,
        template_id: string,
        applicant_id: string,
        approved: number,
        requested: number,
        used: number
    ) {
        const result = await Parse.Cloud.run("updateEntitlement", {
            id,
            member_id,
            template_id,
            applicant_id,
            approved,
            requested,
            used,
        });
        return JSON.parse(JSON.stringify(result));
    }

    public async getEntitlementLimitsForUser(userId: string) {
        const response = await Parse.Cloud.run("getEntitlementLimitsForUser", {
            userId,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async updateEntitlementLimit(
        templateId: string,
        userId: string,
        limit: number
    ) {
        const response = await Parse.Cloud.run("updateEntitlementLimit", {
            templateId,
            userId,
            limit,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async markAsDefaultCommitteeMemberForEvent(
        eventId: string,
        userId: string
    ) {
        const response = await Parse.Cloud.run(
            "markAsDefaultCommitteeMemberForEvent",
            { eventId, userId }
        );
        return JSON.parse(JSON.stringify(response));
    }

    public async getPackageMembershipV2(forPackage: string) {
        const response = await Parse.Cloud.run("getPackageMembershipV2", {
            forPackage,
        });
        return JSON.parse(JSON.stringify(response));
    }

    public async getCommonInventoryOptions() {
        const response = await Parse.Cloud.run("getCommonInventoryOptions");
        return response;
    }

    public async getItemTemplateWithOptions(
        itemTemplateId: string,
        isAddOn: boolean
    ) {
        const result = await Parse.Cloud.run("getItemTemplateWithOptions", {
            itemTemplateId,
            isAddOn,
        });
        return result;
    }

    public async createItemTemplate(
        itemTemplate: any,
        package_id: string,
        event_id: string
    ) {
        const result = await Parse.Cloud.run("createItemTemplate", {
            itemTemplate,
            package_id,
            event_id,
        });
        return result;
    }

    public async updateItemTemplateOptions(
        templateId: any,
        options: typeof Array
    ) {
        const result = await Parse.Cloud.run("updateItemTemplateOptions", {
            templateId,
            options,
        });
        return result;
    }

    public async updateSortOrderForItemOptions(
        itemTemplateId: any,
        options: typeof Array
    ) {
        const result = await Parse.Cloud.run("updateSortOrderForItemOptions", {
            itemTemplateId,
            options,
        });
        return result;
    }

    public async updateSortOrderForItemTemplates(
        itemTemplates: typeof Array,
        packageId: string
    ) {
        const result = await Parse.Cloud.run(
            "updateSortOrderForItemTemplates",
            { itemTemplates, packageId }
        );
        return result;
    }

    public async updateItemTemplate(itemTemplate: any) {
        const result = await Parse.Cloud.run("updateItemTemplate", {
            itemTemplate,
        });
        return result;
    }

    public async deleteItemTemplate(packageId: string, templateId: string) {
        const result = await Parse.Cloud.run("deleteItemTemplate", {
            templateId,
            packageId,
        });
        return result;
    }

    public async lookupUserByEmail(email: string) {
        const response = await Parse.Cloud.run("lookupUserByEmail", { email });
        return response;
    }

    public async updatePackage(payload: any) {
        const response = await Parse.Cloud.run("updatePackage", payload);
        return response;
    }

    public async getPackageDetails(id: string) {
        const result = await Parse.Cloud.run("getPackageDetails", { id });
        return result;
    }

    public async groupOrderItems(fromOrderId: string, toOrderId: string) {
        const response = await Parse.Cloud.run("groupOrderItems", {
            fromOrderId,
            toOrderId,
        });
        return response;
    }

    public async getOrderItemsForGroupOrder(orderId: string) {
        const response = await Parse.Cloud.run("getOrderItemsForGroupOrder", {
            orderId,
        });
        return response;
    }

    public async getPackageItemAddOns(forPackage: string) {
        const result = await Parse.Cloud.run("getItemAddOnsV2", { forPackage });
        return JSON.parse(JSON.stringify(result));
    }

    public async createItemAddOn(
        itemAddOn: any,
        package_id: string,
        event_id: string
    ) {
        const result = await Parse.Cloud.run("createItemAddOn", {
            itemAddOn,
            package_id,
            event_id,
        });
        return result;
    }

    public async updateItemAddOn(itemAddOn: any) {
        const result = await Parse.Cloud.run("updateItemAddOn", { itemAddOn });
        return result;
    }

    public async getPackageEntitlements(packageId: string) {
        const result = await Parse.Cloud.run("getPackageEntitlementsV2", {
            packageId,
        });
        return JSON.parse(JSON.stringify(result));
    }

    public async addEntitlementToPackage(
        packageId: string,
        entitlementTemplateId: string
    ) {
        const result = await Parse.Cloud.run("addEntitlementToPackage", {
            packageId,
            entitlementTemplateId,
        });
        return result;
    }

    public async removeEntitlementAssociation(
        rowIds: string[],
        objectName: string,
        forPackage: string
    ) {
        return Parse.Cloud.run("removeEntitlementAssociation", {
            rowIds,
            objectName,
            forPackage,
        });
    }

    public async isConnectedSetupComplete(params?: any) {
        const result = await Parse.Cloud.run(
            "isConnectedSetupComplete",
            params
        );
        return result;
    }

    public async hasCardConnected() {
        const result = await Parse.Cloud.run("hasCardConnected");
        return result;
    }

    public async getPaypalMerchantStatus() {
        const result = await Parse.Cloud.run("getPaypalMerchantStatus");
        return result;
    }

    public async getNotificationPrefrences() {
        const result = await Parse.Cloud.run("getNotificationPrefrences");
        return result;
    }

    public async isUserSectionLeader() {
        const result = await Parse.Cloud.run("isUserSectionLeader");
        return result;
    }

    public async getTimeLine(entityId: string, entityType: string) {
        const result = await Parse.Cloud.run("getTimeLine", {
            entityId,
            entityType,
        });
        return result;
    }

    public async addTimeLineNote(
        entityId: string,
        entityType: string,
        note: string
    ) {
        const result = await Parse.Cloud.run("addTimeLineNote", {
            entityId,
            entityType,
            note,
        });
        return result;
    }

    public async deleteTimeLineNote(noteId: string) {
        const result = await Parse.Cloud.run("deleteTimeLineNote", { noteId });
        return result;
    }

    public async getConnectAuthURLForSectionLeader() {
        const result = await Parse.Cloud.run(
            "getConnectAuthURLForSectionLeader"
        );
        return result;
    }

    public async getConnectAuthURLForAdmin() {
        const result = await Parse.Cloud.run("getConnectAuthURLForAdmin");
        return result;
    }

    public async grantPackageToUsers(
        cart: any,
        userList: any,
        isComplimentary: boolean
    ) {
        const result = await Parse.Cloud.run("grantPackageToUsers", {
            cart,
            userList,
            isComplimentary,
        });
        return result;
    }

    public async getMessageJobDetail(id: string) {
        const result = await Parse.Cloud.run("getMessageJobDetail", { id });
        return JSON.parse(JSON.stringify(result));
    }
    public async getMessageJobIdForCampaign(campaignId: string) {
        const result = await Parse.Cloud.run("getMessageJobIdForCampaign", {
            campaignId,
        });
        return JSON.parse(JSON.stringify(result));
    }

    public async getEventsForAdmin() {
        const result = await Parse.Cloud.run("getEventsForAdmin");
        return result;
    }

    public async getSectionsForEventForAdmin(eventId: string) {
        const result = await Parse.Cloud.run("getSectionsForEventForAdmin", {
            eventId,
        });
        return result;
    }

    public async getPackageListForEventForAdmin(
        eventId: string,
        sectionId?: string
    ) {
        const result = await Parse.Cloud.run("getPackageListForEventForAdmin", {
            eventId,
            sectionId,
            fromAdminPanel: true,
        });
        return result;
    }

    public async updateSection(id: string, section: any) {
        const result = await Parse.Cloud.run("updateSection", { id, section });
        return result;
    }

    public async createDiscount(discount: any) {
        const result = await Parse.Cloud.run("createDiscount", { discount });
        return result;
    }

    public async getDiscountDetails(id: string) {
        const result = await Parse.Cloud.run("getDiscountDetails", { id });
        return result;
    }

    public async updateDiscount(id: string, discount: any) {
        const result = await Parse.Cloud.run("updateDiscount", {
            id,
            discount,
        });
        return result;
    }
    public async deactivateDiscounts() {
        return await Parse.Cloud.run("deactivateDiscounts", {});
    }

    public async exportApplicants(entitlementId: string) {
        const result = await Parse.Cloud.run("exportApplicants", {
            entitlementId,
        });
        return result;
    }

    public async exportEntitlmentOrders(entitlementTemplateId: string) {
        const result = await Parse.Cloud.run("exportEntitlmentOrders", {
            entitlementTemplateId,
        });
        return result;
    }

    public async getPackageIssuedEntitlements(packageId: string) {
        const result = await Parse.Cloud.run("getPackageEntitlementsV2", {
            packageId,
        });
        return result;
    }

    public async checkInUser(eventId: string, userId: string) {
        const result = await Parse.Cloud.run("checkInUser", {
            eventId,
            userId,
        });
        return result;
    }

    public async getUserDataInQueues(eventId: string) {
        const result = await Parse.Cloud.run("getUserDataInQueues", {
            eventId,
        });
        return result;
    }

    public async moveUserBackToCheckInQueue(eventId: string, userId: string) {
        const result = await Parse.Cloud.run("moveUserBackToCheckInQueue", {
            eventId,
            userId,
        });
        return result;
    }

    public async callUserOnDeck(eventId: string, userId: string) {
        const result = await Parse.Cloud.run("callUserOnDeck", {
            eventId,
            userId,
        });
        return result;
    }

    public async getNextUserFromCheckInQueue(eventId: string) {
        const result = await Parse.Cloud.run("getNextUserFromCheckInQueue", {
            eventId,
        });
        return result;
    }

    public async getOrderDetailsForCollection(orderId: string) {
        const result = await Parse.Cloud.run("getOrderDetailsForCollection", {
            orderId,
        });
        return result;
    }

    public async markItemsForCollection(data: any) {
        const result = await Parse.Cloud.run("markItemsForCollection", {
            data,
        });
        return result;
    }

    public async finishOrderCollection(data: any) {
        const result = await Parse.Cloud.run("finishOrderCollection", {
            ...data,
        });
        return result;
    }

    public async getUserSummaryForCheckIn(eventId: string, userId: string) {
        const result = await Parse.Cloud.run("getUserSummaryForCheckIn", {
            eventId,
            userId,
        });
        return result;
    }

    public async getOnDeckUserSummaryForAgent(eventId: string) {
        const result = await Parse.Cloud.run("getOnDeckUserSummaryForAgent", {
            eventId,
        });
        return result;
    }

    public async clearAllQueues(eventId: string) {
        const result = await Parse.Cloud.run("clearAllQueues", { eventId });
        return result;
    }

    public async clearCheckInQueue(eventId: string) {
        const result = await Parse.Cloud.run("clearCheckInQueue", { eventId });
        return result;
    }

    public async clearOnDeckQueue(eventId: string) {
        const result = await Parse.Cloud.run("clearOnDeckQueue", { eventId });
        return result;
    }

    public async removeUserFromDistribution(eventId: string, userId: string) {
        const result = await Parse.Cloud.run("removeUserFromDistribution", {
            eventId,
            userId,
        });
        return result;
    }

    public async completeUserDistributionSession(
        eventId: string,
        userId: string
    ) {
        const result = await Parse.Cloud.run(
            "completeUserDistributionSession",
            { eventId, userId }
        );
        return result;
    }

    public async getOrderCollectionsForOrder(id: string) {
        const result = await Parse.Cloud.run("getOrderCollectionsForOrder", {
            id,
        });
        return result;
    }

    public async assignRunner(
        eventId: string,
        userId: string,
        runnerId: string
    ) {
        const result = await Parse.Cloud.run("assignRunner", {
            eventId,
            userId,
            runnerId,
        });
        return result;
    }

    public async releaseRunner(eventId: string, userId: string) {
        const result = await Parse.Cloud.run("releaseRunner", {
            eventId,
            userId,
        });
        return result;
    }

    public async getLiveDistributionSummaryData(eventId: string) {
        const result = await Parse.Cloud.run("getLiveDistributionSummaryData", {
            eventId,
        });
        return result;
    }

    public async getDistributionSummaryData(eventId: string) {
        const result = await Parse.Cloud.run("getDistributionSummaryData", {
            eventId,
        });
        return result;
    }

    public async getDistributionDataByDay(
        eventId: string,
        startDate: Date,
        endDate: Date
    ) {
        const result = await Parse.Cloud.run("getDistributionDataByDay", {
            eventId,
            startDate,
            endDate,
        });
        return result;
    }

    public async sendCollectionReadyReminderMessage(
        eventId: string,
        userId: string
    ) {
        const result = await Parse.Cloud.run(
            "sendCollectionReadyReminderMessage",
            { eventId, userId }
        );
        return result;
    }

    public async sendUserMovedOnDeckMessage(eventId: string, userId: string) {
        const result = await Parse.Cloud.run("sendUserMovedOnDeckMessage", {
            eventId,
            userId,
        });
        return result;
    }

    public async cancelUserMovedOnDeckMessage(eventId: string, userId: string) {
        const result = await Parse.Cloud.run("cancelUserMovedOnDeckMessage", {
            eventId,
            userId,
        });
        return result;
    }

    public async getDiscountAutosuggest(
        eventId: string,
        packageId: string,
        sectionId: string
    ) {
        const result = await Parse.Cloud.run("getDiscountAutosuggest", {
            eventId,
            packageId,
            sectionId,
        });
        return result;
    }

    public async getUserCustomFilters(entity: string) {
        const result = await Parse.Cloud.run("getUserCustomFilters", {
            entity,
        });
        return result;
    }

    public async updateUserCustomFilters(data: any) {
        const result = await Parse.Cloud.run("updateUserCustomFilters", data);
        return result;
    }

    public async updateCustomFilterOnUser(data: any) {
        const result = await Parse.Cloud.run("updateCustomFilterOnUser", data);
        return result;
    }

    public async removeUserCustomFilter(name: string, entity: string) {
        const result = await Parse.Cloud.run("removeUserCustomFilter", {
            name,
            entity,
        });
        return result;
    }

    public async storeCustomerPaymentToken(data: Record<string, any>) {
        const result = await Parse.Cloud.run("storeCustomerPaymentToken", data);
        return result;
    }

    public async getCartById(cartId: string) {
        const result = await Parse.Cloud.run("getCartById", { id: cartId });
        return result;
    }

    public async getUser(id: string) {
        const result = await Parse.Cloud.run("getUser", { user_id: id });
        return result;
    }

    public async resetPassword(userId: string) {
        const result = await Parse.Cloud.run("resetPassword", { userId });
        return result;
    }

    public async getAllPayments({ limit, from, to, timeZoneOffset }: { limit: number, from: number, to: number, timeZoneOffset: number }) {
        const results = await Parse.Cloud.run('getAllPaymentsForDashboard', { limit, from, to, timeZoneOffset });
        return results;
    }

    public async getEntitiesByClassNameForDashboard(className: any, limit: any, from: any, to: any, isCount = false) {
        const results = await Parse.Cloud.run('getEntitiesByClassNameForDashboard', { className, limit, from, to, isCount });
        return results;
    }

    public async getAllDisputes() {
        const results = await Parse.Cloud.run('getAllDisputes');
        return results;
    }
}
