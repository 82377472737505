export const Role = Parse.Object.extend("_Role", {
  initialize: function (parseRole: { name: any; users: any; }) {
    if (parseRole) {
      this.name = parseRole.name;
      this.users = parseRole.users;
    }
  },

  name: function () { return this.name; },
  safeName: function () {
    const matchStr = "__"
    const idx = this.name ? this.name.indexOf(matchStr) : -1;
    const name = this.name;
    if (idx != -1) {
      const lastIdx = this.name.indexOf(matchStr)
      name.substring(idx + matchStr.length, lastIdx)
    }
    return name;
  },
  users: function () { return this.users; },
  count: function () { return this.users ? this.users.length : 0; }
});