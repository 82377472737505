/* eslint-disable @typescript-eslint/no-unused-vars */
export const ItemTemplate = Parse.Object.extend("ItemTemplate", {

  url: '',

  name: function () { return this.get("name") ? this.get("name") : ''; },
  desc: function () { return this.get("desc") ? this.get("desc") : ''; },
  image: function () {
    if (this.get("thumbnail")) {
      return this.get("thumbnail").url();
    }
    return paidutils.getNoImageURL();
  },

  thumbSmall: function () {
    if (this.get("thumb_sm")) {
      return this.get("thumb_sm").url();
    }
    return paidutils.getNoImageURL();
  },

  options: function () {
    return this.itemOptions;
  },

  initialize: function () {
    this.itemOptions = [];
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});
