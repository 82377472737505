/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';

export const Package = Parse.Object.extend("Package", {

  url: '',

  initialize: function () {
  },

  name: function () { return this.get("name"); },
  cost: function () { return this.get("cost"); },
  currency: function () { return this.get("currency"); },
  formattedCost: function () { return paidutils.formatMoney(this.get("cost"), this.currency()); },
  desc: function () { return this.get("desc"); },
  sortOrder: function () { return this.get("sortOrder"); },
  gender: function () { return this.get("gender"); },
  genderName: function () { return paidutils.getPackageGenderName(this.get("gender")); },
  online: function () { return this.get("online"); },
  soldout: function () { return this.get("soldOutOverride"); },
  qtySold: function () { return this.get("qtySold") ? this.get("qtySold") : 0; },
  qtyAvailable: function () { return this.get("qtyAvailable"); },
  isSimple: function () { return this.get('isSimple') },
  qtyAvailableDesc: function () {
    let qty = "(unlimited)";
    const qtyAvailable = this.get("qtyAvailable");
    if (qtyAvailable > 0) {
      qty = qtyAvailable;
    }
    return qty;
  },
  formattedMinPaymentOverride: function () {
    const amount = this.minPaymentOverride();
    if (amount) {
      return paidutils.formatMoney(amount);
    } else {
      return amount;
    }
  },
  minPaymentOverride: function () {
    const minPaymentOverride = this.get("minPaymentOverride");
    if (minPaymentOverride && minPaymentOverride > 0) {
      return this.get("minPaymentOverride");
    } else {
      return 0;
    }
  },
  maxApplicationPerCustomer: function () { return this.get("maxApplicationPerCustomer"); },
  maxApplicationPerCustomerDesc: function () {
    let max = "(unlimited)";
    const maxApplicationPerCustomer = this.get("maxApplicationPerCustomer");
    if (maxApplicationPerCustomer > 0) {
      max = maxApplicationPerCustomer;
    }
    return max;
  },
  isQtyLimited: function () { return this.get("qtyAvailable") > 0; },
  percentageSold: function () {
    let pct = 0;
    const qtyAvailable = this.get("qtyAvailable");
    if (qtyAvailable > 0) {
      pct = Math.min(this.qtySold() / qtyAvailable * 100, 100);
    }
    return pct;
  },
  availableOnline: function () { return this.get("online") ? "Yes" : "No"; },
  markedAsSoldOut: function () { return this.get("soldOutOverride") ? "Yes" : "No"; },
  image: function () {
    if (this.get("thumbnail")) {
      return this.get("thumbnail").url();
    }
    return paidutils.getNoImageURL();
  },
  thumbnail_small: function () {
    if (this.get("thumbnail_small")) {
      return this.get("thumbnail_small").url();
    }
    return paidutils.getNoImageURL();
  },
  section: function () { return this.get("section"); },
  directLink: function () {
    const url = Parse.Config.current().get("webURL");
    return url + "/p/" + this.id;
  },
  getGenderSelectionInput: function () {
    const options: { value: string; name: any; selected?: boolean; }[] = [];
    const genders = paidutils.getPackageGenderOptions();
    const self = this;
    _.each(genders, function (gender, index) {
      if (self.gender() == index) {
        options.push({
          value: index as unknown as string,
          name: gender,
          selected: true
        });
      } else {
        options.push({
          value: index as unknown as string,
          name: gender
        });
      }
    });
    return options;
  },

  getFullName: function () {
    return this.section().name() + ' ' + this.name() + ' ' + this.genderName();
  },

  getPresentationName: function () {
    return this.section().get("presentation").get("name");
  },

  defaults: {
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});