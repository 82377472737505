<template>
  <nav class="main-header navbar navbar-expand bg-white navbar-light border-bottom">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          data-widget="pushmenu"
          href="#"
        >
          <i class="fa fa-solid fa-bars" />
        </a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a
          id="notification"
          class="nav-link"
          data-toggle="dropdown"
          href="#"
        >
          <ion-icon
            class="nav-icon"
            name="notifications"
          />
          <span class="d-none d-md-inline">What's New</span>
        </a>
      </li>

      <li
        class="nav-item"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Go to Store"
      >
        <a
          class="nav-link"
          target="_blank"
          :href="webURL"
        >
          <ion-icon name="cart" /> <span class="d-none d-md-inline">Store</span>
        </a>
      </li>

      <li class="nav-item dropdown">
        <a
          id="userDropdown"
          class="nav-link dropdown-toggle user_fullname"
          href="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="d-none d-md-inline mr-2">{{ user.fullName() }}</span>
          <img
            data-no-retina=""
            :src="user.image()"
            class="d-inline img-circle elevation-1 profile-image"
            alt="User Image"
          >
        </a>

        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="userDropdown"
        >
          <router-link
            to="/profile"
            class="dropdown-item"
          >
            Profile
          </router-link>
          <div class="dropdown-divider" />
          <a
            href="#"
            class="dropdown-item text-danger"
            @click="logout"
          >Logout</a>
        </div>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "Navbar",
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    webURL: {
      type: String,
      default: "",
    },
  },
  methods: {
    logout() {
      paidutils.analyticsTrack("Log Out");
      Parse.User.logOut();
      this.$router.push("/");
      location.reload();
    },
  },
};
</script>
