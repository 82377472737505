/* eslint-disable @typescript-eslint/no-unused-vars */
export const EntitlementRight = Parse.Object.extend("EntitlementRight", {

  initialize: function () {
  },

  name: function () { return this.get("name"); },
  desc: function () { return this.get("desc"); },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});
