/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';

export const Payment = Parse.Object.extend("Payment", {
  initialize: function () {
  },

  isDetached: function () { return !!this.get('user'); },
  user: function () { return this.get('user'); },
  amount: function () { return paidutils.formatMoney(this.get("amount"), this.get('currency')); },
  netAmount: function () { return this.get("netAmount") ? paidutils.formatMoney(this.get("netAmount")) : null; },
  taxes: function () { return this.get("taxes") ? paidutils.formatMoney(this.get("taxes")) : null; },
  bandOfflineFee: function () { return this.get("bandOfflineFee") ? paidutils.formatMoney(this.get("bandOfflineFee")) : null; },
  madeBy: function () { return this.get("madeBy"); },
  madeById: function () { return this.get("madeBy") ? this.get("madeBy").id : null; },
  madeByUser: function () { return this.get("madeBy") ? this.get("madeBy").get("firstName") + " " + this.get("madeBy").get("lastName") : null },
  type: function () { return paidutils.getPaymentMethod(this.get("type")); },
  note: function () { return this.get("note"); },
  order: function () { return this.get("order"); },
  orderId: function () { return this.get("order") ? this.get("order").id : null; },
  paymentDateTime: function () { return moment(this.createdAt).format("D MMM YYYY h:mmA"); },
  prettyDate: function () { return moment(this.createdAt).format("D MMM YYYY"); },
  prettyTime: function () { return moment(this.createdAt).format("h:mmA"); },
  fromNowDate: function () { return moment(this.createdAt).fromNow(); },
  parent: function () { return this.get("parent"); },
  status: function () { return this.get("status"); },
  chargeId: function () { return this.get("chargeId"); },
  stripePaymentId: function () { return this.get("stripePaymentId"); },
  settings: function () { return this.get("paymentSettings"); },
  currency: function () { return this.get("currency"); },
  fee: function () {
    return this.get('fee') ? paidutils.formatMoney(this.get('fee')) : null;
  },
  cartFees: function () { return this.get('cartFees') },
  isOnlinePayment: function () { return this.get("type") === 0; },
  isRefund: function () {
    return this.get("type") === 2 && this.get("amount") < 0 || this.get("type") === 5;
  },
  isCash: function () { return this.get("type") === 2; },
  isOfflinePayment: function () { return this.get("type") === 3; },
  isDiscount: function () { return this.get("type") === 4; },
  isPartialRefund: function () {
    const isRefund = this.isRefund();
    const amount = Math.abs(this.get("amount"));
    const parentAmount = this.get("parent").get("amount");
    return isRefund && amount < parentAmount;
  },
  isDisputed() {
    const status = this.status() || "";
    return status.indexOf('disputed') !== -1;
  },
  isAdminRefundable: function () {
    const status = this.status() || ""; //so we can search undefined
    return this.get("type") !== undefined
      && this.get("type") != 0
      && this.get("type") != 5
      && status.indexOf('refunded') === -1
      && status.indexOf('disputed') === -1;
  },
  getPaymentIcon: function () {
    const type = this.get("type");
    if (type == 0)
      return "fa-credit-card";
    else if (type == 1)
      return "fa-dollar";
    else if (type == 2)
      return "fa-money";
    else if (type == 3)
      return "fa-credit-card";
    else if (type == 4)
      return "fa-minus-square";
    return "fa-question";
  },

  defaults: {
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  },
  // for detached payments (i.e. payments which are not attached to an order)
  detachedUserName: function () { return this.get("user") ? this.get("user").get("firstName") + " " + this.get("user").get("lastName") : null },
  detachedUserId: function () { return this.get("user") ? this.get("user").id : null },
});