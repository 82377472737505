/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';

export const EntitlementTemplate = Parse.Object.extend("EntitlementTemplate", {

  initialize: function () {
  },

  name: function () { return this.get("name"); },
  limit: function () { return this.get("limit"); },
  publicApply: function () { return this.get("publicApply"); },
  memberIssued: function () { return this.get("memberIssued"); },
  enforceMemberLimits: function () { return this.get("enforceMemberLimits"); },
  consumedOn: function () { return this.get("consumedOn"); },
  rights: function () { return this.get("rights"); },
  bundle: function () { return this.get("bundle"); },
  grantByCode: function () { return this.get("grantByCode"); },
  grantCode: function () { return this.get("grantCode"); },
  granted: function () { return this.get("granted"); },
  qty: function () { return this.get("qty"); },
  deleted: function () { return this.get("deleted"); },
  remaining: function () {
    if (this.qty() === 0) {
      return "Unlimited";
    }
    return this.qty() - this.granted();
  },
  prettyDate: function () {
    const date = moment(this.get('createdAt'));
    return date.format("YYYY/DD/MM hh:mmA");
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});
