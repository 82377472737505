/* eslint-disable @typescript-eslint/no-unused-vars */
export const Section = Parse.Object.extend("Section", {
  initialize: function () {
  },

  name: function () { return this.get("name"); },
  desc: function () { return this.get("desc"); },
  sortOrder: function () { return this.get("sortOrder"); },
  presentation: function () { return this.get("presentation"); },
  usesRegistrationCodes: function () { return this.get("authType") == "CODES"; },
  mealsDisabled: function () { return this.get("mealsDisabled"); },

  defaults: {
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});
