import Parse from 'parse';
import { Target } from '../target';

declare const window: any;
window.Target = Target;

if (!window.Target.server) {
  console.warn("URL issue", "Place your APP ID as the subdomain");
} else {
  const serverURL = window.Target.server + window.Target.app_id;
  const appId = window.Target.app_id;
  const jsKey = window.Target.js_key;

  Parse.initialize(appId, jsKey);
  Parse.serverURL = serverURL;
  global.Parse = Parse;
}