import slugify from 'slugify'
import EventPackage from '../store/models/eventPackage';
import { IUploadImage } from './image';
import EventPackageImage from '../store/models/eventPackageImage';
import DataService from "../services/DataService";
import { IServerSideDatasource } from 'ag-grid-community';
import _ from 'lodash';

export function getEventUrl(webUrl: string, eventName: string): string {
  const slug = slugify(eventName, { lower: true, strict: true })
  const eventUri = slug ? slug : 'your-event-name'
  return `${webUrl}/events/${eventUri}`
}

export function getParsePointer(className: string, objectId: string) {
  return {
    __type: 'Pointer',
    className,
    objectId
  };
}

export function getPackagesForEventQuery(eventId: string, skip?: number, forSection?: string): Parse.Query<Parse.Object> {
  const SeasonObj = Parse.Object.extend('Season');
  const querySeason = new Parse.Query(SeasonObj);
  querySeason.equalTo('objectId', eventId);
  querySeason.notEqualTo('deleted', true);

  const PresentationObj = Parse.Object.extend('Presentation');
  const queryPresentation = new Parse.Query(PresentationObj);
  queryPresentation.matchesQuery('season', querySeason);
  queryPresentation.notEqualTo('deleted', true);

  const SectionObj = Parse.Object.extend('Section');
  const querySection = new Parse.Query(SectionObj);
  querySection.matchesQuery('presentation', queryPresentation);
  querySection.notEqualTo('deleted', true);
  if (forSection) {
    querySection.equalTo('objectId', forSection);
  }

  const PackageObj = Parse.Object.extend('Package');
  const queryPackage = new Parse.Query(PackageObj);
  queryPackage.matchesQuery('section', querySection);
  queryPackage.notEqualTo('deleted', true);

  if (skip) {
    queryPackage.skip(skip);
  }

  return queryPackage;
}

export async function updateEventPackage(eventPackage: EventPackage, packageDetails: any): Promise<EventPackage> {
  eventPackage.name = packageDetails.name;
  eventPackage.desc = packageDetails.desc;
  eventPackage.cost = Number(packageDetails.cost);
  eventPackage.online = packageDetails.online;
  eventPackage.soldOutOverride = packageDetails.soldOutOverride;
  eventPackage.qtyAvailable = Number(packageDetails.qtyAvailable);
  eventPackage.maxPackagesPerPerson = Number(packageDetails.maxPackagesPerPerson);
  eventPackage.minPaymentOverride = Number(packageDetails.minPaymentOverride);
  eventPackage.useApplication = packageDetails.useApplication;
  eventPackage.allowNewApplication = packageDetails.allowNewApplication;
  eventPackage.maxApplicationPerCustomer = Number(packageDetails.maxApplicationPerCustomer);
  eventPackage.maxDiscountCodeAllowed = Number(packageDetails.maxDiscountCodeAllowed);
  eventPackage.id = packageDetails.id;

  const uploadImages: IUploadImage[] = packageDetails.images || [];
  const ds = DataService.getInstance();

  const eventPackageImages: EventPackageImage[] = []
  for (const index in uploadImages) {
    const uploadImage = uploadImages[index]
    let eventPackageImage = (eventPackage.images || []).find(image => image.id === uploadImage.id)
    if (!eventPackageImage) {
      const packageImage = await ds.createPackageImage(uploadImage.parseFile, Number(index), eventPackage.id)
      eventPackageImage = new EventPackageImage(packageImage)
    } else {
      eventPackageImage.sortOrder = Number(index);
      const parsePackageImage = eventPackageImage.updateParsePackageImage();
      await parsePackageImage.save();
    }

    eventPackageImages.push(eventPackageImage)
  }

  // images to delete
  const imagesToDelete = [];
  let updatedImages: any = [];
  if (eventPackage.images && eventPackage.images.length) {
    for (const packageImage of eventPackage.images) {
      const foundPackageImage = eventPackageImages.find(image => image.id === packageImage.id)
      if (!foundPackageImage) {
        imagesToDelete.push(packageImage.id);
      }
    }
    try {
      if (imagesToDelete.length > 0) {
        updatedImages = await ds.deleteEventPackageImages(eventPackage.id, imagesToDelete);
      }
    } catch (error) {
      console.error('Error deleting images', error);
    }
  }

  eventPackage.images = updatedImages.length > 0 ? updatedImages : eventPackageImages;

  if (updatedImages && updatedImages.length > 0) {
    eventPackage.image = updatedImages[0].get('image');
  } else {
    eventPackage.image = eventPackageImages[0]?.image ?? null;
  }

  const packagePaylod = {
    name: eventPackage.name,
    desc: eventPackage.desc,
    cost: eventPackage.cost,
    online: eventPackage.online,
    image: eventPackage.image,
    soldOutOverride: eventPackage.soldOutOverride,
    qtyAvailable: eventPackage.qtyAvailable,
    maxPackagesPerPerson: eventPackage.maxPackagesPerPerson,
    minPaymentOverride: eventPackage.minPaymentOverride,
    useApplication: !!eventPackage.useApplication,
    allowNewApplication: eventPackage.allowNewApplication,
    maxApplicationPerCustomer: eventPackage.maxApplicationPerCustomer,
    sortOrder: eventPackage.sortOrder,
    sectionId: eventPackage.sectionId,
    maxDiscountCodeAllowed: eventPackage.maxDiscountCodeAllowed
  };

  const payload = {
    id: eventPackage.id,
    package: packagePaylod,
  }

  const updatedPackage = await ds.updatePackage({ ...payload });

  // server generates thumbs
  eventPackage.thumbnail = updatedPackage.get('thumbnail') ?? updatedPackage?.thumbnail;
  eventPackage.thumbnail_small = updatedPackage.get('thumbnail_small') ?? updatedPackage?.thumbnail_small;
  eventPackage.thumbnail_large = updatedPackage.get('thumbnail_large') ?? updatedPackage?.thumbnail_large;

  return eventPackage;
}

export async function getAllTagsByEntityClass(params: any, entityClass: string) {
  try {
    const ds = DataService.getInstance();
    const tags = await ds.getAllTagsByEntityClass(entityClass);
    tags.push(undefined);
    params.success(tags);
  } catch (error) {
    params.fail();
  }
}

export const createDatasourceWithDefaultFilter = (data: any, extraParams: Record<string, any> = {}): IServerSideDatasource => {
  return {
    getRows: async params => {
      try {
        const agGridConfig = { ...params.request };
        if (_.isEmpty(agGridConfig.filterModel)) {
          agGridConfig.filterModel = {
            ...data.defaultFilter,
          };
        }
        const response = await Parse.Cloud.run(data.cloudFnName, { agGridConfig, ...extraParams });
        params.success({
          rowData: response.rows,
          rowCount: response.total
        });
        if (Number(data.availablePageNoQuery)) {
          params.api.paginationGoToPage(Number(data.availablePageNoQuery))
        }
      } catch (error) {
        params.fail()
      }
    }
  }
}

export async function exportAsCSV(cloudFnName: string, params: any) {
  try {
    const data = await Parse.Cloud.run(cloudFnName, {
      agGridConfig: params.payload.agGridConfig,
      timeZoneOffset: params.payload.timeZoneOffset,
      ...params.payload,
    });
    return data;
  } catch (e: any) {
    throw e.error;
  }
}

function getImageFromObject(obj: any, keys: string[]): any {
  for (const key of keys) {
    if (obj[key]) {
      return obj[key];
    }
  }
  return null;
}

function getImageRecursive(obj: any, keys: string[]): any {
  let image = getImageFromObject(obj, keys);
  if (image) {
    return image;
  }

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      image = getImageRecursive(obj[key], keys);
      if (image) {
        return image;
      }
    }
  }

  return null;
}

export function getPackageImageFromPackage(pckage: EventPackage | any, preferredSize = 'thumbnail_large') {
  const keys = [preferredSize, 'image', 'thumbnail_small', 'thumbnail', 'thumbnail_large', 'thumb_sm', 'thumb_sq'];
  const packageThumbnail = getImageRecursive(pckage, keys);

  return packageThumbnail ? packageThumbnail._url ?? packageThumbnail.url : paidutils.getNoImageURL();
}