const IMAGE_MAX_WIDTH = 500

interface IResizeImageOptions {
  maxSize: number;
  file: File;
}

export interface IUploadImage {
  parseFile: Parse.File,
  id: string
}

export function getNoImageUrl(): string {
  return paidutils.getNoImageURL();
}

export async function uploadImage(image: File, name?: string, maxSize: number = IMAGE_MAX_WIDTH) {
  const resizedImage = await resizeImage({file: image, maxSize: maxSize}) as string;
  const fileName = name ? name : image.name
  const { fileName: cleanedFileName } = paidutils.sanitizeFileName(fileName);
  const parseFile = new Parse.File(cleanedFileName, {base64: resizedImage}, "image/jpg");
  return parseFile.save();
}

function resizeImage(settings: IResizeImageOptions) {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');
  const resize = () => {
    let width = image.width;
    let height = image.height;

    if (width > height) {
        if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
        }
    } else {
        if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
        }
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    return dataUrl;
  };

  return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error("Not an image"));
        return;
      }

      reader.onload = (readerEvent: any) => {
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
  })
}