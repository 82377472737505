<template>
  <div class="wrapper login-page">
    <div class="container login">
      <div id="modal-wrapper" />
      <form
        class="form-signin"
        role="form"
        @submit.prevent="login"
      >
        <div class="text-center">
          <object
            class="logo"
            type="image/svg+xml"
            data="/playmas-logo-color.svg"
            alt="Playmas Logo"
          >
            <img
              src="/playmas-logo-color.svg"
              alt="Playmas Logo"
            >
          </object>
        </div>
        <div
          v-show="error"
          id="error"
          class="alert alert-danger"
          role="alert"
        >
          {{ errorMessage }}
        </div>
        <div class="floating-label">
          <input
            v-model="email"
            class="form-control"
            placeholder="Email"
            type="email"
            autocomplete="off"
            autofocus
          >
          <label for="email">Email</label>
        </div>
        <div class="floating-label">
          <input
            v-model="password"
            class="form-control"
            placeholder="Password"
            type="password"
            autocomplete="off"
          >
          <label for="password">Password</label>
        </div>
        <button
          :disabled="isSubmitting"
          class="btn btn-lg btn-primary btn-block mt-4"
          type="submit"
        >
          {{ isSubmitting ? 'Loading...' : 'Log in' }}
        </button>
        <p class="text-center mt-4 text-muted">
          <a
            href="#"
            @click.prevent="resetPassword"
          >Reset password</a>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      error: false,
      errorMessage: '',
      isSubmitting: false,
    };
  },
  methods: {
    async resetPassword() {
      const resetEmail = prompt("Please enter your email:");
      if (resetEmail) {
        try {
          await Parse.User.requestPasswordReset(resetEmail);
          alert(`Email sent to ${resetEmail}`);
        } catch (error) {
          alert(`Error: ${error.message}`);
        }
      }
    },
    async login() {
      this.error = false;
      this.isSubmitting = true;
      try {
        const user = await Parse.User.logIn(this.email.toLowerCase(), this.password);
        const roles = user.get("roles") || [];
        const isCommitteeMember = await this.isCommittee(user);
        if (this.checkPermissions(roles, isCommitteeMember)) {
          this.redirectUser(roles, isCommitteeMember);
        } else {
          const isSectionLeader = await Parse.Cloud.run("isUserSectionLeader");
          if (isSectionLeader) {
            this.redirectUser(["sectionLeader"]);
          } else {
            await Parse.User.logOut();
            throw new Error("Invalid Permissions");
          }
        }
      } catch (error) {
        this.onLoginFailed(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    async isCommittee(user) {
      // Implement the check if user is a committee member
      return paidutils.isCommittee(user);
    },
    checkPermissions(roles, isCommitteeMember) {
      const allowedRoles = ["admin", "cashier", "finance", "operations", "redemption"];
      return roles.some(role => allowedRoles.includes(role)) || isCommitteeMember;
    },
    redirectUser(roles, isCommitteeMember) {
      if (roles.includes("admin")) {
        this.$router.push("/dashboard");
      } else if (roles.includes("cashier") || roles.includes("redemption")) {
        this.$router.push("/events");
      } else if (roles.includes("operations")) {
        this.$router.push("/inventory");
      } else if (roles.includes("finance")) {
        this.$router.push("/dashboard");
      } else if (isCommitteeMember) {
        this.$router.push("/events");
      }

      location.reload();
    },
    onLoginFailed(error) {
      this.error = true;
      this.errorMessage = error.message;
    }
  }
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
