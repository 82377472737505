import UserState from "../type-defs/interfaces/user";
import { ActionContext } from 'vuex';
import DataService from "../../services/DataService";
import _ from 'lodash';

const ds = DataService.getInstance()

// initial state
const state = {
  isMaster: null
}

// getters
const getters = {
  user: () => Parse.User.current(),
  roles: (_: any, getters: any) => {
    const user = getters.user;
    const roles = user ? user.get("roles") || [] : [];
    return roles;
  },
  isAdmin: (_: any, getters: any) => {
    return getters.roles.includes("admin")
  },
  isSectionLeader: (_: any, getters: any) => {
    const SECTION_LEADER_PREFIX = "SectionLeader__";
    const roles = getters.roles;
    const sectionLeaderRoles = roles.filter((roleName: string) => {
      return roleName.indexOf(SECTION_LEADER_PREFIX) === 0;
    });
    return sectionLeaderRoles.length > 0;
  },
  isCashier: (_: any, getters: any) => {
    return getters.roles.includes("cashier")
  },
  isRedemption: (_: any, getters: any) => {
    return getters.roles.includes("redemption")
  },
  isCashierOrRedemption: (_: any, getters: any) => {
    return getters.isCashier || getters.isRedemption
  },
  isRedemptionOnly: (_: any, getters: any) => {
    return getters.isRedemption && !getters.isCashier
  },
  isOperations: (_: any, getters: any) => {
    return getters.roles.includes("operations")
  },
  isFinance: (_: any, getters: any) => {
    return getters.roles.includes("finance")
  },
  isCommittee: (_a: any, getters: any) => {
    const roles = getters.roles || [];
    const booleanArray = roles.map((role: string)=>role.toLowerCase().startsWith("committee"));
    return _.some(booleanArray);
  },
  hasWriteAccess: (_: any, getters: any, rootState: any) => {
    const admin = getters.isAdmin;
    const cashier = getters.isCashier;
    const isSectionLeader = getters.isSectionLeader
    const writeAccess = rootState.settings.writeAccessToSectionLeader
    return admin || cashier || (isSectionLeader && writeAccess);
  },
  isOnlySectionLeader: (_: any, getters: any) => {
    return getters.isSectionLeader && !(getters.isAdmin || getters.isCashier || getters.isOperations);
  },
  isOnlyCommittee: (_: any, getters: any) => {
    return getters.isCommittee && !(getters.isAdmin || getters.isCashier || getters.isOperations);
  },
  isOnlyCashier: (_: any, getters: any) => {
    return getters.isCashier && !(getters.isAdmin || getters.isSectionLeader || getters.isOperations);
  },
  connectedStripeKey: (_: any, getters: any) => {
    return getters.user ? getters.user.get("connectedStripeKey") : null;
  },
  isMaster: (state: UserState) => state.isMaster,
  allowManagingDiscount: (_: any, getters: any, rootState: any) => {
    const sectionLeaderCanCreateDiscounts = rootState.settings.sectionLeaderCanCreateDiscounts;
    return getters.isAdmin || (getters.isSectionLeader && sectionLeaderCanCreateDiscounts)
  },
  allowManagingUser: (_: any, getters: any, rootState: any) => {
    const writeAccess = rootState.settings.writeAccessToSectionLeader
    if (getters.isAdmin) return true;
    if (getters.isCashier) return true;
    if (getters.isSectionLeader && writeAccess) return true;
    if (getters.isFinance) return false;

    return false;
  },
  allowManagingEvent: (_: any, getters: any) => {
    if (getters.isAdmin) return true;

    return false;
  },
  allowManagingItemTemplates: (_: any, getters: any) => {
    if (getters.hasWriteAccess) return true;

    return false;
  },
  allowManagingOrder: (_: any, getters: any) => {
    if (getters.hasWriteAccess) return true;

    return false;
  },
  allowManagingAffilate: (_: any, getters: any) => {
    if (getters.hasWriteAccess) return true;

    return false;
  },
  allowManagingTimeline: (_: any, getters: any) => {
    if (getters.hasWriteAccess) return true;

    return false;
  },
  allowManagingCollection: (_: any, getters: any) => {
    if (getters.hasWriteAccess) return true;

    return false;
  },
  allowManagingSettings: (_: any, getters: any) => {
    if (getters.isAdmin) return true;

    return false;
  },
  allowManagingRoles: (_: any, getters: any) => {
    if (getters.isAdmin) return true;

    return false;
  },
  allowManagingBilling: (_: any, getters: any) => {
    if (getters.isFinance && !getters.hasWriteAccess) return false;

    return true;
  },
  allowManagingCampaigns: (_: any, getters: any) => {
    if (getters.hasWriteAccess) return true;

    return false;
  },
  allowManagingDispute: (_: any, getters: any) => {
    if (getters.hasWriteAccess) return true;

    return false;
  }
}

// actons
const actions = {
  isMaster: async ({ commit }: ActionContext<UserState, any>): Promise<any> => {
    const isMaster = await ds.isMaster();
    commit('isMaster', isMaster);
  },
  fetchUser: async ({ commit }: ActionContext<UserState, any>): Promise<any> => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
      return;
    }

    const user = await currentUser.fetch();
    commit('user', user);
  }
}

// mutations
const mutations = {
  isMaster(state: UserState, isMaster: boolean) {
    state.isMaster = isMaster
  },
  user(state: UserState, user: Parse.User) {
    state.user = user
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
