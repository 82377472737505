/* eslint-disable @typescript-eslint/no-unused-vars */
export const ItemAddOn = Parse.Object.extend("PackageMembership", {

  initialize: function () {
  },

  itemTemplate: function () { return this.get("itemTemplate") ?? new Portal.Models.ItemTemplate(); },
  cost: function () { return this.get("itemTemplate").get("cost"); },
  formattedCost: function () {
    return paidutils.formatMoney(this.get("itemTemplate").get("cost"));
  },

  defaults: {
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});
