/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';

export const Order = Parse.Object.extend("Order", {
  initialize: function (parseObject: { order: any; payments: any; package: any; section: any[]; orderItems: { constructor?: any; }; _orderItems: any; instances: any; addons: unknown[]; }) {
    this.data = parseObject;

    this.order = parseObject.order;
    this.payments = parseObject.payments;

    this.package = parseObject.package;
    parseObject.section = _.filter(parseObject.section, function (obj) { return obj; });
    this.section = parseObject.section;

    this.instances = [];
    this.addons = [];

    if (Object.keys(parseObject.orderItems).length > 0 && parseObject.orderItems.constructor === Object) {
      //nothing here for now
      this.orderItems = parseObject._orderItems;
      this.stitch();
    }
    else {
      this.package = _.flatten([parseObject.package]);
      this.section = _.flatten([parseObject.section]);
      this.presentation = _.map(this.section, function (item) { return item.get("presentation"); });
      this.instances = _.map(parseObject.instances, function (inst) { return new Portal.Models.ItemInstance(inst) });

      _.each(parseObject.addons, (addOn: any) => {
        const parseItemInstance = addOn.item;
        //TODO SPECIAL FIXUP FOR ITEM INSTANCE CONTAINER NEEDS
        parseItemInstance.template = addOn.template;
        const addOnInst = new Portal.Models.ItemInstance(parseItemInstance);
        addOnInst.price = addOn.price;
        this.addons.push(addOnInst);
      });
    }
  },

  stitch: function () {
    const self = this;
    _.each(this.orderItems, function (item) {
      const match = _.find(self.data.orderItems, function (_dataItem, key) { return key == item.id; });
      if (match) {
        item.stitch(match);
      }
      else {
        console.warn("not stitching data for orderItem " + item.id);
      }
    });
  },

  balanceDue: function () {
    console.log("depreciated");
    const totCost = this.get("totalCost");
    const totPaid = this.get("amountPaid");
    const balanceDue = totCost - totPaid;
    //function to walk all payments to confirm everything is in sync?
    return paidutils.formatMoney(balanceDue);
  },
  isFullyPaid: function () {
    console.log("depreciated");
    if (this.get("amountPaid") && this.get("totalCost")) {
      return parseFloat(this.get("amountPaid")) >= parseFloat(this.get("totalCost"));
    }
    return false;
  },
  // id:function() { return this.order.id; },

  defaults: {
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});