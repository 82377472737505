export default class EventPackageImage {
  pckage: Parse.Object | null = null
  id = ''
  desc = ''
  sortOrder = 0
  image: Parse.File | null = null
  thumbnail: Parse.File | null = null
  thumbnail_small: Parse.File | null = null
  thumbnail_large: Parse.File | null = null
  imageUrl: string | null = null
  thumbnailUrl: string | null = null
  thumbnailSmallUrl: string | null = null
  thumbnailLargeUrl: string | null = null

  private parseEventPackageImage: Parse.Object = new Parse.Object()
  private dirty = false

  public constructor(packageImage?: Parse.Object) {
    this.parseEventPackageImage = packageImage ? packageImage : this.parseEventPackageImage
    this.id = packageImage ? packageImage.id : this.id

    this.pckage = packageImage ? packageImage.get('package') : this.pckage
    this.desc = packageImage ? packageImage.get('desc') : this.desc
    this.sortOrder = packageImage ? packageImage.get('order') || 0 : this.sortOrder
    this.image = packageImage ? packageImage.get('image') : this.image
    this.thumbnail = packageImage ? packageImage.get('thumbnail') : this.thumbnail
    this.thumbnail_small = packageImage ? packageImage.get('thumbnail_small') : this.thumbnail_small
    this.thumbnail_large = packageImage ? packageImage.get('thumbnail_large') : this.thumbnail_large

    this.imageUrl = this.image ? this.image.url() : null
    this.thumbnailUrl = this.thumbnail ? this.thumbnail.url() : null
    this.thumbnailSmallUrl = this.thumbnail_small ? this.thumbnail_small.url() : null
    this.thumbnailLargeUrl = this.thumbnail_large ? this.thumbnail_large.url() : null
    
    this.dirty = false
  }

  public updateParsePackageImage(): Parse.Object {
    if(this.parseEventPackageImage.get('desc') !== this.desc) {
      this.dirty = true
      this.parseEventPackageImage.set('desc', this.desc)
    }

    if(this.parseEventPackageImage.get('order') !== this.sortOrder) {
      this.dirty = true
      this.parseEventPackageImage.set('order', this.sortOrder)
    }

    if(this.parseEventPackageImage.get('image') !== this.image) {
      this.dirty = true
      this.parseEventPackageImage.set('image', this.image)
    }

    return this.parseEventPackageImage
  }

  public async deleteParsePackageImage(): Promise<Parse.Object> {
    return this.parseEventPackageImage.destroy()
  }

  public isDirty(): boolean {
    return this.dirty
  }

  public setNotDirty() {
    this.dirty = false
  }
}