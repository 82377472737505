import './helpers/initializeParse';
import Vue from "vue";
import VueRouter, { RawLocation, Route } from "vue-router";
import PrismicVue from "prismic-vue";
import App from "./App.vue";
import router from "./router/router";
import ParseModels from './vue/models';
import VueSignaturePad from "vue-signature-pad";
import VueQRCodeComponent from "vue-qrcode-component";
import "video.js/dist/video-js.css";
import "cropperjs/dist/cropper.min.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import './styles/main.min.css';
import './styles/font-awesome.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'admin-lte/dist/js/adminlte.min.js';

import paidutils from './helpers/paidutils';
const globalWindow = global as any;
import { Plugin } from 'vue-fragment';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faShoppingBasket,
    faUsers,
    faChartLine,
    faCreditCard,
    faMoneyBill,
    faTag,
    faDesktop,
    faArrowCircleRight,
    faQrcode,
    faWindowMinimize,
    faPlus,
    faCheck,
    faTimes,
    faEnvelope,
    faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import BootstrapVue, {
    TablePlugin,
    ButtonPlugin,
    PaginationPlugin,
    CardPlugin,
    ModalPlugin,
    TooltipPlugin,
    FormCheckboxPlugin,
    OverlayPlugin,
    TabsPlugin,
    FormRadioPlugin,
    FormGroupPlugin,
    ImagePlugin,
    EmbedPlugin,
    FormInputPlugin,
    FormTextareaPlugin,
    FormTimepickerPlugin,
    TimePlugin,
    FormDatepickerPlugin,
    FormPlugin,
    FormSelectPlugin,
    AvatarPlugin,
    InputGroupPlugin,
    VBTooltipPlugin,
    LayoutPlugin,
    ListGroupPlugin,
    DropdownPlugin,
    BadgePlugin,
    SkeletonPlugin,
    CollapsePlugin,
    AspectPlugin,
    SpinnerPlugin,
    FormTagsPlugin,
    CarouselPlugin,
    ButtonToolbarPlugin,
    ButtonGroupPlugin,
} from "bootstrap-vue";
import VueToastr from "vue-toastr";
import ToggleButton from "vue-js-toggle-button";
// import VueQRCodeComponent from 'vue-qrcode-component'
import VueTelInput from "vue-tel-input";
import VCreditCard from "v-credit-card";
import "v-credit-card/dist/VCreditCard.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import VueClipboard from "vue-clipboard2";
import VueScreen from "vue-screen";
import { LicenseManager } from "ag-grid-enterprise";
import VueLodash from "vue-lodash";
import lodash from "lodash";
LicenseManager.setLicenseKey(
    "CompanyName=Playmas Tech Inc,LicensedApplication=Playmas,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-028783,ExpiryDate=28_May_2023_[v2]_MTY4NTIyODQwMDAwMA==073b9957ebe0833aa09a20fa52b40581"
);

import linkResolver from "./vue/linkResolver";
import store from "./vue/store";
import "./vue/filters";
import moment from 'moment';
// import 'moment-timezone';

library.add(faShoppingBasket);
library.add(faUsers);
library.add(faChartLine);
library.add(faCreditCard);
library.add(faMoneyBill);
library.add(faTag);
library.add(faDesktop);
library.add(faArrowCircleRight);
library.add(faQrcode);
library.add(faWindowMinimize);
library.add(faPlus);
library.add(faCheck);
library.add(faTimes);
library.add(faEnvelope);
library.add(faPaperPlane);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
Vue.component('QrCode', VueQRCodeComponent)
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
Vue.component("VSelect", vSelect);
Vue.component("VCreditCard", VCreditCard);

Vue.config.productionTip = true;
Vue.use(VueSignaturePad);
Vue.use(ToggleButton);
Vue.use(TooltipPlugin);
// Bootstrap plugins
Vue.use(BootstrapVue);
Vue.use(OverlayPlugin);
Vue.use(TablePlugin);
Vue.use(ButtonPlugin);
Vue.use(PaginationPlugin);
Vue.use(CardPlugin);
Vue.use(ModalPlugin);
Vue.use(VueToastr);
Vue.use(FormCheckboxPlugin);
Vue.use(TabsPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormGroupPlugin);
Vue.use(ImagePlugin);
Vue.use(EmbedPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormTimepickerPlugin);
Vue.use(TimePlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormPlugin);
Vue.use(FormSelectPlugin);
Vue.use(AvatarPlugin);
Vue.use(InputGroupPlugin);
Vue.use(VBTooltipPlugin);
Vue.use(LayoutPlugin);
Vue.use(ListGroupPlugin);
Vue.use(DropdownPlugin);
Vue.use(BadgePlugin);
Vue.use(SkeletonPlugin);
Vue.use(CollapsePlugin);
Vue.use(AspectPlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormTagsPlugin);
Vue.use(CarouselPlugin);
Vue.use(ButtonToolbarPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(Plugin);

// eslint-disable-next-line @typescript-eslint/no-var-requires
Vue.use(require('vue-moment'), { moment });
Vue.prototype.moment = moment;
Vue.use(VueTelInput);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueScreen, "bootstrap");

Vue.use(PrismicVue, {
    endpoint: "https://mcarnivalmanager-website.cdn.prismic.io/api/v2",
    linkResolver,
});

Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
    return new Promise((resolve, reject) => {
        originalPush.call(this, location, () => {
            resolve(this.currentRoute);
        }, (error) => {
            if (error.name === 'NavigationDuplicated') {
                resolve(this.currentRoute);
            } else {
                console.warn(error.message);
                reject(error);
            }
        });
    });
};

Vue.config.ignoredElements = [/^ion-/];

globalWindow.paidutils = paidutils;
globalWindow.globalRouter = router;
globalWindow.Portal = { Models: ParseModels }

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
