/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';

export const Dispute = Parse.Object.extend("Dispute", {
  initialize: function () {
  },

  url: function () {
    const result = this.get("pdf");
    return result ? result.url() : null;
  },
  name: function () {
    const result = this.get("pdf");
    return result ? result.name() : null;
  },
  createTime: function () { return moment(this.createdAt).format("DD-MM-YYYY"); },
  amount: function () {
    const amount = parseFloat(this.get("amount"));
    return paidutils.formatMoney(amount);
  },

  fullName: function () {
    const user = this.user();
    return user ? user.fullName() : '';
  },
  isUserBanned: function () {
    const user = this.user();

    if (!user) {
      return false;
    }

    const userRoles = user.get("roles");
    return userRoles ? userRoles.includes("banned") : false;
  },
  user: function () {
    const payment = this.payment();
    if (!payment)
      return null;
    const order = payment.get("order");
    if (!order)
      return null;
    return new Portal.Models.User(order.get("user"));
  },

  userId: function () {
    const user = this.user();
    return user ? user.id : '';
  },

  payment: function () {
    return this.get("payment");
  },

  order: function () {
    return this.payment() ? this.payment().get("order") : '';
  },

  orderId: function () {
    return this.order() ? this.order().id : null;
  },

  orderDesc: function () {
    return this.order().get("option_desc");
  },

  note: function () {
    return this.get("notes");
  },

  actionBy: function () { return new Portal.Models.User(this.get("actionBy")); },

  actionById: function () {
    return this.get('actionBy') ? new Portal.Models.User(this.get('actionBy')).id : '';
  },

  actionByUserName: function () {
    return this.get('actionBy') ? new Portal.Models.User(this.get('actionBy')).fullName() : '';
  },

  paymentId: function () { return this.payment() ? this.payment().id : ''; },

  transferId: function () {
    const transfer = this.get("transfer");
    return transfer ? transfer.id : null;
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});
