import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../vue/pages/Dashboard.vue"),
    },
    {
        path: '/dailyoverview',
        name: 'Daily Overview',
        component: () => import('../vue/pages/DailyOverview.vue')
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('../vue/pages/Users.vue')
    },
    {
        path: '/users/:id',
        name: 'User',
        component: () => import('../vue/Users/components/UserDetails.vue'),
        props: true
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('../vue/pages/Orders.vue')
    },
    {
        path: '/order/:id',
        name: 'Order',
        component: () => import('../vue/Orders/components/Order.vue'),
        props: true
    },
    {
        path: '/orderitems',
        name: 'Order Items',
        component: () => import('../vue/pages/EventOrderItems.vue')
    },
    {
        path: "/events",
        name: "Events",
        component: () => import("../vue/Events/Events.vue"),
    },
    {
        path: "/carnival/:eventId",
        name: "Carnival",
        component: () => import("../vue/pages/Events/CarnivalEvent.vue"),
        props: true,
    },
    {
        path: '/carnival/:eventId/tv-mode',
        name: 'TV Mode',
        component: () => import('../vue/components/Distribution/DistributionInTvMode.vue'),
        props: true
    },
    {
        path: "/failedStripeEvents",
        name: "Failed Stripe Events",
        component: () => import("../vue/stripeEvents/FailedStripeEvents.vue"),
    },
    {
        path: '/discounts',
        name: 'Discounts',
        component: () => import('../vue/pages/Discounts.vue')
    },
    {
        path: '/discounts/:id',
        name: 'Discount',
        component: () => import('../vue/components/discounts/DiscountDetail.vue'),
        props: true
    },
    {
        path: '/package/:packageId',
        name: 'Package',
        component: () => import('../vue/components/Packages/PackageDetail.vue'),
        props: true
    },
    {
        path: '/livestream/:eventId',
        name: 'Ticketed Event',
        component: () => import('../vue/pages/Events/TicketedEvent.vue'),
        props: true
    },
    {
        path: "/distribution",
        name: "Distribution",
        component: () => import("../vue/pages/Distribution.vue"),
    },
    {
        path: '/inviteUsers',
        name: 'Invite Users',
        component: () => import('../vue/components/UserInvite.vue')
    },
    {
        path: '/roles',
        name: 'Roles',
        component: () => import('../vue/pages/Roles.vue')
    },
    {
        path: "/settings",
        name: "Settings",
        component: () => import("../vue/pages/Settings.vue"),
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('../vue/pages/Profile.vue')
    },
    {
        path: '/payments',
        name: 'Payments',
        component: () => import('../vue/pages/Payments.vue')
    },
    {
        path: '/payment/:paymentId',
        name: 'Payment',
        component: () => import('../vue/pages/Payment.vue'),
        props: true
    },
    {
        path: '/reports',
        name: 'Reports',
        component: () => import('../vue/Reports/pages/Reports.vue')
    },
    {
        path: '/dispute',
        name: 'Dispute',
        component: () => import('../vue/pages/Dispute.vue')
    },
    {
        path: '/billing',
        name: 'Billing',
        component: () => import('../vue/pages/Billing.vue')
    },
    {
        path: '/cashier',
        name: 'Cashier',
        component: () => import('../vue/fetes/Cashier.vue')
    },
    {
        path: '/recommend',
        name: 'Recommend Packages',
        component: () => import('../vue/pages/RecommendedPackages.vue')
    },
    {
        path: '/resale',
        name: 'Resale',
        component: () => import('../vue/pages/Resale.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../vue/pages/Contact/ContactCampaigns.vue'),
    },
    {
        path: '/contact-jobs',
        name: 'Contact Jobs',
        component: () => import('../vue/pages/Contact/ContactJobs.vue'),
    },
    {
        path: '/contact-jobs/:jobId',
        name: 'Contact Job',
        component: () => import('../vue/pages/Contact/ContactJobsDetail.vue'),
        props: true
    },
    {
        path: '/contact/template/:campaignId',
        name: 'Contact Template',
        component: () => import('../vue/pages/Contact/ContactTemplate.vue'),
        props: true
    },
    {
        path: "/contact/campaign/:campaignId",
        name: "Contact Campaign",
        component: () => import("../vue/pages/Contact/ContactCampaign.vue"),
        props: true
    },
    {
        path: '/contact/editor/:campaignId',
        name: 'Contact Editor',
        component: () => import('../vue/pages/Contact/ContactEditor.vue'),
        props: true
    },
    {
        path: '/processorConnect',
        name: 'Processor Connect',
        component: () => import('../vue/pages/ProcessorConnect.vue')
    },
    {
        path: '/termsOfService',
        name: 'Terms of Service',
        component: () => import('../vue/pages/TermsOfService.vue')
    },
    {
        path: "/carts",
        name: "Carts",
        component: () => import("../vue/pages/AllCarts.vue"),
    },
    {
        path: '/cart/:cartId',
        name: 'Cart',
        component: () => import('../vue/pages/CartDetails.vue'),
        props: true
    },
    {
        path: '/order-collection/:orderId',
        name: 'Order Collection',
        component: () => import('../vue/pages/OrderCollection.vue'),
        props: true
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Not Found',
        redirect: () => {
            return '/dashboard';
        }
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.afterEach((to: Route) => {
    if (!to.query.pageNo) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // You can use 'auto' instead of 'smooth' for an instant scroll
        });
    }
    $('body').removeClass('sidebar-open');
    paidutils.confirmStillLoggedIn();
    paidutils.refreshConfig();
    paidutils.refreshAppSettings();
})

router.beforeEach((to: any, from: any, next: any) => {
    if (Parse.User.current()) {
        next();
    } else {
        if (to.path !== from.path) {
            next({ name: '/Login', query: { redirect: to.path } })
        } else {
            next(false);
        }
    }
});

export default router;
