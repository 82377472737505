/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';

export const ItemInstance = Parse.Object.extend("ItemInstance", {

  stitch: function (entry: { _itemOptions: any; _addons: any; }) { //expects entry in form {_itemOptions:[parseObjects], itemOptions:[stripped down json objects for children]};
    this.itemOptions = entry._itemOptions;

    this.addOns = entry._addons;
    /*
    _.each(this.addOns, function(inst) {
      var match = _.find(entry.addons, function(item, key) { return inst.id == key;});
      inst.stitch(match);
    });
    */
  },

  optionNameList: function () {
    const list: any[] = [];
    _.each(this.itemOptions, function (itemOption) {
      list.push(itemOption.get("optionValue"));
    });

    return list.join(", ");
  },

  isAddOn: function () {
    return this.addOns && this.addOns.length > 0;
  },

  addOns: function () {
    return this.addOns;
  },

  template: function () {
    return this.get("itemTemplate");
  },

  validate: function (_attrs: any, _options: any) {
  },

  noCollectionReason: function () {
    if (this.get("noCollectionReason")) {
      return this.get("noCollectionReason").reasonText.length ? `(${this.get("noCollectionReason").reasonCode}: ${this.get("noCollectionReason").reasonText})` : `(${this.get("noCollectionReason").reasonCode})`;
    }
    return false;
  },

  collector_user: function () {
    return this.get('collector_user');
  },

  collectedAt: function () {
    return this.get('collectedAt');
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});