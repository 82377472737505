import EventPackageState from "../type-defs/interfaces/eventPackageState";
import { ActionContext } from 'vuex';
import DataService from "../../services/DataService";
import EventPackage from "../models/eventPackage";
import EventPackageImage from "../models/eventPackageImage";
import { updateEventPackage } from "../../lib/common";
const ds = DataService.getInstance();

// initial state
const state: EventPackageState = {
  eventPackage: new EventPackage()
}

function resetEventPackageState(state: EventPackageState) {
  state.eventPackage = new EventPackage()
}

// getters
const getters = {
  id: () => state.eventPackage.id,
  name: () => state.eventPackage.name,  
  desc: () => state.eventPackage.desc,
  image: () => state.eventPackage.image,
  images: () => state.eventPackage.images,
  sectionId: () => state.eventPackage.sectionId,
  eventPackage: () => state.eventPackage
}

// actons
const actions = {
  getEventPackage: async ({commit, state}: ActionContext<EventPackageState, any>, packageId: string) => {
    resetEventPackageState(state)
    
    const parsePackage = await ds.getPackageById(packageId)
    const eventPackage = new EventPackage(parsePackage)

    const parsePackageImages = await ds.getImagesForPackage(eventPackage.id)
    const packageImages = parsePackageImages.map(packageImage => new EventPackageImage(packageImage))
    eventPackage.images = packageImages.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1)
    
    commit('eventPackage', eventPackage)
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updatePackage: async ({commit}: ActionContext<EventPackageState, any>, packageDetails: any) => {
    state.eventPackage = await updateEventPackage(state.eventPackage, packageDetails)
  }
}

// mutations
const mutations = {
  eventPackage (state: EventPackageState, eventPackage: EventPackage) {    
    state.eventPackage = eventPackage
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
