/* eslint-disable @typescript-eslint/no-unused-vars */
export const AddOnMembership = Parse.Object.extend("PackageMembership", {
  initialize: function () {
  },

  itemTemplate: function () { return this.get("itemTemplate") ?? new Portal.Models.ItemTemplate(); },
  cost: function () { return this.get("itemTemplate").get("cost"); },
  package: function () { return this.get("package"); },

  qtyAvailable: function () { return this.get("itemTemplate").get("qtyAvailable"); },
  qtySold: function () { return this.get("itemTemplate").get("qtySold"); },
  soldout: function () { return this.get("itemTemplate").get("soldOutOverride"); },
  isPublic: function () { return this.get("isPublic") },

  formattedCost: function () {
    return paidutils.formatMoney(this.cost());
  },

  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});