export default class OrderDataService {

  private static _instance: OrderDataService = new OrderDataService();

  public static getInstance(): OrderDataService {
    return OrderDataService._instance;
  }

  constructor() {
    if (OrderDataService._instance) {
      throw new Error('Use getInstance() instead of new.');
    }
    OrderDataService._instance = this;
  }

  //********************************
  // Band's common endpoints
  //*******************************/

  public async markAsCollected(collectionInfo: object) {
    const response = await Parse.Cloud.run("markOrderCollected", { collectionInfo });
    return response;
  }

  public async getOrders(filters: object, options: object) {
    const response = await Parse.Cloud.run("getOrders", { filters, options });
    return response;
  }

  public async cancelOrderItem(orderItemId: string) {
    const response = await Parse.Cloud.run("cancelOrderItem", { orderItemId });
    return response;
  }

  public async removeCollectorFromOrderItem(orderItemId: string) {
    const response = await Parse.Cloud.run("removeCollectorFromOrderItem", { orderItemId });
    return response;
  }

  public async removePackageOwnerFromOrderItem(id: string) {
    const response = await Parse.Cloud.run("removePackageOwnerFromOrderItem", { id });
    return response;
  }

  public async addCollectorToOrderItem(orderItemId: string, collector: any) {
    const response = await Parse.Cloud.run("addCollectorToOrderItem", { orderItemId, collector });
    return response;
  }

  public async addPackageOwnerForOrderItem(id: string, ownerData: any) {
    const response = await Parse.Cloud.run("addPackageOwnerForOrderItem", { id, ownerData });
    return response;
  }
}