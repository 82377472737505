/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
class CustomParseObject extends Parse.Object {
  toJSON() {
    const data: any = super.toJSON();
    delete data.createdAt;
    delete data.updatedAt;
    return data;
  }
}

// Register the subclass with Parse
Parse.Object.registerSubclass('User', CustomParseObject);

export const User = CustomParseObject.extend("User", {

  initialize: function () {
  },

  defaults: {
    firstName: "",
    lastName: "",
    fullName: "",
    image: null,
    gender: 0,
  },

  getOrderCount: function () { return this.get("orderCount"); },
  getUser: function () { return this; },
  firstName: function () { return this.get("firstName"); },
  lastName: function () { return this.get("lastName"); },
  fullName: function () {
    const fullname = [];
    if (this.get("firstName")) fullname.push(this.get("firstName"));
    if (this.get("lastName")) fullname.push(this.get("lastName"));

    if (fullname.length == 0) {
      return "N/A";
    } else {
      return fullname.join(' ');
    }
  },
  email: function () { return this.get('email'); },
  address: function () { return this.get("address"); },
  addressLine2: function () { return this.get("addressLine2"); },
  city: function () { return this.get("city"); },
  state: function () { return this.get("state"); },
  postalCode: function () { return this.get("postalCode"); },
  phone: function () { return this.get("phone"); },
  country: function () {
    const code = this.get("country");
    return paidutils.getCountryFromCode(code);
  },
  countryCode: function () { return this.get("country"); },
  stripeCustomerId: function () { return this.get("stripeCustomerId"); },
  gender: function () { return paidutils.getUserGenderName(this.get("gender")); },
  isMale: function () { return this.get("gender") ? parseInt(this.get("gender")) == 0 : true; },
  prettyCreatedAt: function () { return moment(this.createdAt).format("YYYY-MM-DD HH:mm a"); },
  createdAtTimestamp: function () { return moment(this.createdAt).format("X"); }, //moment format "X" is unix timestamp
  fromNowDate: function () { return moment(this.createdAt).fromNow(); },
  image: function () {
    if (this.get("profileImageThumbnail")) {
      return this.get("profileImageThumbnail").url();
    } else {
      return paidutils.getNoImageURL();
    }
  },
  imageLarge: function () {
    if (this.get("profileImageThumbnailLarge")) {
      return this.get("profileImageThumbnailLarge").url();
    } else {
      return paidutils.getNoImageURL();
    }
  },
  roles: function () {
    const userRoles = this.get("roles");
    if (userRoles && Array.isArray(userRoles) && userRoles.length) {
      return userRoles.join(", ");
    }
    return "";
  },
  isBanned: function () {
    return _.includes(this.rolesArray(), "banned");
  },

  isDeleted: function () {
    return this.get('deleted');
  },


  rolesArray: function () {
    return this.get("roles");
  },

  isTypeGeneral: function () { return this.get("type") ? parseInt(this.get("type")) == 0 : true; },
  isTypeCommitte: function () { return parseInt(this.get("type")) == 1; },
  isTypeSponsored: function () { return parseInt(this.get("type")) == 2; },
  isOperations: function () { return _.includes(this.get("roles"), "operations"); },
  isSectionLeader: function () {
    const sectionLeaderRoles = this.getSectionLeaderRoles();
    return sectionLeaderRoles.length > 0;
  },
  isAdmin: function () { return _.includes(this.get("roles"), "admin"); },
  getSectionLeaderRoles() {
    const SECTION_LEADER_PREFIX = "SectionLeader__";
    const roles = this.get("roles");
    const sectionLeaderRoles = _.filter(roles, function (roleName) {
      return roleName.indexOf(SECTION_LEADER_PREFIX) === 0;
    });
    return sectionLeaderRoles;
  },
  countries: function () {
    return paidutils.getList(this.countryCode());
  },
  role: function () { return this.get("displayRole"); },
  emailVerified: function () { return this.get("emailVerified"); },
  dateOfBirthMoment: function () {
    const date = this.get("dateOfBirth");
    if (date) {
      return moment(date);
    }
    return null;
  },
  dateOfBirth: function () {
    return this.get("dateOfBirth");
  },
  tagsArray: function () {
    return this.get('tags') || [];
  },
  tags: function () {
    return this.tagsArray().length > 0 ? this.tagsArray() : [];
  },
  tagsToString: function () {
    return this.tagsArray().join(', ');
  },
  validate: function (_attrs: any, _options: any) {
  },

  parse: function (response: any, _options: any) {
    return response;
  }
});