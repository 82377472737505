/* eslint-disable no-prototype-builtins */
let _cachedSettings: any;
let _cachedStripePublicKey: any;
let _cachedCarnivalStripePublicKey: any;
const MAX_FILENAME_LENGTH = 32;
import { Base64 } from 'js-base64';
import _ from 'lodash';
import moment from 'moment';
import $ from 'jquery';

const globalWindow = globalThis.window as any;

const paidutils = {

  replaceMergeTags: function (text: string, settings: any) {
    const user = Parse.User.current() || null;
    const mergeTagToValue: any = {
      USER_FIRST_NAME: user ? user.get("firstName") : "",
      USER_LAST_NAME: user ? user.get("lastName") : "",
      USER_EMAIL: user ? user.get("email") : "",
      BAND_NAME: settings.band_name,
    };

    const getMergeTagValue = function (tagName: string) {
      const key = tagName.replace(/[*]/g, "");
      const value = mergeTagToValue.hasOwnProperty(key) ? mergeTagToValue[key] : "";
      return value;
    };

    const mergeTags = text.match(/\*\s*[^*]*?\s*\*/g); //https://regex101.com/r/QvTS6q/2
    const mergeValue = _.map(mergeTags, function (tag) { return getMergeTagValue(tag); });

    _.each(mergeTags, function (tag, index) {
      text = text.replace(tag, mergeValue[index]);
    });

    return text;
  },

  getWebsiteTextMapFromSettings: function (settings: { [x: string]: any; }, resolveMergeTags: any) {
    // defaults resolveMergeTags to true
    resolveMergeTags = typeof resolveMergeTags !== 'undefined' ? resolveMergeTags : true;

    const settingKeys = Array.from(Object.keys(settings));
    const websiteTextKeys = _.filter(settingKeys, function (key) { return key.startsWith("website_text_"); });
    const website_text: any = [];
    _.each(websiteTextKeys, function (key) {
      const value = resolveMergeTags ? paidutils.replaceMergeTags(settings[key], settings) : settings[key];
      website_text.push({
        key: key.replace("website_text_", ""),
        value: value
      });
    });

    return website_text;
  },

  validEmail: function (email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  validateItemTemplate: function (itemTemplate: { name: any; options: any; }) {
    let validated = true;
    if (paidutils.hasSpecialCharacters(itemTemplate.name)) {
      validated = false;
    }

    _.each(itemTemplate.options, function (option) {
      if (paidutils.hasSpecialCharacters(option.name)) {
        validated = false;
      }

      _.each(option.value, function (v) {
        if (paidutils.hasSpecialCharacters(v)) {
          validated = false;
        }
      });
    });

    return validated;
  },

  validateItemTemplateMaxOptions: function (itemTemplate: { options: any; }) {
    let validated = true;
    const itemOptions: any = []

    _.each(itemTemplate.options, function (option) {
      itemOptions.push(option.value.length)
    })

    if (!_.isEmpty(itemOptions)) {
      const totalCombos = itemOptions.reduce(function (a: number, b: number) {
        return a * b
      });

      if (totalCombos > 300) {
        validated = false;
      }
    }
    return validated
  },

  hasSpecialCharacters: function (str: string) {
    return /[~`!#$%\\^*=\\[\]';,{}|":<>\\?]/g.test(str);
  },

  getCurrencyCode: function () {
    return Parse.Config.current() && Parse.Config.current().get("stripeCurrency");
  },

  getCurrencySymbol: function () {
    return Parse.Config.current() && Parse.Config.current().get("stripeCurrencySymbol");
  },

  getBandName: function () {
    return Parse.Config.current() && Parse.Config.current().get("bandName");
  },

  getPortalURL: function () {
    return Parse.Config.current() && Parse.Config.current().get("portalURL");
  },

  getNoImageURL: function () {
    return "/no-image.jpg";
  },

  withSelectedProperties: function (object: any, properties: any) {
    if (_.isNil(object)) {
      return {};
    }
    if (_.isEmpty(properties)) {
      if (_.isArray(object)) {
        return _.map(object, function (item) {
          return (item && item.toJSON) ? item.toJSON() : item;
        });
      } else {
        return (object && object.toJSON) ? object.toJSON() : object;
      }
    } else {
      if (_.isArray(object)) {
        return _.map(object, function (item) {
          return _.pick((item && item.toJSON) ? item.toJSON() : item, properties)
        });
      } else {
        return _.pick((object && object.toJSON) ? object.toJSON() : object, properties);
      }
    }
  },

  withoutSelectedProperties(object: any, properties = []) {
    if (_.isEmpty(properties)) {
      if (_.isArray(object)) {
        return _.map(object, item => item.toJSON ? item.toJSON() : item);
      } else {
        return object.toJSON ? object.toJSON() : object;
      }
    } else {
      if (_.isArray(object)) {
        return _.map(object, item => _.omit(item.toJSON ? item.toJSON() : item, properties));
      } else {
        return _.omit(object.toJSON ? object.toJSON() : object, properties);
      }
    }
  },

  setQueryParamsForFilters: function (routeName: string, filters: any, type: string) {
    const stringifiedEncodedFilter = Base64.encode(JSON.stringify(filters));
    const query = (type == 'quick') ? '?quick_filter=' + stringifiedEncodedFilter : '?q=' + stringifiedEncodedFilter;
    globalRouter.push(routeName + query);
  },

  setQueryParamsForPage: function (routeName: string, pageNo: string) {
    try {
      const pageQuery = 'pageNo=' + pageNo;
      const queryUrlWithoutPageNo = this.removeQueryParam('pageNo');
      const currentRouteQueryParams = queryUrlWithoutPageNo.split('?')[1];
      if (currentRouteQueryParams && currentRouteQueryParams.length) {
        globalRouter.push(routeName + '?' + currentRouteQueryParams + '&' + pageQuery);
        return;
      }
      globalRouter.push(routeName + '?' + pageQuery);
    } catch (err: any) {
      if (
        err.name !== 'NavigationDuplicated' &&
        !err.message.includes('Avoided redundant navigation to current location')
      ) {
        console.warn(err);
      }
    }
  },

  getFiltersFromQueryParams: function (encodedString: string) {
    const filterModel = JSON.parse(Base64.decode(encodedString));
    return filterModel;
  },

  getStringifiedFilter: function (filterObj: any) {
    if (_.isObject(filterObj)) {
      return Base64.encode(JSON.stringify(filterObj));
    } else {
      return filterObj;
    }
  },

  getFilterQuery: function (queryParamName: string) {
    let filterObj: any = false;
    const extractParams = window.location.href.split('?')[1];
    if (extractParams && extractParams.length) {
      const splitParams = extractParams.split('&')
      splitParams.forEach(function (params) {
        const splitKeyPairs = params.split('=');
        if (splitKeyPairs[0] == queryParamName && decodeURIComponent(splitKeyPairs[1]).trim().length) {
          filterObj = decodeURIComponent(splitKeyPairs[1]);
        }
      })
    }
    return filterObj;
  },

  removeQueryParam: function (queryParamName: string) {
    const extractParams = window.location.href.split('?')[1];
    let urlParams = '';
    if (extractParams && extractParams.length) {
      const splitParams = extractParams.split('&')
      splitParams.forEach(function (params, index) {
        const splitKeyPairs = params.split('=');
        if (splitKeyPairs[0] == queryParamName) {
          splitParams.splice(index, 1)
        }
      })
      urlParams = urlParams + '?' + splitParams.join('&');
    }

    return urlParams;
  },

  toKeyValueArray: function (obj: any) {
    const array: any = [];
    if (Array.isArray(obj)) {
      _.each(obj, function (item, i) {
        array.push({
          key: i,
          value: item
        });
      });
    } else {
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          array.push({
            key: prop,
            value: obj[prop]
          });
        }
      }
    }

    return array;
  },

  getPaymentOptions: function () {
    return [
      { value: 0, name: "Online Payment" },
      { value: 2, name: "Cash" },
      { value: 3, name: "Offline Payment" },
      { value: 4, name: "Discount", settingRole: "roleForDiscounts" },
      { value: 5, name: "Refund", role: "master" }
    ];
  },

  getPaymentMethodByValue: function (value: any) {
    const paymentMethods = paidutils.getPaymentOptions()
    const findPaymentMethod = _.find(paymentMethods, { value: value });
    return findPaymentMethod ? findPaymentMethod.name : '';
  },

  getPaymentMethodByName: function (name: any) {
    const paymentMethods = paidutils.getPaymentOptions()
    const findPaymentMethod = _.find(paymentMethods, { name: name });
    return findPaymentMethod ? findPaymentMethod.value : '';
  },

  GetCustomerToken: function (desc: any, callback: any) {
    const self = this;
    this.refreshConfig();
    paidutils.getAppSettings().then((settings: any) => {
      if (settings.server_dev === true) {
        callback("token");
        return;
      }

      return self.GetPaymentHandler(callback)
    }).then(function (handler: { open: (arg0: { name: any; description: any; email: any; }) => void; }) {
      handler.open({
        name: self.getBandName(),
        description: desc,
        email: Parse.User.current()?.get("email"),
      });
    }).catch(function (error: any) {
      // toastr.error(error);
      console.log('error:', error);

    });
  },

  GetPaymentHandler: function (callback: any) {
    const self = this;
    return Parse.Cloud.run("getCarnivalStripePublicKey").then(function (key) {
      return globalWindow.StripeCheckout.configure({
        key: key,
        image: self.getNoImageURL(),
        locale: 'auto',
        currency: self.getCurrencyCode(),
        token: callback
      });
    });
  },

  getStripePublicKey: function () {
    //console.log('get cached Stripe public key:', _cachedStripePublicKey);
    return _cachedStripePublicKey;
  },

  getPaypalAccessToken: function () {
    return Parse.Cloud.run("getPaypalAccessToken");
  },

  getCarnivalStripePublicKey: function () {
    //console.log('get cached Stripe public key:', _cachedCarnivalStripePublicKey);
    return _cachedCarnivalStripePublicKey;
  },

  getApplyablePaymentOptions: function () {
    const paymentOptions = paidutils.getPaymentOptions();
    paymentOptions.splice(-1); // remove refunds
    return paymentOptions.splice(1); // remove online payments
  },

  getPaymentMethod: function (option: string | number) {
    const paymentOptions = paidutils.getPaymentOptions();
    option = parseInt(option as string);
    const paymentMethod = _.find(paymentOptions, { value: option });
    if (!paymentMethod) {
      return "Unknown Payment Method";
    }
    return paymentMethod.name;
  },

  // Fetches the config at most once every 12 hours per app runtime
  refreshConfig: function () {
    let lastFetchedDate: Date | undefined;
    const configRefreshInterval = 12 * 60 * 60 * 1000;
    return function () {
      const currentDate = new Date();
      if (lastFetchedDate === undefined ||
        currentDate.getTime() - lastFetchedDate.getTime() > configRefreshInterval) {
        Parse.Config.get();
        lastFetchedDate = currentDate;
        Promise.all([Parse.Cloud.run("getStripePublicKey"), Parse.Cloud.run("getCarnivalStripePublicKey")])
          .then(function (results) {
            _cachedStripePublicKey = results[0];
            _cachedCarnivalStripePublicKey = results[1];
          }).catch(function (error) {
            console.error('failed getting Stripe public key:', error);
          });
      }
    };
  }(),

  // will get fresh app settings every 30 seconds when called
  refreshAppSettings: function () {
    return _.throttle(function () {
      Parse.Cloud.run("getAppSettings").then(function (settings) {
        _cachedSettings = settings;
      });
    }, 30 * 1000, { leading: false });
  }(),

  getAppSettings: function () {
    let _promise: Promise<unknown>;

    return function () {
      paidutils.refreshAppSettings();
      if (_cachedSettings) {
        return Promise.resolve(_cachedSettings);
      } else if (_promise) {
        return _promise;
      } else {
        _promise = Parse.Cloud.run("getAppSettings").then(function (settings) {
          _cachedSettings = settings;
          return Promise.resolve(settings);
        });
        return _promise;
      }
    };
  }(),

  getUserGenderOptions: function () {
    return [
      "Male",
      "Female"
    ];
  },

  getUserGenderName: function (gender: string | number) {
    const genders = paidutils.getUserGenderOptions();
    gender = parseInt(gender as string);
    if (gender >= 0 && gender < genders.length) {
      return genders[gender];
    }

    return "Unknown";
  },

  getGenderOptions: function () {
    return paidutils.getUserGenderOptions().map((gender, index) => ({ value: index, text: gender }));
  },

  getUserGender: function (value: number) {
    const gender = paidutils.getGenderOptions().find((option) => option.value == value);
    return gender ? gender.text : null;
  },

  getPackageGenderOptions: function () {
    return [
      "Male & Female",
      "Male",
      "Female"
    ];
  },

  getPackageGenderName: function (gender: string | number) {
    const genders = paidutils.getPackageGenderOptions();
    gender = parseInt(gender as string);
    if (gender >= 0 && gender < genders.length) {
      return genders[gender];
    }

    return "Unknown";
  },

  roundMoney: function (amount: number) {
    return (Math.round(amount * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2)
  },

  formatMoney: function (amount: number | bigint, currency?: any) {
    currency = currency || this.getCurrencyCode(); // defaults to global currency
    const formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(amount)
    if (!formatted) {
      return "N/A";
    }

    return formatted;
  },

  formatDate: function (date: { getDate: () => any; getMonth: () => any; getFullYear: () => any; }) {
    // Create an array of abbreviated month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the day of the month, the month name from our array, and the full year
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Return the formatted string
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  },

  userFullName(data: { firstName: any; lastName: any; objectId: any; }) {
    return (data.firstName && data.lastName) ? `${data.firstName} ${data.lastName}` : data.objectId;
  },

  formatDuration: function (durationInSeconds: moment.DurationInputArg1) {
    if (durationInSeconds == undefined || durationInSeconds == null || durationInSeconds == 0) {
      return '-';
    }
    const duration = moment.duration(durationInSeconds, 'seconds');
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    let formattedDuration = '';
    if (days > 0) {
      formattedDuration += `${days}d `;
    }
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
    if (minutes > 0) {
      formattedDuration += `${minutes}m `;
    }
    if (seconds > 0 && formattedDuration == '') {
      formattedDuration += `${seconds}s `;
    }

    return formattedDuration.trim();
  },

  guid: function () {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  },

  // currently only supports JPG images
  uploadImage: async function (imageInputField: any) {
    await paidutils.validateImage(imageInputField, {});
    const file = imageInputField.files[0];
    const name = paidutils.guid() + ".jpg";
    const parseFile = new Parse.File(name, file, "jpg");
    return await parseFile.save();
  },

  uploadFile: function (fileInput: { files: any[]; }) {
    const file = fileInput.files[0];
    const name = paidutils.guid() + ".csv";
    const parseFile = new Parse.File(name, file, "csv");
    return parseFile.save();
  },

  uploadDataURL: function (dataURL: string, fileExtension?: string) {
    const ext = fileExtension || "jpg";
    const base64 = dataURL.split('base64,')[1];
    const name = paidutils.guid() + "." + ext;
    const parseFile = new Parse.File(name, { base64: base64 }, ext);
    return parseFile.save();
  },

  generateImagePreview: function (input: { files: Blob[]; }) {
    const promise = Promise;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        promise.resolve(e?.target?.result);
      };

      reader.readAsDataURL(input.files[0]);
    } else {
      promise.reject("No File");
    }

    return promise;
  },

  resizeImage: function (image: { files: any[]; }, maxWidth: any, maxHeight: any) {
    const file = image.files[0];
    return this.resizeImageFromFile(file, maxWidth, maxHeight);
  },

  resizeImageFromFile: function (file: Blob | MediaSource, maxWidth?: number, maxHeight?: number) {
    const promise = Promise;
    const imgLoader = new Image();
    imgLoader.onload = function () {

      // Desired size
      const max_width = maxWidth || 1000;
      const max_height = maxHeight || 1000;

      // Get image dimensions
      const original_width = imgLoader.width;
      const original_height = imgLoader.height;

      // Calculate final dimensions
      let new_height;
      let new_width;
      let ratio;
      if (original_width > original_height) {
        if (original_width > max_width) {
          ratio = max_width / original_width;
          new_height = Math.round(original_height * ratio);
          new_width = max_width;
        } else {
          new_height = original_height;
          new_width = original_width;
        }
      } else {
        if (original_height > max_height) {
          ratio = max_height / original_height;
          new_width = Math.round(original_width * ratio);
          new_height = max_height;
        } else {
          new_height = original_height;
          new_width = original_width;
        }
      }

      // Resizing function
      function resizeStep(image: HTMLImageElement | HTMLCanvasElement, new_width: number, new_height: number): HTMLCanvasElement {

        // Create new canvas
        const canvas = document.createElement('canvas');
        const ctx: any = canvas.getContext('2d');

        // Get incremental image size
        const half_width = Math.round(image.width / 2);
        const half_height = Math.round(image.height / 2);

        if (half_width > new_width) {

          // Resize image	by 50%
          canvas.width = half_width;
          canvas.height = half_height;
          ctx.drawImage(image, 0, 0, half_width, half_height);

          // Resize again
          return resizeStep(canvas, new_width, new_height);

        } else {
          // Final Resize of Image
          canvas.width = new_width;
          canvas.height = new_height;
          ctx.drawImage(image, 0, 0, new_width, new_height);

          // Return resized image
          return canvas;
        }
      }

      // Fire resizing function
      const resized_image = resizeStep(imgLoader, new_width, new_height);
      return promise.resolve(resized_image);
    };

    // Load image
    const _URL = window.URL || window.webkitURL;
    imgLoader.setAttribute('crossOrigin', 'anonymous');
    imgLoader.src = _URL.createObjectURL(file);

    return promise;
  },

  validateImage(image: HTMLInputElement, options?: { minWidth?: number; minHeight?: number; maxWidth?: number; maxHeight?: number }): Promise<string> {
    return new Promise((resolve, reject) => {
      const defaults = {
        minWidth: Parse.Config.current().get("imageMinWidthHeight"),
        minHeight: Parse.Config.current().get("imageMinWidthHeight"),
        maxWidth: Parse.Config.current().get("imageMaxWidthHeight"),
        maxHeight: Parse.Config.current().get("imageMaxWidthHeight")
      };

      const appliedOptions = {
        minWidth: options?.minWidth || defaults.minWidth,
        minHeight: options?.minHeight || defaults.minHeight,
        maxWidth: options?.maxWidth || defaults.maxWidth,
        maxHeight: options?.maxHeight || defaults.maxHeight
      };

      const file = image.files?.[0];
      if (file) {
        const url = file.name;
        const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
        if (ext === "png" || ext === "jpeg" || ext === "jpg") {
          const _URL = window.URL || window.webkitURL;
          const newImage = new Image();

          newImage.onload = () => {
            if (newImage.width >= appliedOptions.minWidth &&
              newImage.width <= appliedOptions.maxWidth &&
              newImage.height >= appliedOptions.minHeight &&
              newImage.height <= appliedOptions.maxHeight) {
              resolve(newImage.src);
            } else {
              reject({
                code: "901",
                message: `Image height and width must be between ${appliedOptions.minWidth}x${appliedOptions.minHeight} to ${appliedOptions.maxWidth}x${appliedOptions.maxHeight}.`
              });
            }
          };

          newImage.onerror = () => {
            reject({
              code: "904",
              message: "Image could not be loaded."
            });
          };

          newImage.src = _URL.createObjectURL(file);
        } else {
          reject({
            code: "902",
            message: "Image is not a valid file type. Must be a png or jpg."
          });
        }
      } else {
        reject({
          code: "903",
          message: "No image selected."
        });
      }
    });
  },


  validateCSV: function (file: { files: any[]; }) {
    const promise = Promise;
    const csv = file.files[0];
    if (csv) {
      const url = csv.name;
      const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
      if (ext == "csv") {
        return promise
      }
      else {
        return promise.reject({ code: "902", message: "Not a valid file type. should be a csv file" });
      }
    }
    return promise;
  },

  showModal(headerHTML: string, bodyHTML: string, cancelButtonText?: string | undefined, actionButtonText?: string | null | undefined, actionFunc?: any): any {
    const self = this;
    const modalWrapper: any = document.getElementById('modal-wrapper1');
    modalWrapper.innerHTML = this.generateModalHTML(headerHTML, bodyHTML, cancelButtonText, actionButtonText);

    const modal: any = document.getElementById('modal');
    const modalBackdrop: any = document.getElementById('modal-backdrop');
    modal.querySelector('.modal-title').innerHTML = headerHTML;
    modal.querySelector('.modal-body').innerHTML = bodyHTML;

    const cancelButton = modal.querySelector('#modal_cancel');
    cancelButton.textContent = cancelButtonText || "Close";
    cancelButton.addEventListener('click', function () {
      self.hideModal(modal);
      modalBackdrop.remove();
      modal.remove();
    });

    const actionButton = modal.querySelector('#modal_action');
    if (actionButtonText != null) {
      actionButton.textContent = actionButtonText;
      actionButton.addEventListener('click', function (e: any) {
        if (actionFunc && actionFunc(e) !== false) {
          self.hideModal(modal);
          modal.remove();
        }
      });
    }

    modal.classList.add('show');
    return modal;
  },

  hideModal(modal: { classList: { remove: (arg0: string) => void; }; }) {
    modal.classList.remove('show');
  },

  generateModalHTML(headerHTML: string, bodyHTML: string, cancelButtonText: string | undefined, actionButtonText: string | null | undefined) {
    const cancelButtonHTML = '<button type="button" class="btn btn-secondary" data-dismiss="modal" id="modal_cancel">' + (cancelButtonText || "Close") + '</button>';
    const actionButtonHTML = actionButtonText ? '<button type="button" class="btn btn-primary" id="modal_action">' + actionButtonText + '</button>' : '';

    return `
  <div class="modal-backdrop" id="modal-backdrop"></div>
    <div class="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalLabel">${headerHTML}</h5>
          </div>
          <div class="modal-body">${bodyHTML}</div>
          <div class="modal-footer">
            ${cancelButtonHTML}
            ${actionButtonHTML}
          </div>
        </div>
      </div>
    </div>
  `;
  },


  showVueModal: function (vueEl: any, modalId: any) {
    const modalElement: any = $('#modal');
    if (vueEl.$bvModal) {
      vueEl.$bvModal.show(modalId);
    } else {
      $("#modal-wrapper").html(globalWindow.JST.modal);
      $('#modal .modal-dialog').html(vueEl);
      modalElement.modal('show');
      return modalElement;
    }
  },

  closeVueModal: function () {
    const modalElement: any = $('#modal');
    modalElement.modal('hide');
  },

  getDateRange: function (models: any) {
    const range: any = { "start": null, "end": null };
    _.each(models, function (model) {
      const day = moment(model.createdAt);
      range.start = range.start ? moment.min(day, range.start) : day;
      range.end = range.end ? moment.max(day, range.end) : day;
    });
    return range;
  },

  quantize: function (filters: any, models: string | any[]) {

    const buckets: any = {};
    if (models && models.length) {
      const range = filters ? filters : this.getDateRange(models);
      const date = moment(range.start).startOf('d');
      const endDate = moment(range.end).endOf('d');
      for (; date.isBefore(endDate); date.add(1, 'd')) {
        buckets[date.format("MMM Do")] = [];
      }
      _.each(models, function (order) {
        const day = moment(order.createdAt).format("MMM Do");
        if (day in buckets) {
          buckets[day].push(order);
        }
      });
      return buckets;
    }
  },

  abbrNum: function (number: any, decPlaces: number, money: any) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // any money less than 1000, we do not abbreviate
    if (money && number < 1000) {
      return paidutils.formatMoney(number);
    }

    // Enumerate number abbreviations
    const abbrev = ["k", "m", "b", "t"];

    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {

      // Convert array index to "1000", "1000000", etc
      const size = Math.pow(10, (i + 1) * 3);

      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round(number * decPlaces / size) / decPlaces;

        // Handle special case where we round up to the next abbreviation
        if ((number == 1000) && (i < abbrev.length - 1)) {
          number = 1;
          i++;
        }

        // Add the letter for the abbreviation
        number += abbrev[i];

        // We are done... stop
        break;
      }
    }

    return money ? this.getCurrencySymbol() + number : number;
  },

  canOrderBeMutated: function (order: { className: any; collection: any; cancelled: any; }) {
    if (order?.className && order.className !== "Order") {
      throw "invalid parameter passed into canOrderBeMutated need to pass in parse Order object";
    }

    if (!order.collection && !order.cancelled) {
      return true;
    }
    return false;
  },

  isZeroPaymentOrder: function (order: { amountPaid: string | undefined; }) {
    if (order.amountPaid !== undefined) {
      return parseFloat(order.amountPaid) == 0;
    }
    return false;
  },

  isFullyPaidOrder: function (order: { amountPaid: string; totalCost: string; }) {
    if (order.amountPaid && order.totalCost) {
      return parseFloat(order.amountPaid) >= parseFloat(order.totalCost);
    }

    return false;
  },

  canAdminCancelOrder: function (orders: any) {
    let ordersWithoutPayments = 0;
    _.each(orders, function (order) {
      if (paidutils.isZeroPaymentOrder(order)) {
        ordersWithoutPayments++;
        return false;
      }
    });
    return ordersWithoutPayments > 0;
  },

  //  TODO: remove ? as this functionality duplicated in utils.js
  getMealsArray: function () {
    const mealsArray = Parse.Config.current().get("mealsArray");
    const defaultMeals = [
      "Meat",
      "Vegetarian"
    ];

    return mealsArray && mealsArray.length > 0 ? mealsArray : defaultMeals;
  },


  //  TODO: remove ? as this functionality duplicated in utils.js
  getMealName: function (meal: string | number) {
    let name = "Unknown";
    meal = parseInt(meal as string);
    const mealsArray = paidutils.getMealsArray();
    if (mealsArray.length > meal) {
      name = mealsArray[meal];
    }

    return name;
  },

  isPossibleToOrderItemsPayments: function (orders: string | any[]) {
    if (orders.length > 1) {
      //is it possible to make a move?
      //are there any payments?
      //is there a costume that is not paid off fully
      let newStyleOrders = 0;
      _.each(orders, function (order) {
        if (paidutils.canMoveOrderItem(order)) {
          newStyleOrders++;
        }
      });

      const canGroupOrders = (newStyleOrders > 0);
      return canGroupOrders;
    }
    return false;
  },

  canMoveOrderItem: function (order: { get: (arg0: string) => any; }) {
    return !order.get('package'); //only orders with order items an be grouped
  },

  restoreColumnState: function (gridApi: { columnModel: { columnApi: { setColumnState: (arg0: any) => void; }; }; }, tableKey: string) {
    const savedColumnState = localStorage.getItem(tableKey);
    if (savedColumnState && gridApi) {
      const columnState = JSON.parse(savedColumnState);
      gridApi.columnModel.columnApi.setColumnState(columnState);
    }
  },

  saveColumnState: function (gridApi: { columnModel: { columnApi: { getColumnState: () => any; }; }; }, tableKey: string) {
    if (gridApi) {
      const columnState = gridApi.columnModel.columnApi.getColumnState();
      localStorage.setItem(tableKey, JSON.stringify(columnState));
    }
  },

  isPossibleToMovePayments: function (orders: string | any[]) {
    if (orders.length > 1) {
      //is it possible to make a move?
      //are there any payments?
      //is there a costume that is not paid off fully
      let ordersWithPayments = 0;
      let ordersFullyPaid = 0;
      _.each(orders, function (order) {
        if (!paidutils.isZeroPaymentOrder(order)) {
          ordersWithPayments++;
          if (paidutils.isFullyPaidOrder(order)) {
            ordersFullyPaid++;
          }
        }
      });

      let canMovePayments = (ordersWithPayments > 0);
      canMovePayments &&= (ordersFullyPaid != orders.length);
      return canMovePayments;
    }
    return false;
  },

  getParsePointer: function (className: any, objectId: any) {
    return {
      __type: 'Pointer',
      className: className,
      objectId: objectId
    }
  },

  getOrdersForSeasonQuery: function (seasonId: any) {
    const SeasonObj = Parse.Object.extend("Season");
    const querySeason = new Parse.Query(SeasonObj);
    querySeason.equalTo("objectId", seasonId);

    const PresentationObj = Parse.Object.extend("Presentation");
    const queryPresentation = new Parse.Query(PresentationObj);
    queryPresentation.matchesQuery("season", querySeason);

    const SectionObj = Parse.Object.extend("Section");
    const querySection = new Parse.Query(SectionObj);
    querySection.matchesQuery("presentation", queryPresentation);

    const PackageObj = Parse.Object.extend("Package");
    const queryPackage = new Parse.Query(PackageObj);
    queryPackage.matchesQuery("section", querySection);

    const OrderItemObj = Parse.Object.extend("OrderItem");
    const queryOrderItem = new Parse.Query(OrderItemObj);
    queryOrderItem.matchesQuery("package", queryPackage);

    const OrderObj = Parse.Object.extend("Order");
    const queryNewStyleOrder = new Parse.Query(OrderObj);
    queryNewStyleOrder.matchesKeyInQuery("objectId", "order.objectId", queryOrderItem);

    return queryNewStyleOrder;
  },

  setAnalyticsPage: function (route: any, item_id: any) {
    if (globalWindow.analytics) {
      globalWindow.analytics.page(route, {
        item_id: item_id
      });
    }
  },

  setAnalyticsUser: function (user: { id: any; get: (arg0: string) => string; }) {
    if (globalWindow.analytics && user && user.id) {
      globalWindow.analytics.identify(user.id, {
        name: user.get('firstName') + ' ' + user.get('lastName'),
        email: user.get('email'),
        env: globalWindow.Target.desc,
        server: globalWindow.Target.server,
        gender: paidutils.getUserGenderName(user.get('gender')),
        roles: user.get('roles'),
        country: user.get('country')
      });
    }
  },

  analyticsTrack: function (event: any, props: any) {
    if (globalWindow.analytics) {
      globalWindow.analytics.track(event, props);
    }
  },

  attachZendesk: function () {
    globalWindow.zESettings = {
      webWidget: {
        zIndex: 5000
      }
    };
    const script = document.createElement("script");
    script.setAttribute("src", "https://static.zdassets.com/ekr/snippet.js?key=36b13c11-530d-4684-b93a-3e8bc488eab8");
    script.setAttribute("id", "ze-snippet");
    document.getElementsByTagName("head")[0].appendChild(script);
  },

  attachIntercom: function () {

    Parse.Config.get().then(function (config) {
      const intercomAppId = config.get("intercomAppId");
      if (!intercomAppId) {
        return;
      }

      function bootIntercom() {
        const user: any = Parse.User.current();
        let userImage = paidutils.getNoImageURL();
        if (user.get("profileImageThumbnail")) {
          userImage = user.get("profileImageThumbnail").url();
        }

        globalWindow.Intercom('boot', {
          app_id: intercomAppId,
          name: user.get("firstName") + " " + user.get("lastName"),
          email: user.getEmail(),
          user_id: user.id,
          phone: user.get("phone"),
          created_at: moment(user.createdAt).format("X"),
          "band_name": config.get("bandName"),
          "order_count": user.get("orderCount"),
          "roles": user.get("roles"),
          "gender": paidutils.getUserGenderName(user.get("gender")),
          "country": paidutils.getCountryFromCode(user.get("country")),
          "address": user.get("address"),
          "profile": config.get("portalURL") + "/#users/" + user.id,
          "stripe_customer_id": user.get("stripeCustomerId"),
          "avatar": {
            type: "avatar",
            image_url: userImage
          }
        });
      }

      const w = globalWindow;
      const ic = w.Intercom;
      if (typeof ic === "function") {
        ic('reattach_activator');
        // ic('update', intercomSettings);
        bootIntercom();
      } else {
        const d = document;
        const i: any = function () {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments)
        };
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args)
        };
        w.Intercom = i;

        // eslint-disable-next-line no-inner-declarations
        function l() {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + intercomAppId;
          const x: any = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
          return s;
        }
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }

        if (document.readyState === "complete") {
          l().onload = function () {
            bootIntercom();
          }
        }
      }
    });
  },

  confirmStillLoggedIn: function () {
    const queryUser = new Parse.Query(Portal.Models.User as string);
    const user = Parse.User.current();
    if (!user) {
      return;
    }
    queryUser.get(user.id).then(function (user) {
      //check for TOS acceptance
      Promise.all([user.fetch(), Parse.Cloud.run('termsOfServiceVersion')]).then(function (responses) {
        const user = responses[0];
        const version = responses[1];
        if (user.get('termsOfServiceAgreed') && user.get('termsOfServiceAgreed') < version) {
          globalRouter.push("termsOfService");
        }
      });
    }).catch(function (error) {
      if (error.code === 209) {  //expired session token.  LOGOUT
        Parse.User.logOut();
        window.location.reload();
      }
    })
  },

  sanitizeFileName(fileName: string) {
    // Replace special characters with underscores
    const sanitizedFileName = fileName.replace(/[^\w.-]/g, '_');
    // Remove consecutive underscores
    const cleanedFileName = sanitizedFileName.replace(/_+/g, '_');

    // Extract file extension
    const fileParts = cleanedFileName.split('.');
    const fileExtension = fileParts.pop();
    let baseFileName = fileParts.join('.');
    // Also remove all the . from baseFileName
    baseFileName = baseFileName.replace(/\./g, '');
    baseFileName = baseFileName.substring(0, MAX_FILENAME_LENGTH);
    const completeFileName = `${baseFileName}.${fileExtension}`;
    return { baseFileName, fileExtension, fileName: completeFileName };
  },

  getRowStyleForOrderTable(data: { cancelled: boolean; disputed: boolean; isReSold: boolean; isForReSale: boolean; collectionStatus: string; }) {
    const COLLECTION_STATUS = { COLLECTED: 'COLLECTED', PARTIALLY_COLLECTED: 'PARTIALLY_COLLECTED', NOT_COLLECTED: 'NOT_COLLECTED' };
    if (data) {
      if (data.cancelled === true || data.disputed === true) {
        return { background: "#fff3f3" };
      } else if (
        data.isReSold === true ||
        data.isForReSale === true
      ) {
        return { background: "#fdfde6" };
      } else if (data.collectionStatus === COLLECTION_STATUS.COLLECTED) {
        return { background: "#e8ffed" };
      } else if (data.collectionStatus === COLLECTION_STATUS.PARTIALLY_COLLECTED) {
        return { background: "#ffe99a" };
      } else {
        return { background: "transparent" };
      }
    } else {
      return;
    }
  },

  saveDataByOrderId(orderId: string | number, data: any, key: string) {
    let existingData: any = sessionStorage.getItem(key) || {};
    if (typeof existingData === 'string') {
      existingData = JSON.parse(existingData);
    }
    existingData[orderId] = data;
    sessionStorage.setItem(key, JSON.stringify(existingData));
  },

  getDataByOrderId(orderId: string | number, key: string) {
    const existingData = sessionStorage.getItem(key);
    if (existingData) {
      const dataObject = JSON.parse(existingData);
      return dataObject[orderId] || null;
    }
    return null;
  },

  async readFileAsBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.onload = () => resolve(reader?.result?.split(',')[1]); // Extract base64 content
      reader.onerror = (error: any) => reject(error);
      reader.readAsDataURL(file);
    });
  },

  base64ToFile(base64Data: string, fileName: string, mimeType: any) {
    const byteCharacters = atob(base64Data);
    const byteArrays: any = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  },

  generateCombinations(optionSets: any) {
    function generateVariants(arrays: string | any[], index: number, currentVariant: any[], variants: any[]) {
      if (index === arrays.length) {
        variants.push(currentVariant.slice());
        return;
      }

      for (let i = 0; i < arrays[index].length; i++) {
        currentVariant[index] = arrays[index][i];
        generateVariants(arrays, index + 1, currentVariant, variants);
      }
    }

    const variants: never[] = [];
    generateVariants(optionSets, 0, [], variants);
    return variants;
  },

  pluralise(count: number, value: any) {
    return count !== 1 ? `${value}s` : `${value}`
  },

  isCommittee(user: { get: (arg0: string) => never[]; }) {
    const rolesArray = user.get("roles") || []
    const booleanArray = rolesArray.map(function (role: string) { return role.toLowerCase().startsWith("committee") });
    return _.some(booleanArray)
  },

  getStringifiedSegmentCompositionName(segmentComposition: any[]) {
    return segmentComposition.map(segment => {
      const segmentOperator = segment.operator || '';
      if (segment.segment) {
        return `<span class="segment-name">${segment.segment.attributes ? segment.segment.get('name') : segment.segment.name}</span> <strong>${segmentOperator}</strong>`;
      } else {
        return '';
      }
    }).join(' ');

  },
  getCountryFromCode: function (code: string) {
    code = code ? code.toUpperCase() : '';
    const country: any = _.find(this._list, { code: code });
    return country ? country.name : "Unknown";
  },

  getList: function (selectedCode: any) {
    let list: any = [];
    if (selectedCode) {
      _.each(this._list, function (country) {
        if (country.code == selectedCode) {
          list.push({
            "name": country.name,
            "code": country.code,
            "selected": true
          });
        } else {
          list.push(country);
        }
      });
    } else {
      list = this._list;
    }
    return list;
  },
  _list: [
    { "name": "Trinidad and Tobago", "code": "TT" },
    { "name": "United States", "code": "US" },
    { "name": "United Kingdom", "code": "GB" },
    { "name": "Afghanistan", "code": "AF" },
    { "name": "Åland Islands", "code": "AX" },
    { "name": "Albania", "code": "AL" },
    { "name": "Algeria", "code": "DZ" },
    { "name": "American Samoa", "code": "AS" },
    { "name": "Andorra", "code": "AD" },
    { "name": "Angola", "code": "AO" },
    { "name": "Anguilla", "code": "AI" },
    { "name": "Antarctica", "code": "AQ" },
    { "name": "Antigua and Barbuda", "code": "AG" },
    { "name": "Argentina", "code": "AR" },
    { "name": "Armenia", "code": "AM" },
    { "name": "Aruba", "code": "AW" },
    { "name": "Australia", "code": "AU" },
    { "name": "Austria", "code": "AT" },
    { "name": "Azerbaijan", "code": "AZ" },
    { "name": "Bahamas", "code": "BS" },
    { "name": "Bahrain", "code": "BH" },
    { "name": "Bangladesh", "code": "BD" },
    { "name": "Barbados", "code": "BB" },
    { "name": "Belarus", "code": "BY" },
    { "name": "Belgium", "code": "BE" },
    { "name": "Belize", "code": "BZ" },
    { "name": "Benin", "code": "BJ" },
    { "name": "Bermuda", "code": "BM" },
    { "name": "Bhutan", "code": "BT" },
    { "name": "Bolivia", "code": "BO" },
    { "name": "Bosnia and Herzegovina", "code": "BA" },
    { "name": "Botswana", "code": "BW" },
    { "name": "Bouvet Island", "code": "BV" },
    { "name": "Brazil", "code": "BR" },
    { "name": "British Indian Ocean Territory", "code": "IO" },
    { "name": "Brunei Darussalam", "code": "BN" },
    { "name": "Bulgaria", "code": "BG" },
    { "name": "Burkina Faso", "code": "BF" },
    { "name": "Burundi", "code": "BI" },
    { "name": "Cambodia", "code": "KH" },
    { "name": "Cameroon", "code": "CM" },
    { "name": "Canada", "code": "CA" },
    { "name": "Cape Verde", "code": "CV" },
    { "name": "Cayman Islands", "code": "KY" },
    { "name": "Central African Republic", "code": "CF" },
    { "name": "Chad", "code": "TD" },
    { "name": "Chile", "code": "CL" },
    { "name": "China", "code": "CN" },
    { "name": "Christmas Island", "code": "CX" },
    { "name": "Cocos (Keeling) Islands", "code": "CC" },
    { "name": "Colombia", "code": "CO" },
    { "name": "Comoros", "code": "KM" },
    { "name": "Congo", "code": "CG" },
    { "name": "Congo, The Democratic Republic of the", "code": "CD" },
    { "name": "Cook Islands", "code": "CK" },
    { "name": "Costa Rica", "code": "CR" },
    { "name": "Cote D'Ivoire", "code": "CI" },
    { "name": "Croatia", "code": "HR" },
    { "name": "Cuba", "code": "CU" },
    { "name": "Cyprus", "code": "CY" },
    { "name": "Czech Republic", "code": "CZ" },
    { "name": "Denmark", "code": "DK" },
    { "name": "Djibouti", "code": "DJ" },
    { "name": "Dominica", "code": "DM" },
    { "name": "Dominican Republic", "code": "DO" },
    { "name": "Ecuador", "code": "EC" },
    { "name": "Egypt", "code": "EG" },
    { "name": "El Salvador", "code": "SV" },
    { "name": "Equatorial Guinea", "code": "GQ" },
    { "name": "Eritrea", "code": "ER" },
    { "name": "Estonia", "code": "EE" },
    { "name": "Ethiopia", "code": "ET" },
    { "name": "Falkland Islands (Malvinas)", "code": "FK" },
    { "name": "Faroe Islands", "code": "FO" },
    { "name": "Fiji", "code": "FJ" },
    { "name": "Finland", "code": "FI" },
    { "name": "France", "code": "FR" },
    { "name": "French Guiana", "code": "GF" },
    { "name": "French Polynesia", "code": "PF" },
    { "name": "French Southern Territories", "code": "TF" },
    { "name": "Gabon", "code": "GA" },
    { "name": "Gambia", "code": "GM" },
    { "name": "Georgia", "code": "GE" },
    { "name": "Germany", "code": "DE" },
    { "name": "Ghana", "code": "GH" },
    { "name": "Gibraltar", "code": "GI" },
    { "name": "Greece", "code": "GR" },
    { "name": "Greenland", "code": "GL" },
    { "name": "Grenada", "code": "GD" },
    { "name": "Guadeloupe", "code": "GP" },
    { "name": "Guam", "code": "GU" },
    { "name": "Guatemala", "code": "GT" },
    { "name": "Guernsey", "code": "GG" },
    { "name": "Guinea", "code": "GN" },
    { "name": "Guinea-Bissau", "code": "GW" },
    { "name": "Guyana", "code": "GY" },
    { "name": "Haiti", "code": "HT" },
    { "name": "Heard Island and Mcdonald Islands", "code": "HM" },
    { "name": "Holy See (Vatican City State)", "code": "VA" },
    { "name": "Honduras", "code": "HN" },
    { "name": "Hong Kong", "code": "HK" },
    { "name": "Hungary", "code": "HU" },
    { "name": "Iceland", "code": "IS" },
    { "name": "India", "code": "IN" },
    { "name": "Indonesia", "code": "ID" },
    { "name": "Iran, Islamic Republic Of", "code": "IR" },
    { "name": "Iraq", "code": "IQ" },
    { "name": "Ireland", "code": "IE" },
    { "name": "Isle of Man", "code": "IM" },
    { "name": "Israel", "code": "IL" },
    { "name": "Italy", "code": "IT" },
    { "name": "Jamaica", "code": "JM" },
    { "name": "Japan", "code": "JP" },
    { "name": "Jersey", "code": "JE" },
    { "name": "Jordan", "code": "JO" },
    { "name": "Kazakhstan", "code": "KZ" },
    { "name": "Kenya", "code": "KE" },
    { "name": "Kiribati", "code": "KI" },
    { "name": "Democratic People's Republic of Korea", "code": "KP" },
    { "name": "Korea, Republic of", "code": "KR" },
    { "name": "Kosovo", "code": "XK" },
    { "name": "Kuwait", "code": "KW" },
    { "name": "Kyrgyzstan", "code": "KG" },
    { "name": "Lao People's Democratic Republic", "code": "LA" },
    { "name": "Latvia", "code": "LV" },
    { "name": "Lebanon", "code": "LB" },
    { "name": "Lesotho", "code": "LS" },
    { "name": "Liberia", "code": "LR" },
    { "name": "Libyan Arab Jamahiriya", "code": "LY" },
    { "name": "Liechtenstein", "code": "LI" },
    { "name": "Lithuania", "code": "LT" },
    { "name": "Luxembourg", "code": "LU" },
    { "name": "Macao", "code": "MO" },
    { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
    { "name": "Madagascar", "code": "MG" },
    { "name": "Malawi", "code": "MW" },
    { "name": "Malaysia", "code": "MY" },
    { "name": "Maldives", "code": "MV" },
    { "name": "Mali", "code": "ML" },
    { "name": "Malta", "code": "MT" },
    { "name": "Marshall Islands", "code": "MH" },
    { "name": "Martinique", "code": "MQ" },
    { "name": "Mauritania", "code": "MR" },
    { "name": "Mauritius", "code": "MU" },
    { "name": "Mayotte", "code": "YT" },
    { "name": "Mexico", "code": "MX" },
    { "name": "Micronesia, Federated States of", "code": "FM" },
    { "name": "Moldova, Republic of", "code": "MD" },
    { "name": "Monaco", "code": "MC" },
    { "name": "Mongolia", "code": "MN" },
    { "name": "Montenegro", "code": "ME" },
    { "name": "Montserrat", "code": "MS" },
    { "name": "Morocco", "code": "MA" },
    { "name": "Mozambique", "code": "MZ" },
    { "name": "Myanmar", "code": "MM" },
    { "name": "Namibia", "code": "NA" },
    { "name": "Nauru", "code": "NR" },
    { "name": "Nepal", "code": "NP" },
    { "name": "Netherlands", "code": "NL" },
    { "name": "Netherlands Antilles", "code": "AN" },
    { "name": "New Caledonia", "code": "NC" },
    { "name": "New Zealand", "code": "NZ" },
    { "name": "Nicaragua", "code": "NI" },
    { "name": "Niger", "code": "NE" },
    { "name": "Nigeria", "code": "NG" },
    { "name": "Niue", "code": "NU" },
    { "name": "Norfolk Island", "code": "NF" },
    { "name": "Northern Mariana Islands", "code": "MP" },
    { "name": "Norway", "code": "NO" },
    { "name": "Oman", "code": "OM" },
    { "name": "Pakistan", "code": "PK" },
    { "name": "Palau", "code": "PW" },
    { "name": "Palestinian Territory, Occupied", "code": "PS" },
    { "name": "Panama", "code": "PA" },
    { "name": "Papua New Guinea", "code": "PG" },
    { "name": "Paraguay", "code": "PY" },
    { "name": "Peru", "code": "PE" },
    { "name": "Philippines", "code": "PH" },
    { "name": "Pitcairn", "code": "PN" },
    { "name": "Poland", "code": "PL" },
    { "name": "Portugal", "code": "PT" },
    { "name": "Puerto Rico", "code": "PR" },
    { "name": "Qatar", "code": "QA" },
    { "name": "Reunion", "code": "RE" },
    { "name": "Romania", "code": "RO" },
    { "name": "Russian Federation", "code": "RU" },
    { "name": "Rwanda", "code": "RW" },
    { "name": "Saint Helena", "code": "SH" },
    { "name": "Saint Kitts and Nevis", "code": "KN" },
    { "name": "Saint Lucia", "code": "LC" },
    { "name": "Saint Pierre and Miquelon", "code": "PM" },
    { "name": "Saint Vincent and the Grenadines", "code": "VC" },
    { "name": "Samoa", "code": "WS" },
    { "name": "San Marino", "code": "SM" },
    { "name": "Sao Tome and Principe", "code": "ST" },
    { "name": "Saudi Arabia", "code": "SA" },
    { "name": "Senegal", "code": "SN" },
    { "name": "Serbia", "code": "RS" },
    { "name": "Seychelles", "code": "SC" },
    { "name": "Sierra Leone", "code": "SL" },
    { "name": "Singapore", "code": "SG" },
    { "name": "Slovakia", "code": "SK" },
    { "name": "Slovenia", "code": "SI" },
    { "name": "Solomon Islands", "code": "SB" },
    { "name": "Somalia", "code": "SO" },
    { "name": "South Africa", "code": "ZA" },
    { "name": "South Georgia and the South Sandwich Islands", "code": "GS" },
    { "name": "Spain", "code": "ES" },
    { "name": "Sri Lanka", "code": "LK" },
    { "name": "Sudan", "code": "SD" },
    { "name": "Suriname", "code": "SR" },
    { "name": "Svalbard and Jan Mayen", "code": "SJ" },
    { "name": "Swaziland", "code": "SZ" },
    { "name": "Sweden", "code": "SE" },
    { "name": "Switzerland", "code": "CH" },
    { "name": "Syrian Arab Republic", "code": "SY" },
    { "name": "Taiwan", "code": "TW" },
    { "name": "Tajikistan", "code": "TJ" },
    { "name": "Tanzania, United Republic of", "code": "TZ" },
    { "name": "Thailand", "code": "TH" },
    { "name": "Timor-Leste", "code": "TL" },
    { "name": "Togo", "code": "TG" },
    { "name": "Tokelau", "code": "TK" },
    { "name": "Tonga", "code": "TO" },
    { "name": "Tunisia", "code": "TN" },
    { "name": "Turkey", "code": "TR" },
    { "name": "Turkmenistan", "code": "TM" },
    { "name": "Turks and Caicos Islands", "code": "TC" },
    { "name": "Tuvalu", "code": "TV" },
    { "name": "Uganda", "code": "UG" },
    { "name": "Ukraine", "code": "UA" },
    { "name": "United Arab Emirates", "code": "AE" },
    { "name": "United States Minor Outlying Islands", "code": "UM" },
    { "name": "Uruguay", "code": "UY" },
    { "name": "Uzbekistan", "code": "UZ" },
    { "name": "Vanuatu", "code": "VU" },
    { "name": "Venezuela", "code": "VE" },
    { "name": "Viet Nam", "code": "VN" },
    { "name": "Virgin Islands, British", "code": "VG" },
    { "name": "Virgin Islands, U.S.", "code": "VI" },
    { "name": "Wallis and Futuna", "code": "WF" },
    { "name": "Western Sahara", "code": "EH" },
    { "name": "Yemen", "code": "YE" },
    { "name": "Zambia", "code": "ZM" },
    { "name": "Zimbabwe", "code": "ZW" }
  ],

  extractId(id: string) {
    if (id && id.includes("$")) {
      return id.split("$")[1];
    }
  },

};  // return paidutils object

//write IPaidUtils for all above functions in paidutils object
type IPaidUtils = typeof paidutils;

export default paidutils;
export type { IPaidUtils };