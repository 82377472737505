<template>
  <!-- Left side column. contains the logo and sidebar -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- sidebar: style can be found in sidebar.less -->
    <div
      class="sidebar"
      data-widget="tree"
    >
      <!-- Sidebar user panel (optional) -->
      <div class="m-4">
        <a
          class="branding"
          href="https://www.playmas.app"
          target="_blank"
        >
          <img src="../../images/playmas-logo-light.png">
        </a>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <template v-if="isOnlySectionLeader || isBothSectionLeaderAndCashier">
            <li class="nav-header">
              SECTION LEADER
            </li>
            <li
              id="nav-dashboard"
              class="nav-item"
            >
              <router-link
                to="/dashboard"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="pulse"
                />
                <p>Section Leader Dashboard</p>
              </router-link>
            </li>
            <li
              id="nav-orders"
              class="nav-item"
            >
              <router-link
                to="/orders"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="cart"
                />
                <p>Orders</p>
              </router-link>
            </li>
            <li
              id="nav-orderItems"
              class="nav-item"
            >
              <router-link
                to="/orderitems"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="logo-buffer"
                />
                <p>Order Items</p>
              </router-link>
            </li>
            <li
              v-if="showContact"
              id="nav-contact"
              class="nav-item"
            >
              <router-link
                to="/contact"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="chatbox"
                />
                <p>Mas Link</p>
              </router-link>
            </li>
            <li
              id="nav-users"
              class="nav-item"
            >
              <router-link
                to="/users"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="people"
                />
                <p>Users</p>
              </router-link>
            </li>
            <li
              id="nav-discounts"
              class="nav-item"
            >
              <router-link
                to="/discounts"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="pricetag"
                />
                <p>Discounts</p>
              </router-link>
            </li>
          </template>

          <template v-if="isOperations">
            <li class="nav-header">
              GENERAL
            </li>
          </template>

          <template v-if="allowSidebarViewForAdminAndFinance">
            <li
              id="nav-dashboard"
              class="nav-item"
            >
              <router-link
                to="/dashboard"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="pulse"
                />
                <p>Dashboard</p>
              </router-link>
            </li>
            <li
              id="nav-dailyoverview"
              class="nav-item"
            >
              <router-link
                to="/dailyoverview"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="calendar"
                />
                <p>Daily Overview</p>
              </router-link>
            </li>
          </template>

          <template v-if="allowSidebarViewForOperationsAndFinance">
            <li
              id="nav-users"
              class="nav-item"
            >
              <router-link
                to="/users"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="people"
                />
                <p>Users</p>
              </router-link>
            </li>
            <li
              id="nav-orders"
              class="nav-item"
            >
              <router-link
                to="/orders"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="cart"
                />
                <p>Orders</p>
              </router-link>
            </li>
            <li
              id="nav-orderItems"
              class="nav-item"
            >
              <router-link
                to="/orderitems"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="logo-buffer"
                />
                <p>Order Items</p>
              </router-link>
            </li>
            <li
              id="nav-discounts"
              class="nav-item"
            >
              <router-link
                to="/discounts"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="pricetag"
                />
                <p>Discounts</p>
              </router-link>
            </li>
          </template>

          <template v-if="allowSidebarViewForAdminAndFinance">
            <li
              id="nav-distribution"
              class="nav-item"
            >
              <router-link
                to="/distribution"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="qr-code"
                />
                <p>Distribution</p>
              </router-link>
            </li>
          </template>

          <li class="nav-header">
            OPERATIONS
          </li>
          <li
            id="nav-events"
            class="nav-item"
          >
            <router-link
              to="/events"
              class="nav-link"
            >
              <ion-icon
                class="nav-icon"
                name="calendar"
              />
              <p>Events</p>
            </router-link>
          </li>

          <template>
            <li
              v-if="allowSidebarViewForOperationsAndFinance && showContact"
              id="nav-contact"
              class="nav-item"
            >
              <router-link
                to="/contact"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="chatbox"
                />
                <p>Mas Link</p>
              </router-link>
            </li>
            <li
              v-if="showResale && isOperations"
              id="nav-resale"
              class="nav-item"
            >
              <router-link
                to="/resale"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="home"
                />
                <p>Resale</p>
              </router-link>
            </li>
            <li
              v-if="showRecommended && isOperations"
              id="nav-recommended"
              class="nav-item"
            >
              <router-link
                to="/recommended"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="flash"
                />
                <p>Recommendations</p>
              </router-link>
            </li>
            <!-- Depreciated -->
            <!-- <li class="nav-item" id="nav-inventory">
              <router-link to="/inventory" class="nav-link">
                <ion-icon class="nav-icon" name="barcode"></ion-icon>
                <p>Inventory</p>
              </router-link>
            </li> -->
          </template>

          <template v-if="isCashierOrRedemption || isBothSectionLeaderAndCashier">
            <li class="nav-header">
              CASHIER
            </li>
            <li
              id="nav-cashier"
              class="nav-item"
            >
              <router-link
                to="/cashier"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="calculator"
                />
                <p>Cashier</p>
              </router-link>
            </li>
          </template>

          <template v-if="isFinance">
            <li class="nav-header">
              FINANCIALS
            </li>

            <li
              v-if="showRemittance"
              id="nav-remittance"
              class="nav-item"
            >
              <router-link
                to="/remittance"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="logo-usd"
                />
                <p>Remittance Advice</p>
              </router-link>
            </li>
            <li
              id="nav-billing"
              class="nav-item"
            >
              <router-link
                to="/billing"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="receipt"
                />
                <p>Billing</p>
              </router-link>
            </li>
            <li
              id="nav-dispute"
              class="nav-item"
            >
              <router-link
                to="/dispute"
                class="nav-link"
              >
                <i class="fa fa-gavel nav-icon" />
                <p>Disputes</p>
              </router-link>
            </li>
            <li
              id="nav-reports"
              class="nav-item"
            >
              <router-link
                to="/reports"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="apps"
                />
                <p>Reports</p>
              </router-link>
            </li>
            <li
              id="nav-payments"
              class="nav-item"
            >
              <router-link
                to="/payments"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="cash"
                />
                <p>Payments</p>
              </router-link>
            </li>
          </template>

          <li class="nav-header">
            CONFIGURATION
          </li>
          <li
            id="nav-profile"
            class="nav-item"
          >
            <router-link
              to="/profile"
              class="nav-link"
            >
              <ion-icon
                class="nav-icon"
                name="person"
              />
              <p>Profile</p>
            </router-link>
          </li>
          <!-- // below actions are only available to admin. -->
          <template v-if="allowSidebarViewForAdminAndFinance">
            <li
              id="nav-settings"
              class="nav-item"
            >
              <router-link
                to="/settings"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="settings"
                />
                <p>Settings</p>
              </router-link>
            </li>
            <li
              id="nav-roles"
              class="nav-item"
            >
              <router-link
                to="/roles"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="lock-open"
                />
                <p>Role Management</p>
              </router-link>
            </li>
            <li
              v-if="isAdmin"
              id="nav-inviteUsers"
              class="nav-item"
            >
              <router-link
                to="/inviteUsers"
                class="nav-link"
              >
                <ion-icon
                  class="nav-icon"
                  name="download"
                />
                <p>Invite Users</p>
              </router-link>
            </li>
          </template>
        </ul>
        <!-- /.sidebar-menu -->
      </nav>
    </div>
    <!-- /.sidebar -->
  </aside>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Sidebar",
    data() {
    return {
      showRecommended: false,
      showContact: false,
      showRemittance: false,
      showResale: false,
    };
  },
  computed: {
    ...mapGetters("user", [
      "isOnlySectionLeader",
      "isAdmin",
      "isFinance",
      "isOperations",
      "isCashierOrRedemption",
      "isSectionLeader"
    ]),
    ...mapGetters("settings", ["enableGlobalAddons", "enableContact", "allowResale"]),
    isBothSectionLeaderAndCashier(){
      return (this.isSectionLeader && this.isCashierOrRedemption) && (!this.isAdmin && !this.isFinance && !this.isOperations);
    },  
    allowSidebarViewForAdminAndFinance () {
      return this.isAdmin || this.isFinance;
    },
    allowSidebarViewForOperationsAndFinance () {
      return this.isOperations || this.isFinance;
    },
  },
  async created() {
    await this.$store.dispatch("settings/getAppSettings");
    if (this.enableGlobalAddons) {
      this.showRecommended = true;
    }

    if (this.enableContact) {
      this.showContact = true;
    }

    if (this.allowResale) {
      this.showResale = true;
    }
  },
};
</script>
