import { round } from "lodash";
import moment from "moment";
import { MomentInput } from "moment";
import Vue from "vue";

// filters
Vue.filter("fullname", function (user: { get: (arg0: string) => any }) {
    try {
        const parseUser = user.get("user") || user;
        return `${parseUser.get("firstName")} ${parseUser.get("lastName")}`;
    } catch (err) {
        return "-";
    }
});

Vue.filter("name", function (parseObject: { get: (arg0: string) => any }) {
    try {
        return parseObject.get("name");
    } catch (err) {
        return "Unknown";
    }
});

Vue.filter("formatISODate", function (isoDate: MomentInput) {
    return moment(isoDate).format("D MMM YYYY");
});

Vue.filter("formatISODateTime", function (isoDateTime: MomentInput) {
    return moment(isoDateTime).format("D MMM YYYY h:mmA");
});

Vue.filter(
    "prettyMoment",
    function (moment: { format: (arg0: string) => any }) {
        return moment.format("D MMM YYYY");
    }
);

Vue.filter(
    "fullDateTimeMoment",
    function (moment: { format: (arg0: string) => any }) {
        return moment.format("D MMM YYYY h:mmA");
    }
);

Vue.filter("formatMoney", function (value: any, currency: any) {
    return paidutils.formatMoney(value, currency);
});

Vue.filter("toPercentage", function (value: number, total: number) {
    return `${((value * 100) / total).toFixed(1)}%`;
});
Vue.filter("roundTo2", function (value: any) {
    return round(value, 2).toFixed(2);
});

Vue.filter("pluralise", function (value: any, count: number) {
    return count !== 1 ? `${value}s` : `${value}`;
});

Vue.filter("package", function (orderItem: { get: (arg0: string) => any }) {
    try {
        return orderItem.get("package");
    } catch (err) {
        return null;
    }
});

Vue.filter(
    "section",
    function (orderItem: {
        get: (arg0: string) => {
            (): any;
            new (): any;
            get: { (arg0: string): any; new (): any };
        };
    }) {
        try {
            return orderItem.get("package").get("section");
        } catch (err) {
            return null;
        }
    }
);

Vue.filter(
    "presentation",
    function (orderItem: {
        get: (arg0: string) => {
            (): any;
            new (): any;
            get: {
                (arg0: string): {
                    (): any;
                    new (): any;
                    get: { (arg0: string): any; new (): any };
                };
                new (): any;
            };
        };
    }) {
        try {
            return orderItem.get("package").get("section").get("presentation");
        } catch (err) {
            return null;
        }
    }
);

Vue.filter("template", function (instance: { get: (arg0: string) => any }) {
    try {
        return instance.get("template");
    } catch (err) {
        return null;
    }
});

Vue.filter(
    "optionNameList",
    function (instance: { get: (arg0: string) => any }) {
        try {
            return instance.get("optionNameList");
        } catch (err) {
            return null;
        }
    }
);

Vue.filter(
    "image",
    function (parseObject: {
        get: (arg0: string) => { (): any; new (): any; _url: any };
    }) {
        try {
            return parseObject.get("image")._url;
        } catch (err) {
            return paidutils.getNoImageURL();
        }
    }
);
