export const DISCOUNT_TYPES = {
    DISCOUNT_PCT: 'DISCOUNT_PCT',
    DISCOUNT_FIXED: 'DISCOUNT_FIXED',
    ZERODOWNPAYMENT: 'ZERODOWNPAYMENT',
}

export const ENTITLEMENT_TYPES = {
    PURCHASE_RIGHT: 'PURCHASE_RIGHT',
};

export const COLLECTION_STATUS = [
    { name: 'COLLECTED', id: 'COLLECTED' },
    { name: 'PARTIALLY_COLLECTED', id: 'PARTIALLY_COLLECTED' },
    { name: 'NOT_COLLECTED', id: 'NOT_COLLECTED' },
];

export const COLLECTION_STATUS_MAP = {
    COLLECTED: 'COLLECTED',
    PARTIALLY_COLLECTED: 'PARTIALLY_COLLECTED',
    NOT_COLLECTED: 'NOT_COLLECTED',
};

export const APPLICATION_STATES = {
    REVIEWED: 'REVIEWED',
    NOT_REVIEWED: 'NOT_REVIEWED',
    DENIED: 'DENIED',
}

export const DATE_FORMATS = {
    'DD-MM-YYYY': 'DD-MM-YYYY',
    'MM-DD-YYYY': 'MM-DD-YYYY',
    'YYYY-MM-DD': 'YYYY-MM-DD',
};