import moment from 'moment';

export const Season = Parse.Object.extend("Season", {
  initialize: function () {
  },

  name: function () { return this.get("name"); },
  desc: function () { return this.get("desc"); },

  //createdAt is a top level member
  isSelling: function () { return this.get("isSelling"); },
  eventDate: function () { return moment(this.get("eventDate")).format("YYYY-MM-DD"); },
  salesStartDate: function () { return moment(this.get("salesStartDate")).format("YYYY-MM-DD"); },
  useMeals: function () { return this.get("useMeals"); },
  mealsArray: function () {
    let meals = this.get("mealsArray");
    if (!meals || meals.length <= 0) {
      meals = ["Meat", "Vegetarian"]
    }
    return meals
  },
  mealsAsKeyValues: function () {
    return paidutils.toKeyValueArray(this.mealsArray())
  },
  useReferrals: function () { return this.get("useReferrals"); },
  referralCodes: function () { return this.get("referralCodes"); },
  validateReferralCode: function (code: any) {
    return this.referralCodes().includes(code);
  },
});
