import OrderCollectionState from '../../type-defs/interfaces/Orders/collectionState'
import { ActionTree, ActionContext } from 'vuex'
import OrderService from '../../../services/OrderService'

const ds = OrderService.getInstance()

// initial state
const state = {
  signature: null,
  picFile: null,
  idFile: null,
  signatureUpload: null,
  picUpload: null,
  idUpload: null,
  collectionUserId: '',
  sendSMS: true
}

// getters
const getters = {
  signature: () => state.signature,
  picFile: () => state.picFile,
  idFile: () => state.idFile
}

// actions
const actions: ActionTree<OrderCollectionState, any> = {
  async uploadSignature({ commit, state }: ActionContext<OrderCollectionState, any>) {
    if(state.signature) {

      const response = await paidutils.uploadDataURL(state.signature as unknown as string, "png");
      commit('signatureUpload', response)
    }
  },
  async uploadPicFile({ commit, state }: ActionContext<OrderCollectionState, any>) {
    if(state.picFile) {

      const picData: any = await paidutils.resizeImageFromFile(state.picFile as unknown as File);
      const picURL = picData.toDataURL('image/jpeg', 0.8);

      const response = await paidutils.uploadDataURL(picURL);
      commit('picUpload', response)
    }
  },
  async uploadIdFile({ commit, state }: ActionContext<OrderCollectionState, any>) {
    if(state.idFile) {

      const idData: any = await paidutils.resizeImageFromFile(state.idFile as unknown as File);
      const idURL = idData.toDataURL('image/jpeg', 0.8);

      const response = await paidutils.uploadDataURL(idURL);
      commit('idUpload', response)
    }
  },
  async markAsCollected({ state }: ActionContext<OrderCollectionState, any>, orderId: string) {
    const collectionInfo = {
      order_id: orderId,
      collecting_user_id: state.collectionUserId,
      picture: state.picUpload,
      signature: state.signatureUpload,
      identification: state.idUpload,
      sms: state.sendSMS
    };
    await ds.markAsCollected(collectionInfo);
  },
  refreshState({ commit }: ActionContext<OrderCollectionState, any>) {
    commit('signature', null);
    commit('picFile', null);
    commit('idFile', null);
    commit('signatureUpload', null);
    commit('picUpload', null);
    commit('idUpload', null);
    commit('sendSMS', true);
  }
}

// mutations
const mutations = {
  signature(state: OrderCollectionState, signature: object) {
    state.signature = signature
  },
  picFile(state: OrderCollectionState, picFile: object) {
    state.picFile = picFile
  },
  idFile(state: OrderCollectionState, idFile: object) {
    state.idFile = idFile
  },
  signatureUpload(state: OrderCollectionState, signature: object) {
    state.signatureUpload = signature
  },
  picUpload(state: OrderCollectionState, picUpload: object) {
    state.picUpload = picUpload
  },
  idUpload(state: OrderCollectionState, idUpload: object) {
    state.idUpload = idUpload
  },
  collectionUserId(state: OrderCollectionState, collectionUserId: string) {
    state.collectionUserId = collectionUserId
  },
  sendSMS(state: OrderCollectionState, sendSMS: boolean) {
    state.sendSMS = sendSMS
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
